import { useEffect, useState } from 'react';
import ListCards from '../../../components/card/ListCards';
import { faTable, faDisplayChartUpCircleCurrency } from '@fortawesome/pro-light-svg-icons';
import { Recent } from '../../../api/recents/recents';
import { CardTypesEnum } from '../../../enums/card-types.enum';
import { RecentTypesEnum } from '../../../enums/recent-types.enum';
import { sortByTimestamp } from '../../../helpers/helper-components/recents-factory-helper';
import { CardProps } from '../../../types/cards/card-props';
import { ChartIconMap } from '../../../helpers/helper-components/chart-helper';
import { ChartTypesEnum } from '../../../enums/chart-types.enum';
import {
	Bullseye,
	Flex,
	FlexItem,
	Grid,
	GridItem,
	Spinner,
	Tab,
	Tabs,
	TextInput,
	Title,
} from '@patternfly/react-core';
import FilterIcon from '@fortawesome/fontawesome-pro/svgs/regular/filter.svg';
import { useIsGranted, useUser } from '../../../components/user/ApplicationProvider';
import { Permission } from '../../../enums/permission.enum';
import EmptyResults from '../../../components/EmptyResults';
import {
	faChartSimpleHorizontal,
	faFileChartColumn,
	faInfoCircle,
	faTableList,
} from '@fortawesome/pro-regular-svg-icons';
import { Favorites, TFavorites } from '../../../api/favorites/Favorites';
import { FavoriteTypes } from '../../../enums/favorite-types';

type Props = {
	initialTab: number;
	isAnalyze?: boolean;
	isReport?: boolean;
};

function ListRecentsFavorites(props: Props) {
	const [loading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState<string>('');
	const [recentCardsDataframes, setRecentCardsDataframes] = useState<CardProps[]>([]);
	const [recentCardsCharts, setRecentCardsCharts] = useState<CardProps[]>([]);
	const [recentCardsDashboards, setRecentCardsDashboards] = useState<CardProps[]>([]);
	const [recentCardsTables, setRecentCardsTables] = useState<CardProps[]>([]);
	const [recentCardsAll, setRecentCardsAll] = useState<CardProps[]>([]);
	const [filteredRecentCardsAll, setFilteredRecentCardsAll] = useState<CardProps[]>([]);
	const [filteredRecentCardsDataframes, setFilteredRecentCardsDataframes] = useState<CardProps[]>(
		[]
	);
	const [filteredRecentCardsCharts, setFilteredRecentCardsCharts] = useState<CardProps[]>([]);
	const [filteredRecentCardsDashboards, setFilteredRecentCardsDashboards] = useState<CardProps[]>(
		[]
	);
	const [filteredRecentCardsTables, setFilteredRecentCardsTables] = useState<CardProps[]>([]);
	const [favoriteCardsAll, setFavoriteCardsAll] = useState<CardProps[]>([]);
	const [favoriteCardsDataframes, setFavoriteCardsDataframes] = useState<CardProps[]>([]);
	const [favoriteCardsCharts, setFavoriteCardsCharts] = useState<CardProps[]>([]);
	const [favoriteCardsDashboards, setFavoriteCardsDashboards] = useState<CardProps[]>([]);
	const [favoriteCardsReports, setFavoriteCardsReports] = useState<CardProps[]>([]);
	const [favoriteCardsTables, setFavoriteCardsTables] = useState<CardProps[]>([]);
	const [filteredFavoriteCardsAll, setFilteredFavoriteCardsAll] = useState<CardProps[]>([]);
	const [filteredFavoriteCardsDataframes, setFilteredFavoriteCardsDataframes] = useState<
		CardProps[]
	>([]);
	const [filteredFavoriteCardsCharts, setFilteredFavoriteCardsCharts] = useState<CardProps[]>([]);
	const [filteredFavoriteCardsDashboards, setFilteredFavoriteCardsDashboards] = useState<
		CardProps[]
	>([]);
	const [filteredFavoriteCardsReports, setFilteredFavoriteCardsReports] = useState<CardProps[]>(
		[]
	);
	const [filteredFavoriteCardsTables, setFilteredFavoriteCardsTables] = useState<CardProps[]>([]);
	const [activeParentTabKey, setActiveParentTabKey] = useState(0);
	const [activeRecentTabKey, setActiveRecentTabKey] = useState(0);
	const [activeFavoriteTabKey, setActiveFavoriteTabKey] = useState(0);
	const [hasReportFavorites, setHasReportFavorites] = useState<boolean>(false);
	const [hasAnalyzeFavorites, setHasAnalyzeFavorites] = useState<boolean>(false);
	const [cardName, setCardName] = useState<string>();
	const isGranted = useIsGranted();
	const hasViewRecentPerm = isGranted(Permission.ViewRecent);
	const hasViewFavouritePerm = isGranted(Permission.ViewFavorite);
	const hasViewChartPerm = isGranted(Permission.ViewChart);
	const hasViewDataframePerm = isGranted(Permission.ViewDataframe);
	const hasViewDashboardPerm = isGranted(Permission.ViewDashboard);
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const hasViewTablePerm = isGranted(Permission.ViewTable);
	const currentUser = useUser();

	useEffect(() => {
		if (!hasViewRecentPerm && !hasViewFavouritePerm) {
			return;
		}

		setActiveParentTabKey(props.initialTab);
		setHasAnalyzeFavorites(props.isAnalyze ?? false);
		setHasReportFavorites(props.isReport ?? false);

		GetAllRecents();
		GetAllFavorites();
	}, [props]);

	function GetAllRecents() {
		setIsLoading(true);
		setFilter('');

		const promises = [
			Recent.GetAll(RecentTypesEnum.Dataframe),
			Recent.GetAll(RecentTypesEnum.Charts),
			Recent.GetAll(RecentTypesEnum.Dashboard),
			Recent.GetAll(RecentTypesEnum.Report, true),
		];

		// Use Promise.all to wait for all promises to complete
		Promise.all(promises)
			.then(([dataframes, charts, dashboards, tables]) => {
				//process dataframes
				const recentsDataframesCards = dataframes.map((item) => ({
					id: item.entity_id,
					icon: faTable,
					type: CardTypesEnum.DataFrame,
					url: `/analyze/${CardTypesEnum.DataFrame}/view/${item.entity_id}`,
					title: item.name ?? '',
					isRecent: true,
					timestamp: item.accessed_at,
					refreshList: GetAllRecents,
				}));

				//process charts
				const recentsChartCards = charts.map((item) => ({
					id: item.entity_id,
					icon: ChartIconMap[item.chart_type as ChartTypesEnum],
					type: CardTypesEnum.Chart,
					url: `/analyze/${CardTypesEnum.Chart}/view/chart/${item.entity_id}`,
					title: item.name ?? '',
					isRecent: true,
					timestamp: item.accessed_at,
					refreshList: GetAllRecents,
				}));

				//process dashboards
				const recentsDashboardCards = dashboards.map((item) => ({
					id: item.entity_id,
					icon: faDisplayChartUpCircleCurrency,
					type: CardTypesEnum.Dashboard,
					url: `/analyze/${CardTypesEnum.Dashboard}/view/${item.entity_id}`,
					title: item.name ?? '',
					isRecent: true,
					timestamp: item.accessed_at,
					refreshList: GetAllRecents,
				}));

				//process tables
				const recentsTablesCards = tables.map((item) => ({
					id: item.entity_id,
					icon: faTableList,
					type: CardTypesEnum.Table,
					url: `/table/view/${item.entity_id}`,
					title: item.name ?? '',
					isRecent: true,
					timestamp: item.accessed_at,
					refreshList: GetAllRecents,
				}));

				const combinedRecents = [
					...recentsDataframesCards,
					...recentsChartCards,
					...recentsDashboardCards,
					...recentsTablesCards,
				];

				//sorting
				const sortedDataframeRecents = sortByTimestamp(recentsDataframesCards).slice(0, 18);
				const sortedChartRecents = sortByTimestamp(recentsChartCards).slice(0, 18);
				const sortedDashboardRecents = sortByTimestamp(recentsDashboardCards).slice(0, 18);
				const sortedTableRecents = sortByTimestamp(recentsTablesCards).slice(0, 18);
				const sortedCombinedRecents = sortByTimestamp(combinedRecents).slice(0, 18);

				setRecentCardsDataframes(sortedDataframeRecents);
				setFilteredRecentCardsDataframes(sortedDataframeRecents);

				setRecentCardsCharts(sortedChartRecents);
				setFilteredRecentCardsCharts(sortedChartRecents);

				setRecentCardsDashboards(sortedDashboardRecents);
				setFilteredRecentCardsDashboards(sortedDashboardRecents);

				setRecentCardsTables(sortedTableRecents);
				setFilteredRecentCardsTables(sortedTableRecents);

				setRecentCardsAll(sortedCombinedRecents);
				setFilteredRecentCardsAll(sortedCombinedRecents);
			})
			.catch((error) => {
				// Handle errors
				console.error('Error fetching recent data:', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	function GetAllFavorites() {
		setIsLoading(true);
		setFilter('');

		const promises = [
			Favorites.GetByType(currentUser.id, FavoriteTypes.dataframe),
			Favorites.GetByType(currentUser.id, FavoriteTypes.chart),
			Favorites.GetByType(currentUser.id, FavoriteTypes.report),
			Favorites.GetByType(currentUser.id, FavoriteTypes.dashboard),
			Favorites.GetByType(currentUser.id, FavoriteTypes.table),
		];

		Promise.all(promises)
			.then(([dataframes, charts, reports, dashboards, tables]) => {
				let favoriteDataframesCards: CardProps[] = [];
				let favoriteChartCards: CardProps[] = [];
				let favoriteDashboardCards: CardProps[] = [];
				let favoriteTableCards: CardProps[] = [];

				if (props.isAnalyze) {
					//process dataframes
					favoriteDataframesCards = dataframes.map((item: TFavorites) => ({
						id: item.object_id,
						icon: faTable,
						type: CardTypesEnum.DataFrame,
						url: hasViewDataframePerm
							? `/analyze/${CardTypesEnum.DataFrame}/view/${item.object_id ?? 0}`
							: '',
						title: item.name,
						refreshList: GetAllFavorites,
					}));

					//process charts
					favoriteChartCards = charts.map((item: TFavorites) => ({
						id: item.object_id,
						icon: item.chartType
							? ChartIconMap[item.chartType as ChartTypesEnum]
							: faChartSimpleHorizontal,
						type: CardTypesEnum.Chart,
						url: hasViewChartPerm
							? `/analyze/${CardTypesEnum.Chart}/view/chart/${item.object_id ?? 0}`
							: '',
						title: item.name ?? '',
						refreshList: GetAllFavorites,
					}));

					//process dashboards
					favoriteDashboardCards = dashboards.map((item: TFavorites) => ({
						id: item.object_id,
						icon: faDisplayChartUpCircleCurrency,
						type: CardTypesEnum.Dashboard,
						url: hasViewDashboardPerm
							? `/analyze/${CardTypesEnum.Dashboard}/view/${item.object_id ?? 0}`
							: '',
						title: item.name ?? '',
						refreshList: GetAllFavorites,
					}));

					//process tables
					favoriteTableCards = tables.map((item: TFavorites) => ({
						id: item.object_id,
						icon: faTable,
						type: CardTypesEnum.Table,
						url: hasViewTablePerm ? `/table/view/${item.object_id ?? 0}` : '',
						title: item.name,
						refreshList: GetAllFavorites,
					}));
				}

				//process reports
				const favoriteReportCards = props.isReport
					? reports.map((item: TFavorites) => ({
							id: item.object_id,
							icon: faFileChartColumn,
							type: CardTypesEnum.Report,
							url: hasViewReportPerm ? `/report/view/${item.object_id ?? 0}` : '',
							title: item.name ?? '',
							refreshList: GetAllFavorites,
					  }))
					: [];

				const combinedFavorites = [
					...favoriteDataframesCards,
					...favoriteChartCards,
					...favoriteReportCards,
					...favoriteDashboardCards,
					...favoriteTableCards,
				];

				//sorting
				const sortedDataframeFavorites = sortByTimestamp(favoriteDataframesCards).slice(
					0,
					18
				);
				const sortedChartFavorites = sortByTimestamp(favoriteChartCards).slice(0, 18);
				const sortedReportFavorites = sortByTimestamp(favoriteReportCards).slice(0, 18);
				const sortedDashboardFavorites = sortByTimestamp(favoriteDashboardCards).slice(
					0,
					18
				);
				const sortedTableFavorites = sortByTimestamp(favoriteTableCards).slice(0, 18);
				const sortedCombinedFavorites = sortByTimestamp(combinedFavorites).slice(0, 18);

				setFavoriteCardsDataframes(sortedDataframeFavorites);
				setFilteredFavoriteCardsDataframes(sortedDataframeFavorites);

				setFavoriteCardsCharts(sortedChartFavorites);
				setFilteredFavoriteCardsCharts(sortedChartFavorites);

				setFavoriteCardsReports(sortedReportFavorites);
				setFilteredFavoriteCardsReports(sortedReportFavorites);

				setFavoriteCardsDashboards(sortedDashboardFavorites);
				setFilteredFavoriteCardsDashboards(sortedDashboardFavorites);

				setFavoriteCardsTables(sortedTableFavorites);
				setFilteredFavoriteCardsTables(sortedTableFavorites);

				setFavoriteCardsAll(sortedCombinedFavorites);
				setFilteredFavoriteCardsAll(sortedCombinedFavorites);
			})
			.catch((error) => {
				// Handle errors
				console.error('Error fetching favorite data:', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	const filterCards = (value: string) => {
		// recents
		setFilteredRecentCardsDataframes(
			recentCardsDataframes.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredRecentCardsDashboards(
			recentCardsDashboards.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredRecentCardsCharts(
			recentCardsCharts.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredRecentCardsTables(
			recentCardsTables.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredRecentCardsAll(
			recentCardsAll.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);

		// favorites
		setFilteredFavoriteCardsDataframes(
			favoriteCardsDataframes.filter((c) =>
				c.title.toLowerCase().includes(value.toLowerCase())
			)
		);
		setFilteredFavoriteCardsDashboards(
			favoriteCardsDashboards.filter((c) =>
				c.title.toLowerCase().includes(value.toLowerCase())
			)
		);
		setFilteredFavoriteCardsCharts(
			favoriteCardsCharts.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredFavoriteCardsAll(
			favoriteCardsAll.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredFavoriteCardsReports(
			favoriteCardsReports.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);
		setFilteredRecentCardsTables(
			favoriteCardsTables.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
		);

		setFilter(value);
	};

	const handleParentTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveParentTabKey(Number(tabIndex));
	};

	const handleRecentTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveRecentTabKey(Number(tabIndex));
	};

	const handleFavoriteTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveFavoriteTabKey(Number(tabIndex));
	};

	const header = (
		<>
			<span className="spacer-sm"></span>
			<Grid span={12}>
				<GridItem span={12}>
					<Flex direction={{ default: 'row' }}>
						<FlexItem>
							<Title headingLevel={'h1'}>Recents & Favorites</Title>
						</FlexItem>
					</Flex>
				</GridItem>
			</Grid>
			<GridItem span={3}>
				<TextInput
					value={filter}
					type="text"
					className="filter"
					customIconUrl={FilterIcon}
					placeholder="Filter"
					onChange={(value) => filterCards(value)}
					aria-label="Filter Recents"
				/>
			</GridItem>
		</>
	);

	const emptyTemplate = (
		<EmptyResults
			emptyStateBody=""
			iconName={faInfoCircle}
			backComponent={<></>}
		/>
	);

	if (loading) {
		return (
			<Bullseye>
				<div>
					<Spinner
						isSVG
						size={'xl'}
					/>
				</div>
			</Bullseye>
		);
	}

	return (
		<>
			<Grid hasGutter>
				{header}
				<GridItem span={12}>
					<Tabs
						className="parent-tabs"
						activeKey={activeParentTabKey}
						onSelect={handleParentTabSelect}
					>
						{hasViewRecentPerm && (
							<Tab
								eventKey={0}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Recents<strong/>',
										}}
									></span>
								}
							>
								<Tabs
									activeKey={activeRecentTabKey}
									onSelect={handleRecentTabSelect}
									className="recent-tabs"
								>
									<Tab
										eventKey={0}
										title="All"
									>
										{filteredRecentCardsAll.length ? (
											<ListCards
												additionalClassNames="scrollable-cards"
												items={filteredRecentCardsAll}
												hideEllipsis={true}
												hidePin={true}
											/>
										) : (
											emptyTemplate
										)}
									</Tab>
									<Tab
										eventKey={1}
										title="Dashboards"
									>
										{filteredRecentCardsDashboards.length ? (
											<ListCards
												additionalClassNames="scrollable-cards"
												items={filteredRecentCardsDashboards}
												hideEllipsis={true}
												hidePin={true}
											/>
										) : (
											emptyTemplate
										)}
									</Tab>
									<Tab
										eventKey={2}
										title="Charts"
									>
										{filteredRecentCardsCharts.length ? (
											<ListCards
												additionalClassNames="scrollable-cards"
												items={filteredRecentCardsCharts}
												hideEllipsis={true}
												hidePin={true}
											/>
										) : (
											emptyTemplate
										)}
									</Tab>
									<Tab
										eventKey={3}
										title="Tables"
									>
										{filteredRecentCardsTables.length ? (
											<ListCards
												additionalClassNames="scrollable-cards"
												items={filteredRecentCardsTables}
												hideEllipsis={true}
												hidePin={true}
											/>
										) : (
											emptyTemplate
										)}
									</Tab>
									<Tab
										eventKey={4}
										title="Dataframes"
									>
										{filteredRecentCardsDataframes.length ? (
											<ListCards
												additionalClassNames="scrollable-cards"
												items={filteredRecentCardsDataframes}
												hideEllipsis={true}
												hidePin={true}
											/>
										) : (
											emptyTemplate
										)}
									</Tab>
								</Tabs>
							</Tab>
						)}

						{hasViewFavouritePerm && (
							<Tab
								eventKey={1}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Favorites<strong/>',
										}}
									></span>
								}
							>
								{hasAnalyzeFavorites && (
									<Tabs
										activeKey={activeFavoriteTabKey}
										onSelect={handleFavoriteTabSelect}
										className="favorite-tabs"
									>
										<Tab
											eventKey={0}
											title="All"
										>
											{filteredFavoriteCardsAll.length ? (
												<ListCards
													additionalClassNames="scrollable-cards"
													items={filteredFavoriteCardsAll}
													hideEllipsis={true}
													hidePin={true}
												/>
											) : (
												emptyTemplate
											)}
										</Tab>
										<Tab
											eventKey={1}
											title="Dashboards"
										>
											{filteredFavoriteCardsDashboards.length ? (
												<ListCards
													additionalClassNames="scrollable-cards"
													items={filteredFavoriteCardsDashboards}
													hideEllipsis={true}
													hidePin={true}
												/>
											) : (
												emptyTemplate
											)}
										</Tab>
										<Tab
											eventKey={2}
											title="Charts"
											className="favorite-chart-tab"
										>
											{filteredFavoriteCardsCharts.length ? (
												<ListCards
													additionalClassNames="scrollable-cards"
													items={filteredFavoriteCardsCharts}
													hideEllipsis={true}
													hidePin={activeFavoriteTabKey != 2}
												/>
											) : (
												emptyTemplate
											)}
										</Tab>
										<Tab
											eventKey={3}
											title="Tables"
										>
											{filteredFavoriteCardsTables.length ? (
												<ListCards
													additionalClassNames="scrollable-cards"
													items={filteredFavoriteCardsTables}
													hideEllipsis={true}
													hidePin={true}
												/>
											) : (
												emptyTemplate
											)}
										</Tab>
										<Tab
											eventKey={4}
											title="Dataframes"
										>
											{filteredFavoriteCardsDataframes.length ? (
												<ListCards
													additionalClassNames="scrollable-cards"
													items={filteredFavoriteCardsDataframes}
													hideEllipsis={true}
													hidePin={true}
												/>
											) : (
												emptyTemplate
											)}
										</Tab>
									</Tabs>
								)}
								{hasReportFavorites && (
									<Tabs
										activeKey={activeFavoriteTabKey}
										onSelect={handleFavoriteTabSelect}
										className="favorite-tabs"
									>
										<Tab
											eventKey={0}
											title="Reports"
										>
											{filteredFavoriteCardsReports.length ? (
												<ListCards
													additionalClassNames="scrollable-cards"
													items={filteredFavoriteCardsReports}
													hideEllipsis={true}
													hidePin={activeFavoriteTabKey != 2}
												/>
											) : (
												emptyTemplate
											)}
										</Tab>
									</Tabs>
								)}
							</Tab>
						)}
					</Tabs>
				</GridItem>
			</Grid>
		</>
	);
}

export default ListRecentsFavorites;
