import React, { useEffect } from 'react';
import {
	ActionGroup,
	Button,
	Form,
	FormGroup,
	HelperText,
	HelperTextItem,
	Popover,
	TextArea,
	TextContent,
	TextInput,
	TextVariants,
	Text,
	Radio,
	Alert,
	DragDrop,
	DualListSelectorPane,
	Droppable,
	DualListSelectorList,
	Draggable,
	DualListSelectorListItem,
	DualListSelectorControlsWrapper,
	DualListSelectorControl,
	DualListSelector,
	NumberInput,
	Checkbox,
	Grid,
	GridItem,
	Spinner,
	DatePicker,
	TimePicker,
	Flex,
	FlexItem,
	InputGroup,
	SearchInput,
} from '@patternfly/react-core';
import {
	cleanupPropertyName,
	isValidDatetime,
	timestampToHHDDAMPM,
	timestampToMMDDYYYY,
} from '../../../utilities';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { useMount } from 'react-use';
import Typeahead from '../../ui/Typeahead';
import VariableInputGroup from '../../ui/VariableInputGroup';
import AngleRightIcon from '@patternfly/react-icons/dist/esm/icons/angle-right-icon';
import AngleDoubleRightIcon from '@patternfly/react-icons/dist/esm/icons/angle-double-right-icon';
import AngleDoubleLeftIcon from '@patternfly/react-icons/dist/esm/icons/angle-double-left-icon';
import AngleLeftIcon from '@patternfly/react-icons/dist/esm/icons/angle-left-icon';
import './SchnurForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import SearchableTreeViewSelect from '../Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { TDimension } from '../../../api/analytics/Dimension';
import { TKeyMeasure } from '../../../api/analytics/KeyMeasure';
import { TwitterPicker } from 'react-color';

export enum UIType {
	TEXT = 'text',
	NUMBER = 'number',
	BOOLEAN = 'boolean',
	SELECT = 'select',
	TYPEAHEAD = 'typeahead',
	DATE = 'date',
	TIME = 'time',
	DATETIME = 'datetime',
	TEXTAREA = 'textarea',
	CHECKBOX = 'checkbox',
	RADIO = 'radio',
	PASSWORD = 'password',
	DUAL_LIST = 'dual-list',
	VARIABLE_INPUT_GROUP = 'variable-input-group',
	DIM_ATTRIBUTE_SELECT = 'dim-attribute-select',
	KEYMEASURE_FACT_SELECT = 'keymeasure-fact-select',
	COLOR_PICKER = 'color-picker',
}

export enum EventType {
	CHANGE = 'change',
	BLUR = 'blur',
	FOCUS = 'focus',
}

export type Popover = {
	header: string;
	body: string;
};

export type BaseUISchema = {
	type: UIType;
	placeholder?: string;
	helpText?: string;
	popover?: Popover;
	dimOptions?: TDimension[];
};

export type NumberUISchema = BaseUISchema & {
	type: UIType.NUMBER;
	min?: number;
	max?: number;
	widthChars?: number;
};

export interface ISelectOption {
	value: string;
	key: string | number;
	description?: string;
	selectedByDefault?: boolean;
}

export interface IVariableInputListData {
	name: string;
	unitType: number;
	sequence: number;
	alias: string;
	direction: string;
	minimum_value: number;
	maximum_value: number;
	id?: number;
}

export interface IVariableInputList {
	key: number;
	content: object | IVariableInputListData[] | undefined;
}

export type SelectUISchema<T, K extends keyof T> = BaseUISchema & {
	type:
		| UIType.SELECT
		| UIType.RADIO
		| UIType.CHECKBOX
		| UIType.DIM_ATTRIBUTE_SELECT
		| UIType.KEYMEASURE_FACT_SELECT
		| UIType.COLOR_PICKER;
	options: string[] | ISelectOption[];
	dimOptions: TDimension[];
	kmOptions: TKeyMeasure[];
	placeholder?: string;
	onSelect: (selected: ISelectOption) => T[K];
	initialSelection?: string | number | null;
};

export type DualListUISchema<T, K extends keyof T> = BaseUISchema & {
	type: UIType.DUAL_LIST;
	options: ISelectOption[];
	placeholder?: string;
	onSelect: (selected: IDualListOption[]) => T[K];
	selected?: ISelectOption[];
};

export type VariableInputGroupUISchema = BaseUISchema & {
	type: UIType.VARIABLE_INPUT_GROUP;
	data: Array<object> | IVariableInputList[];
};

type InternalValidation = {
	isValid: boolean;
	message?: string;
	hasBeenEdited?: boolean;
};

export type Field<T> = {
	uiSchema:
		| BaseUISchema
		| SelectUISchema<T, keyof T>
		| DualListUISchema<T, keyof T>
		| NumberUISchema
		| VariableInputGroupUISchema;
	columnName: keyof T;
	title?: string;
	required?: boolean;
	disabled?: boolean;
	maxLength?: number;
	minLength?: number;
	validate?: (eventType: EventType, value: string) => boolean | string;
	isHidden?: boolean;
};
export interface IDualListOption {
	text: string;
	selected: boolean;
	isVisible: boolean;
	key: string | number;
}

type DualListOption = {
	availableOptions: IDualListOption[];
	selectedOptions: IDualListOption[];
	ignoreNextSelect: boolean;
};

// https://www.patternfly.org/v4/components/form/design-guidelines#data-inputs

type Props<T> = {
	title: string;
	description?: string;
	fields: Field<T>[];
	isHorizontal?: boolean;
	initialSubject: T;
	showTitle?: boolean;
	onSubmit: (subject: T) => void;
	isLoading?: boolean;
};

interface HasId {
	id: number;
}

function convertToObject<T extends HasId>(initialSubject: T) {
	return initialSubject.id;
}

export default function SchnurForm<T>(props: Props<T>) {
	const [subject, setSubject] = React.useState<T>(props.initialSubject);
	const [validations, setValidations] = React.useState<Map<string, InternalValidation>>(
		new Map()
	);
	const [allFieldsOptional, setAllFieldsOptional] = React.useState<boolean>(false);
	const [allFieldsRequired, setAllFieldsRequired] = React.useState<boolean>(false);
	const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = React.useState<boolean>(false);
	const [dualListOptions, setDualListOptions] = React.useState<Record<string, DualListOption>>(
		{}
	);
	const [defaultDualListOptions, setDefaultDualListOptions] = React.useState<
		Record<string, DualListOption>
	>({});
	const [selectedDualListOptions, setSelectedDualListOptions] =
		React.useState<IDualListOption[]>();
	const [isFormValid, setFormValid] = React.useState<boolean>(true);
	const titleId = props.title.toLowerCase().replaceAll(' ', '-');
	const emptyDualListOption: DualListOption = {
		ignoreNextSelect: false,
		availableOptions: [],
		selectedOptions: [],
	};
	const [subjectId, setSubjectId] = React.useState<number>(convertToObject(subject as HasId));

	const setUpdatedSelectedCacheItems = (
		fieldIdKey: string,
		updatedSelectedOptions: IDualListOption[]
	) => {
		const lsKey = `${fieldIdKey}_selectedOptions_${subjectId}`;

		localStorage.setItem(lsKey, JSON.stringify(updatedSelectedOptions));
	};

	const handleLeftSearch = (
		_event: React.FormEvent<HTMLInputElement>,
		value: string,
		fieldId: string
	) => {
		const search = value;
		const fieldIdKey = fieldId;
		const fieldInferredOptions = dualListOptions[fieldIdKey];
		const lsKey = `${fieldIdKey}_avaliableOptions_${subjectId}`;
		let existingLsItem = localStorage.getItem(lsKey);

		if (!existingLsItem) {
			localStorage.setItem(lsKey, JSON.stringify(fieldInferredOptions.availableOptions));
			existingLsItem = localStorage.getItem(lsKey);
		}
		const firstItemInObject: DualListOption = fieldInferredOptions
			? fieldInferredOptions
			: emptyDualListOption;

		const dualOptions =
			fieldInferredOptions?.availableOptions?.length > 0
				? fieldInferredOptions
				: firstItemInObject;

		if (!search) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const originalAvaliableOptions = JSON.parse(existingLsItem!);

			setDualListOptions((prevState) => ({
				...prevState,
				[fieldIdKey]: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					availableOptions: originalAvaliableOptions,
					selectedOptions: dualOptions['selectedOptions'],
					ignoreNextSelect: false,
				},
			}));
			return;
		}

		const leftOptions = existingLsItem
			? (JSON.parse(existingLsItem) as IDualListOption[])
			: firstItemInObject['availableOptions'];

		const updatedLeftOptions = leftOptions.filter((x) =>
			x.text.toLowerCase().includes(search.toLowerCase())
		);

		setDualListOptions((prevState) => ({
			...prevState,
			[fieldIdKey]: {
				availableOptions: updatedLeftOptions,
				selectedOptions: dualOptions['selectedOptions'],
				ignoreNextSelect: false,
			},
		}));
	};

	const handleRightSearch = (
		_event: React.FormEvent<HTMLInputElement>,
		value: string,
		fieldId: string
	) => {
		const search = value;
		const fieldIdKey = fieldId;
		const fieldInferredOptions = dualListOptions[fieldIdKey];
		const lsKey = `${fieldIdKey}_selectedOptions_${subjectId}`;
		let existingLsItem = localStorage.getItem(lsKey);

		if (!existingLsItem) {
			localStorage.setItem(lsKey, JSON.stringify(fieldInferredOptions.selectedOptions));
			existingLsItem = localStorage.getItem(lsKey);
		}
		const firstItemInObject: DualListOption = fieldInferredOptions
			? fieldInferredOptions
			: emptyDualListOption;

		const dualOptions =
			fieldInferredOptions?.selectedOptions?.length > 0
				? fieldInferredOptions
				: firstItemInObject;
		const rightOptions = existingLsItem
			? (JSON.parse(existingLsItem) as IDualListOption[])
			: dualOptions['selectedOptions'];

		if (!search) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const originalChosenOptions = JSON.parse(existingLsItem!);

			setDualListOptions((prevState) => ({
				...prevState,
				[fieldIdKey]: {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					availableOptions: dualOptions['availableOptions'],
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					selectedOptions: originalChosenOptions,
					ignoreNextSelect: false,
				},
			}));
			return;
		}

		const updatedRightOptions = rightOptions.filter((x) =>
			x.text.toLowerCase().includes(search.toLowerCase())
		);

		setDualListOptions((prevState) => ({
			...prevState,
			[fieldIdKey]: {
				availableOptions: dualOptions['availableOptions'],
				selectedOptions: updatedRightOptions,
				ignoreNextSelect: false,
			},
		}));
	};

	useEffect(() => {
		if (hasFormBeenSubmitted) {
			const isValid = validateForm(false);
			setFormValid(isValid);
		}

		let finalDualListOptions: Record<string, DualListOption> = dualListOptions;

		for (const field of props.fields) {
			const label = field.title ?? cleanupPropertyName(String(field.columnName));
			const fieldId = titleId + '-' + label.toLowerCase().replaceAll(' ', '-');
			if (
				field.uiSchema.type === UIType.DUAL_LIST &&
				(field.uiSchema as DualListUISchema<T, keyof T>).options.length !== 0 &&
				dualListOptions[fieldId] === undefined
			) {
				const tempDualListOptions = { ...dualListOptions };

				let options = (field.uiSchema as DualListUISchema<T, keyof T>).options;
				const selected = (field.uiSchema as DualListUISchema<T, keyof T>).selected ?? [];

				options = options.filter((option) => {
					return selected.every((selectedOption) => {
						return selectedOption?.key !== option.key;
					});
				});

				const selectedOptions = selected
					.filter((selected) => selected !== undefined)
					.map((option) => {
						return {
							text: typeof option === 'string' ? option : option.value,
							selected: false,
							isVisible: true,
							key: typeof option === 'string' ? option : option.key,
						};
					});

				tempDualListOptions[fieldId] = {
					availableOptions: options.map((option) => {
						return {
							text: typeof option === 'string' ? option : option.value,
							selected: false,
							isVisible: true,
							key: typeof option === 'string' ? option : option.key,
						};
					}),
					selectedOptions: selectedOptions,
					ignoreNextSelect: false,
				};

				finalDualListOptions = Object.assign({}, finalDualListOptions, tempDualListOptions);
			}
		}

		finalDualListOptions != null && setDualListOptions(finalDualListOptions);
	}, [hasFormBeenSubmitted, validations, props.fields]);

	const buildFinalDualListOptions = () => {
		let finalDualListOptions: Record<string, DualListOption> = dualListOptions;

		for (const field of props.fields) {
			const label = field.title ?? cleanupPropertyName(String(field.columnName));
			const fieldId = titleId + '-' + label.toLowerCase().replaceAll(' ', '-');
			if (
				field.uiSchema.type === UIType.DUAL_LIST &&
				(field.uiSchema as DualListUISchema<T, keyof T>).options.length !== 0 &&
				dualListOptions[fieldId] === undefined
			) {
				const tempDualListOptions = { ...dualListOptions };

				let options = (field.uiSchema as DualListUISchema<T, keyof T>).options;
				const selected = (field.uiSchema as DualListUISchema<T, keyof T>).selected ?? [];

				options = options.filter((option) => {
					return selected.every((selectedOption) => {
						return selectedOption?.key !== option.key;
					});
				});

				const selectedOptions = selected
					.filter((selected) => selected !== undefined)
					.map((option) => {
						return {
							text: typeof option === 'string' ? option : option.value,
							selected: true,
							isVisible: true,
							key: typeof option === 'string' ? option : option.key,
						};
					});

				tempDualListOptions[fieldId] = {
					availableOptions: options.map((option) => {
						return {
							text: typeof option === 'string' ? option : option.value,
							selected: false,
							isVisible: true,
							key: typeof option === 'string' ? option : option.key,
						};
					}),
					selectedOptions: selectedOptions,
					ignoreNextSelect: false,
				};
				finalDualListOptions = Object.assign({}, finalDualListOptions, tempDualListOptions);
			}
		}
		return finalDualListOptions;
	};

	useEffect(() => {
		const subjectToUpdate: T = subject;

		for (const key in dualListOptions) {
			const field = props.fields.find((field) => {
				const label = field.title ?? cleanupPropertyName(String(field.columnName));
				const fieldId = titleId + '-' + label.toLowerCase().replaceAll(' ', '-');
				return fieldId === key;
			});

			if (!field) {
				continue;
			}

			const newVal = (field.uiSchema as DualListUISchema<T, keyof T>).onSelect(
				dualListOptions[key].selectedOptions
			);

			subjectToUpdate[field.columnName] = newVal;
		}

		setSubject(subjectToUpdate);
		setSubjectId(convertToObject(subject as HasId));
	}, [dualListOptions]);

	useMount(() => {
		let allOptional = true;
		let allRequired = true;

		for (const field of props.fields) {
			if (field.required) {
				allOptional = false;
			} else {
				allRequired = false;
			}

			const label = field.title ?? cleanupPropertyName(String(field.columnName));
			const fieldId = titleId + '-' + label.toLowerCase().replaceAll(' ', '-');
			const data = subject[field.columnName] as string | number | undefined;

			if (field.required || (field.validate && !validations.has(fieldId))) {
				if (
					field.uiSchema.type === UIType.BOOLEAN ||
					field.uiSchema.type === UIType.NUMBER
				) {
					validations.set(fieldId, { isValid: true, hasBeenEdited: true });
				} else {
					validations.set(fieldId, { isValid: true, hasBeenEdited: false });
				}
			}
		}

		setAllFieldsOptional(allOptional);
		setAllFieldsRequired(allRequired);

		const initialDualListOptions = buildFinalDualListOptions();
		setDefaultDualListOptions(initialDualListOptions);

		const firstItemDefaulted = getObjectWithFirstKeyAsValue(initialDualListOptions);
		const firstItemInObject: DualListOption = firstItemDefaulted
			? firstItemDefaulted[1]
			: emptyDualListOption;

		if (firstItemInObject.selectedOptions.length > 0) {
			const selectedOptions = firstItemInObject.selectedOptions.map((selectedOption) => {
				selectedOption.selected = false;

				return selectedOption;
			});
			setSelectedDualListOptions(selectedOptions);
		}
	});

	const updateValidations = (fieldId: string, validationObject: InternalValidation): void => {
		setValidations(
			new Map(validations.set(fieldId, { hasBeenEdited: true, ...validationObject }))
		);
	};

	const handleValidation = (text: string, fieldId: string, field: Field<T>, event: EventType) => {
		// If required and empty, we don't need to run the validation function
		if (field.required && text.length === 0) {
			updateValidations(fieldId, { isValid: false, message: 'This field is required.' });
			return;
		}

		if (field.maxLength && text.length > field.maxLength) {
			updateValidations(fieldId, {
				isValid: false,
				message: `This field has a maximum length of ${field.maxLength} characters.`,
			});
			return;
		}

		if (field.minLength && text.length > 0 && text.length < field.minLength) {
			updateValidations(fieldId, {
				isValid: false,
				message: `This field has a minimum length of ${field.minLength} characters.`,
			});
			return;
		}

		if (field.validate) {
			const result = field.validate(event, text);
			updateValidations(
				fieldId,
				result === true ? { isValid: true } : { isValid: false, message: result as string }
			);
		}

		if (field.required && text.length !== 0) {
			updateValidations(fieldId, { isValid: true });
			return;
		}

		if (field.maxLength && text.length <= field.maxLength) {
			updateValidations(fieldId, { isValid: true });
			return;
		}

		if (field.minLength && text.length > 0 && text.length >= field.minLength) {
			updateValidations(fieldId, { isValid: true });
			return;
		}
	};

	const validateForm = (shouldUpdateState: boolean) => {
		let isValid = true;

		props.fields.forEach((field) => {
			const label = field.title ?? cleanupPropertyName(String(field.columnName));
			const fieldId = titleId + '-' + label.toLowerCase().replaceAll(' ', '-');

			if ((field.required || field.maxLength || field.minLength) && !field.isHidden) {
				const internalValidation = validations.get(fieldId);

				if (field.uiSchema.type === UIType.DUAL_LIST) {
					if (
						dualListOptions[fieldId] !== undefined &&
						dualListOptions[fieldId].selectedOptions.length === 0
					) {
						isValid = false;

						if (shouldUpdateState) {
							updateValidations(fieldId, {
								isValid: false,
								message: 'This field is required.',
							});
						}
					}

					return;
				} else if (field.uiSchema.type === UIType.VARIABLE_INPUT_GROUP) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					if (!subject[field.columnName] || subject[field.columnName].length === 0) {
						isValid = false;
						if (shouldUpdateState) {
							updateValidations(fieldId, {
								isValid: false,
								message: 'At least one Fact is required.',
							});
						}
					}
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const valueToValidate = subject[field.columnName] as string;

					if (valueToValidate) {
						if (field.required && valueToValidate.length === 0) {
							isValid = false;
						} else if (field.maxLength && valueToValidate.length > field.maxLength) {
							isValid = false;
						} else if (
							field.minLength &&
							valueToValidate.length &&
							valueToValidate.length < field.minLength
						) {
							isValid = false;
						}
					} else {
						if (field.required) {
							isValid = false;
						}
					}

					if (!isValid && shouldUpdateState) {
						handleValidation(valueToValidate, fieldId, field, EventType.CHANGE);
					}
				}

				if (internalValidation) {
					if (!internalValidation?.isValid) {
						isValid = false;

						if (shouldUpdateState) {
							updateValidations(fieldId, {
								isValid: internalValidation?.isValid ?? false,
								message: internalValidation?.message,
							});
						}
					}
				}
			}
		});

		return isValid;
	};

	if (props.isLoading) {
		return (
			<Form>
				<Grid
					hasGutter
					id={'zone-form-grid'}
				>
					<GridItem span={12}>
						<Spinner
							isSVG
							size={'lg'}
						/>
					</GridItem>
				</Grid>
			</Form>
		);
	}

	const dateFormat = (date: Date) =>
		date
			.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
			.replace(/\//g, '-');

	const dateParse = (date: string) => {
		const split = date.split('-');
		if (split.length !== 3) {
			return new Date();
		}
		const month = split[0];
		const day = split[1];
		const year = split[2];
		return new Date(
			`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`
		);
	};

	const getObjectWithFirstKeyAsValue = (
		obj: Record<string, DualListOption>
	): [string, DualListOption] | null => {
		return Object.entries(obj)[0];
	};

	return (
		<Form>
			{(props.showTitle || props.description) && (
				<TextContent>
					{props.showTitle && <Text component={TextVariants.h2}>{props.title}</Text>}
					{props.description && <Text>{props.description}</Text>}
				</TextContent>
			)}
			{(allFieldsOptional && (
				<HelperText>
					<HelperTextItem variant="indeterminate">
						All fields are optional.
					</HelperTextItem>
				</HelperText>
			)) ||
				(allFieldsRequired && (
					<HelperText>
						<HelperTextItem variant="indeterminate">
							All fields are required.
						</HelperTextItem>
					</HelperText>
				))}
			{!isFormValid && hasFormBeenSubmitted && (
				<Alert
					variant="danger"
					isInline
					title="Please address form errors to proceed."
				/>
			)}
			{props.fields.map((field) => {
				const label = field.title ?? cleanupPropertyName(String(field.columnName));
				const fieldId = titleId + '-' + label.toLowerCase().replaceAll(' ', '-');
				const data = subject[field.columnName] as string | number | undefined;
				const showRequired = field.required;
				const isDisabled = field.disabled;
				let input: React.ReactElement | React.ReactElement[] | null = null;

				const isValid = validations.get(fieldId)?.isValid ?? true;
				const canBeInvalid =
					field.required || field.validate || field.minLength || field.maxLength;

				if (field.isHidden) {
					return;
				}

				switch (field.uiSchema.type) {
					case UIType.TEXT:
						input = (
							<TextInput
								type="text"
								id={fieldId}
								name={fieldId}
								isRequired={showRequired}
								isDisabled={isDisabled}
								validated={isValid ? 'default' : 'error'}
								aria-describedby={
									field.uiSchema.helpText ? fieldId + '-helper' : undefined
								}
								area-label={field.uiSchema.helpText ? undefined : label + ' input'}
								placeholder={field.uiSchema.placeholder ?? undefined}
								value={data ?? ''}
								onBlur={(e: React.FormEvent<HTMLInputElement>) => {
									const text = (e.target as HTMLInputElement).value;

									handleValidation(text, fieldId, field, EventType.BLUR);
								}}
								onChange={(text) => {
									handleValidation(text, fieldId, field, EventType.CHANGE);

									setSubject({ ...subject, [field.columnName]: text });
								}}
							/>
						);
						break;
					case UIType.PASSWORD:
						input = (
							<TextInput
								type="password"
								id={fieldId}
								name={fieldId}
								isRequired={showRequired}
								validated={isValid ? 'default' : 'error'}
								aria-describedby={
									field.uiSchema.helpText ? fieldId + '-helper' : undefined
								}
								area-label={field.uiSchema.helpText ? undefined : label + ' input'}
								placeholder={field.uiSchema.placeholder ?? undefined}
								value={data ?? ''}
								onBlur={(e: React.FormEvent<HTMLInputElement>) => {
									const text = (e.target as HTMLInputElement).value;

									handleValidation(text, fieldId, field, EventType.BLUR);
								}}
								onChange={(text) => {
									handleValidation(text, fieldId, field, EventType.CHANGE);

									setSubject({ ...subject, [field.columnName]: text });
								}}
							/>
						);
						break;
					case UIType.TEXTAREA:
						input = (
							<TextArea
								isRequired={showRequired}
								type="text"
								id={fieldId}
								name={fieldId}
								validated={isValid ? 'default' : 'error'}
								aria-describedby={
									field.uiSchema.helpText ? fieldId + '-helper' : undefined
								}
								area-label={field.uiSchema.helpText ? undefined : label + ' input'}
								value={data ?? ''}
								onBlur={(e) => {
									const text = e.target.value;

									handleValidation(text, fieldId, field, EventType.BLUR);
								}}
								onChange={(text) => {
									handleValidation(text, fieldId, field, EventType.CHANGE);

									setSubject({ ...subject, [field.columnName]: text });
								}}
							/>
						);
						break;
					case UIType.RADIO:
						// TODO: https://www.patternfly.org/v4/components/form/design-guidelines#data-input-arrangement
						input = (field.uiSchema as SelectUISchema<T, keyof T>).options.map(
							(option, index) => {
								const isObject = typeof option === 'object';
								const stringOption = isObject ? option.value : option;
								const id = `${fieldId}-${index}`;
								const isChecked =
									typeof option === 'object' && 'selectedByDefault' in option
										? option.selectedByDefault
										: undefined;

								return (
									<Radio
										id={id}
										name={id}
										key={`${id}-${index}`}
										isChecked={
											data === option || (isChecked && data === undefined)
										}
										onChange={() => {
											updateValidations(fieldId, { isValid: true });

											setSubject({ ...subject, [field.columnName]: option });

											(field.uiSchema as SelectUISchema<T, keyof T>).onSelect(
												{
													key: option,
													value: option,
												} as ISelectOption
											);
										}}
										label={stringOption}
										description={isObject ? option.description : undefined}
									/>
								);
							}
						);
						break;
					case UIType.TYPEAHEAD:
					case UIType.SELECT:
						// eslint-disable-next-line no-case-declarations
						const options = (field.uiSchema as SelectUISchema<T, keyof T>).options.map(
							(option, index) => {
								const isObject = typeof option === 'object';

								return {
									value: isObject ? option.value : option,
									key: isObject ? option.key : `${fieldId}-${index}`,
									description: isObject ? option.description : undefined,
								};
							}
						);

						// eslint-disable-next-line no-case-declarations
						let initSelection;

						if (typeof data === 'object') {
							initSelection = (field.uiSchema as SelectUISchema<T, keyof T>)
								.initialSelection
								? ((field.uiSchema as SelectUISchema<T, keyof T>)
										.initialSelection as string)
								: undefined;
						}

						if (initSelection === undefined) {
							if (typeof data === 'number') {
								initSelection = options.find(
									(option) => option.key === data
								)?.value;
							} else if (typeof data === 'string') {
								initSelection = options.find(
									(option) => option.value === data || option.key === data
								)?.value;
							}
						}

						input = (
							<Typeahead
								name={fieldId}
								options={options}
								initialSelection={initSelection}
								onSelect={(e) => {
									handleValidation(e.value, fieldId, field, EventType.BLUR);
									setSubject({
										...subject,
										[field.columnName]: (
											field.uiSchema as SelectUISchema<T, keyof T>
										).onSelect(e),
									});
								}}
								shouldResetOnSelect={true}
							/>
						);
						break;
					case UIType.DIM_ATTRIBUTE_SELECT:
						// eslint-disable-next-line no-case-declarations
						const nestedSelectOptions = (field.uiSchema as SelectUISchema<T, keyof T>)
							.dimOptions;

						// eslint-disable-next-line no-case-declarations
						const initialSelection = (field.uiSchema as SelectUISchema<T, keyof T>)
							.initialSelection;

						// eslint-disable-next-line no-case-declarations
						const initalSelectionName = initialSelection
							? nestedSelectOptions
									.find((x) =>
										x.dimensionAttributes.some((y) => y.id == initialSelection)
									)
									?.dimensionAttributes.find((x) => x.id == initialSelection)
									?.name
							: '';

						// eslint-disable-next-line no-case-declarations
						const emptyTree = [
							BuildTreeViewItem(
								{
									id: -1,
									name: 'No Options were found',
								},
								[]
							),
						];
						input = (
							<SearchableTreeViewSelect
								className="no-innerscroll"
								data={
									nestedSelectOptions.length > 0
										? nestedSelectOptions.map((s) =>
												BuildTreeViewItem(s, s.dimensionAttributes)
										  )
										: emptyTree
								}
								placeHolderText={'Select an option...'}
								selectedItems={
									initialSelection
										? [
												{
													name: initalSelectionName,
													id: initialSelection.toString(),
												},
										  ]
										: []
								}
								onSelect={(e, selectedItem) => {
									const selectedValue = selectedItem.name?.toString() ?? '';
									handleValidation(selectedValue, fieldId, field, EventType.BLUR);
									setSubject({
										...subject,
										[field.columnName]: (
											field.uiSchema as SelectUISchema<T, keyof T>
										).onSelect({
											key: selectedValue,
											value: selectedValue,
										} as ISelectOption),
									});
								}}
								treeItemsExpanded={true}
							/>
						);
						break;
					case UIType.KEYMEASURE_FACT_SELECT:
						// eslint-disable-next-line no-case-declarations
						const nestedKmSelectOptions = (field.uiSchema as SelectUISchema<T, keyof T>)
							.kmOptions;

						// eslint-disable-next-line no-case-declarations
						const emptyTreeKm = [
							BuildTreeViewItem(
								{
									id: -1,
									name: 'No Options were found',
								},
								[]
							),
						];
						// eslint-disable-next-line no-case-declarations
						const initialSelectionKMF = (field.uiSchema as SelectUISchema<T, keyof T>)
							.initialSelection;

						// eslint-disable-next-line no-case-declarations
						const initalSelectionNameKMF = initialSelectionKMF
							? nestedKmSelectOptions.find((x) =>
									x.keyMeasureFacts.some((y) => y.id == initialSelectionKMF)
							  )?.name
							: '';

						input = (
							<SearchableTreeViewSelect
								className="no-innerscroll"
								data={
									nestedKmSelectOptions.length > 0
										? nestedKmSelectOptions.map((s) =>
												BuildTreeViewItem(s, s.keyMeasureFacts)
										  )
										: emptyTreeKm
								}
								placeHolderText={'Select a Key Measure Fact...'}
								onSelect={(e, selectedItem) => {
									const selectedValue = selectedItem.name?.toString() ?? '';
									handleValidation(selectedValue, fieldId, field, EventType.BLUR);
									setSubject({
										...subject,
										[field.columnName]: (
											field.uiSchema as SelectUISchema<T, keyof T>
										).onSelect({
											key: selectedValue,
											value: selectedValue,
										} as ISelectOption),
									});
								}}
								treeItemsExpanded={true}
								selectedItems={
									initialSelectionKMF
										? [
												{
													name: initalSelectionNameKMF,
													id: initialSelectionKMF.toString(),
												},
										  ]
										: []
								}
							/>
						);
						break;
					case UIType.COLOR_PICKER:
						input = (
							<TwitterPicker
								color={data?.toString()}
								onChangeComplete={(color) => {
									const { hex } = color;

									setSubject({ ...subject, [field.columnName]: hex });
								}}
							/>
						);
						break;
					case UIType.VARIABLE_INPUT_GROUP:
						input = (
							<VariableInputGroup
								name={fieldId}
								onChange={(changedData: IVariableInputListData[]) => {
									setSubject((oldSubject) => ({
										...oldSubject,
										[field.columnName]: changedData,
									}));

									setFormValid(true);
								}}
								data={subject[field.columnName] as IVariableInputListData[]}
								shouldResetOnSelect={true}
								isDisabled={false}
								placeholder={''}
								isRequired={true}
							/>
						);
						break;
					case UIType.BOOLEAN:
					case UIType.CHECKBOX:
						input = (
							<Checkbox
								id={fieldId}
								name={fieldId}
								isChecked={data as unknown as boolean}
								onChange={(e) => {
									setSubject((oldSubject) => ({
										...oldSubject,
										[field.columnName]: e,
									}));
								}}
								isRequired={showRequired}
								aria-describedby={
									field.uiSchema.helpText ? fieldId + '-helper' : undefined
								}
								area-label={field.uiSchema.helpText ? undefined : label + ' input'}
							/>
						);
						break;

					case UIType.DUAL_LIST:
						// eslint-disable-next-line no-case-declarations
						const dualOptions = dualListOptions[fieldId];

						if (dualOptions === undefined) {
							input = null;
							break;
						}

						input = (
							<DragDrop
								onDrag={(e) => {
									const cleanedId = e.droppableId.replace(
										/(-availableOptions|-selectedOptions)/g,
										''
									);
									const options = dualListOptions[cleanedId];

									setDualListOptions((prevState) => ({
										...prevState,
										cleanedId: {
											...options,
											ignoreNextSelect: true,
										},
									}));

									return true;
								}}
								onDrop={(src, dest) => {
									if (!dest) {
										return false;
									}

									const options = dualListOptions[fieldId];
									const [dropped] = options['selectedOptions'].splice(
										src.index,
										1
									);
									dropped.selected = false;
									options['selectedOptions'].splice(dest.index, 0, dropped);

									setDualListOptions((prevState) => ({
										...prevState,
										fieldId: {
											availableOptions: options['availableOptions'],
											selectedOptions: options['selectedOptions'],
											ignoreNextSelect: false,
										},
									}));

									return true;
								}}
							>
								<DualListSelector>
									<DualListSelectorPane
										searchInput={
											<SearchInput
												onChange={(event) =>
													handleLeftSearch(
														event,
														event.currentTarget.value,
														fieldId
													)
												}
												type="search"
											/>
										}
										title="Available"
										status={`${
											dualOptions['availableOptions'].filter(
												(option) => option.selected && option.isVisible
											).length
										} of ${
											dualOptions['availableOptions'].filter(
												(option) => option.isVisible
											).length
										} options selected`}
									>
										<DualListSelectorList>
											{dualOptions['availableOptions'].map((option, index) =>
												option.isVisible ? (
													<DualListSelectorListItem
														key={`${fieldId}-dual-list-${option.key}`}
														isSelected={option.selected}
														id={`${fieldId}-drag-drop-item-${index}`}
														onOptionSelect={() => {
															const options =
																dualListOptions[fieldId];

															if (options['ignoreNextSelect']) {
																setDualListOptions((prevState) => ({
																	...prevState,
																	cleanedId: {
																		...options,
																		ignoreNextSelect: false,
																	},
																}));
															}

															const newAvailable = [
																...options['availableOptions'],
															];
															newAvailable[index].selected =
																!options['availableOptions'][index]
																	.selected;
															setDualListOptions((prevState) => ({
																...prevState,
																fieldId: {
																	availableOptions: newAvailable,
																	selectedOptions: [
																		...options[
																			'selectedOptions'
																		],
																	],
																	ignoreNextSelect: false,
																},
															}));
														}}
													>
														{option.text}
													</DualListSelectorListItem>
												) : null
											)}
										</DualListSelectorList>
									</DualListSelectorPane>
									<DualListSelectorControlsWrapper>
										<DualListSelectorControl
											isDisabled={
												!dualOptions['availableOptions'].some(
													(option) => option.selected
												)
											}
											onClick={() => {
												const options = dualListOptions[fieldId];
												const [selectedItems, unselectedItems] = [
													...options['availableOptions'],
												].reduce(
													(
														accumulator: [
															IDualListOption[],
															IDualListOption[]
														],
														currentItem
													) => {
														currentItem.selected
															? accumulator[0].push({
																	...currentItem,
																	selected: false,
															  })
															: accumulator[1].push(currentItem);
														return accumulator;
													},
													[[], []] // Initial value, an array containing two empty arrays
												);

												setDualListOptions((prevState) => ({
													...prevState,
													[fieldId]: {
														availableOptions: unselectedItems,
														selectedOptions: [
															...options['selectedOptions'],
															...selectedItems,
														],
														ignoreNextSelect: false,
													},
												}));

												setUpdatedSelectedCacheItems(fieldId, [
													...options['selectedOptions'],
													...selectedItems,
												]);

												setSelectedDualListOptions([
													...options['selectedOptions'],
													...selectedItems,
												]);
											}}
											aria-label="Add selected"
										>
											<AngleRightIcon />
										</DualListSelectorControl>
										<DualListSelectorControl
											isDisabled={
												dualOptions['availableOptions'].length === 0
											}
											onClick={() => {
												const options = dualListOptions[fieldId];

												const arrayMerge = [
													...options['selectedOptions'],
													...options['availableOptions'],
												].map((item) => {
													item.selected = false;

													return item;
												});

												setDualListOptions((prevState) => ({
													...prevState,
													[fieldId]: {
														availableOptions: [],
														selectedOptions: arrayMerge,
														ignoreNextSelect: false,
													},
												}));

												setUpdatedSelectedCacheItems(fieldId, arrayMerge);
											}}
											aria-label="Add all"
										>
											<AngleDoubleRightIcon />
										</DualListSelectorControl>
										<DualListSelectorControl
											isDisabled={dualOptions['selectedOptions'].length === 0}
											onClick={() => {
												const options = dualListOptions[fieldId];

												const arrayMerge = [
													...options['selectedOptions'],
													...options['availableOptions'],
												].map((item) => {
													item.selected = false;

													return item;
												});

												setDualListOptions((prevState) => ({
													...prevState,
													[fieldId]: {
														availableOptions: arrayMerge,
														selectedOptions: [],
														ignoreNextSelect: false,
													},
												}));
											}}
											aria-label="Remove all"
										>
											<AngleDoubleLeftIcon />
										</DualListSelectorControl>
										<DualListSelectorControl
											onClick={() => {
												const options = dualListOptions[fieldId];
												const [selectedItems, unselectedItems] = [
													...options['selectedOptions'],
												].reduce(
													(
														accumulator: [
															IDualListOption[],
															IDualListOption[]
														],
														currentItem
													) => {
														currentItem.selected
															? accumulator[0].push({
																	...currentItem,
																	selected: false,
															  })
															: accumulator[1].push(currentItem);
														return accumulator;
													},
													[[], []] // Initial value, an array containing two empty arrays
												);

												setDualListOptions((prevState) => ({
													...prevState,
													[fieldId]: {
														availableOptions: [
															...options['availableOptions'],
															...selectedItems,
														],
														selectedOptions: unselectedItems,
														ignoreNextSelect: false,
													},
												}));
											}}
											isDisabled={
												!dualOptions['selectedOptions'].some(
													(option) => option.selected
												)
											}
											aria-label="Remove selected"
										>
											<AngleLeftIcon />
										</DualListSelectorControl>
									</DualListSelectorControlsWrapper>
									<DualListSelectorPane
										searchInput={
											<SearchInput
												onChange={(event) =>
													handleRightSearch(
														event,
														event.currentTarget.value,
														fieldId
													)
												}
												type="search"
											/>
										}
										title="Chosen"
										status={`${
											dualOptions['selectedOptions'].filter(
												(option) => option.selected && option.isVisible
											).length
										} of ${
											dualOptions['selectedOptions'].filter(
												(option) => option.isVisible
											).length
										} options selected`}
										isChosen
									>
										<Droppable
											hasNoWrapper
											droppableId={`${fieldId}-selectedOptions`}
											zone={`${fieldId}-zone`}
										>
											<DualListSelectorList>
												{dualOptions['selectedOptions'].map(
													(option, index) =>
														option.isVisible ? (
															<Draggable
																key={`${fieldId}-draggable-${option.key}`}
																hasNoWrapper
															>
																<DualListSelectorListItem
																	key={`${fieldId}-dual-list-${option.key}`}
																	isSelected={option.selected}
																	id={`${fieldId}-drag-drop-item-${index}`}
																	onOptionSelect={() => {
																		const options =
																			dualListOptions[
																				fieldId
																			];

																		if (
																			options[
																				'ignoreNextSelect'
																			]
																		) {
																			setDualListOptions(
																				(prevState) => ({
																					...prevState,
																					cleanedId: {
																						...options,
																						ignoreNextSelect:
																							false,
																					},
																				})
																			);
																		}

																		const newChosen = [
																			...options[
																				'selectedOptions'
																			],
																		];
																		newChosen[index].selected =
																			!options[
																				'selectedOptions'
																			][index].selected;
																		setDualListOptions(
																			(prevState) => ({
																				...prevState,
																				fieldId: {
																					availableOptions:
																						[
																							...options[
																								'availableOptions'
																							],
																						],
																					selectedOptions:
																						newChosen,
																					ignoreNextSelect:
																						false,
																				},
																			})
																		);
																	}}
																	isDraggable
																>
																	{option.text}
																</DualListSelectorListItem>
															</Draggable>
														) : null
												)}
											</DualListSelectorList>
										</Droppable>
									</DualListSelectorPane>
								</DualListSelector>
							</DragDrop>
						);
						break;
					case UIType.NUMBER:
						input = (
							<NumberInput
								id={fieldId}
								name={fieldId}
								validated={isValid ? 'default' : 'error'}
								aria-describedby={
									field.uiSchema.helpText ? fieldId + '-helper' : undefined
								}
								min={(field.uiSchema as NumberUISchema).min ?? undefined}
								max={(field.uiSchema as NumberUISchema).max ?? undefined}
								widthChars={
									(field.uiSchema as NumberUISchema).widthChars ?? undefined
								}
								area-label={field.uiSchema.helpText ? undefined : label + ' input'}
								value={(data as number) ?? 0}
								onChange={(e) => {
									const text = (e.target as HTMLInputElement).value;
									handleValidation(text, fieldId, field, EventType.CHANGE);

									setSubject({ ...subject, [field.columnName]: text });
								}}
								onPlus={() => {
									const changeData = (data as number)
										? parseInt(String(data))
										: 0;

									handleValidation(
										String(changeData + 1),
										fieldId,
										field,
										EventType.CHANGE
									);
									setSubject({
										...subject,
										[field.columnName]: changeData + 1,
									});
								}}
								onMinus={() => {
									const changeData = (data as number)
										? parseInt(String(data))
										: 0;

									handleValidation(
										String(changeData - 1),
										fieldId,
										field,
										EventType.CHANGE
									);
									setSubject({
										...subject,
										[field.columnName]: changeData - 1,
									});
								}}
							/>
						);
						break;
					case UIType.DATE:
						input = (
							<>
								<DatePicker
									id={fieldId}
									name={fieldId}
									isDisabled={isDisabled}
									aria-describedby={
										field.uiSchema.helpText ? fieldId + '-helper' : undefined
									}
									area-label={
										field.uiSchema.helpText ? undefined : label + ' input'
									}
									value={
										data === 0 || data === null || data === undefined
											? ''
											: timestampToMMDDYYYY(data as number)
									}
									placeholder="MM-DD-YYYY"
									dateFormat={dateFormat}
									dateParse={dateParse}
									onChange={(
										event: React.FormEvent<HTMLInputElement>,
										value: string,
										date?: Date
									) => {
										if (!isValidDatetime(value)) {
											updateValidations(fieldId, {
												isValid: false,
												message: 'Must be a valid Date MM-DD-YYYY.',
											});
										} else {
											updateValidations(fieldId, {
												isValid: true,
												message: '',
											});
										}

										if (!date) {
											return;
										}
										const tempData = new Date((data as number) * 1000);
										tempData.setDate(date.getDate());
										tempData.setMonth(date.getMonth());
										tempData.setFullYear(date.getFullYear());

										const val = Math.floor(tempData.getTime() / 1000.0);

										setSubject({
											...subject,
											[field.columnName]: val,
										});
									}}
								/>
								{data && data != 0 && (
									<Button
										variant="plain"
										className="btn-dp-close"
										onClick={() => {
											setSubject({
												...subject,
												[field.columnName]: null,
											});
										}}
									>
										<FontAwesomeIcon icon={faTimes} />
									</Button>
								)}
							</>
						);
						break;
					case UIType.TIME:
						input = (
							<TimePicker
								id={fieldId}
								name={fieldId}
								aria-describedby={
									field.uiSchema.helpText ? fieldId + '-helper' : undefined
								}
								area-label={field.uiSchema.helpText ? undefined : label + ' input'}
								value={data}
								onChange={(
									event: React.FormEvent<HTMLInputElement>,
									time: string,
									hour?: number,
									minute?: number,
									seconds?: number,
									isValid?: boolean
								) => {
									if (!isValid) {
										updateValidations(fieldId, {
											isValid: false,
											message: 'Must be a valid Time HH:MM AM|PM.',
										});
										return;
									} else {
										updateValidations(fieldId, { isValid: true, message: '' });
									}

									setSubject({ ...subject, [field.columnName]: time });
								}}
							/>
						);
						break;
					case UIType.DATETIME:
						input = (
							<Flex direction={{ default: 'column', lg: 'row' }}>
								<FlexItem>
									<InputGroup>
										<DatePicker
											id={`${fieldId}-date`}
											name={`${fieldId}-date`}
											aria-describedby={
												field.uiSchema.helpText
													? fieldId + '-helper'
													: undefined
											}
											area-label={
												field.uiSchema.helpText
													? undefined
													: label + ' date'
											}
											value={
												data === 0 || data === null || data === undefined
													? ''
													: timestampToMMDDYYYY(data as number)
											}
											placeholder="MM-DD-YYYY"
											dateFormat={dateFormat}
											dateParse={dateParse}
											onChange={(
												event: React.FormEvent<HTMLInputElement>,
												value: string,
												date?: Date
											) => {
												if (!isValidDatetime(value)) {
													updateValidations(fieldId, {
														isValid: false,
														message: 'Must be a valid Date MM-DD-YYYY.',
													});
												} else {
													updateValidations(fieldId, {
														isValid: true,
														message: '',
													});
												}

												if (!date) {
													return;
												}

												const tempData = new Date((data as number) * 1000);
												tempData.setDate(date.getDate());
												tempData.setMonth(date.getMonth());
												tempData.setFullYear(date.getFullYear());

												const val = Math.floor(tempData.getTime() / 1000.0);

												setSubject({
													...subject,
													[field.columnName]: val,
												});
											}}
										/>
										<TimePicker
											id={`${fieldId}-time`}
											name={`${fieldId}-time`}
											aria-describedby={
												field.uiSchema.helpText
													? fieldId + '-helper'
													: undefined
											}
											area-label={
												field.uiSchema.helpText
													? undefined
													: label + ' date'
											}
											time={timestampToHHDDAMPM(data as number)}
											style={{ width: '150px' }}
											onChange={(
												event: React.FormEvent<HTMLInputElement>,
												time: string,
												hour?: number,
												minute?: number,
												seconds?: number,
												isValid?: boolean
											) => {
												if (!isValid) {
													updateValidations(fieldId, {
														isValid: false,
														message:
															'Must be a valid Time HH:MM AM|PM.',
													});
													return;
												} else {
													updateValidations(fieldId, {
														isValid: true,
														message: '',
													});
												}

												if (
													hour === null ||
													minute === null ||
													hour === undefined ||
													minute === undefined
												) {
													updateValidations(fieldId, {
														isValid: false,
														message:
															'Must be a valid Time HH:MM AM|PM.',
													});
													return;
												}

												const tempData = new Date((data as number) * 1000);
												tempData.setHours(hour);
												tempData.setMinutes(minute);
												tempData.setSeconds(seconds ?? 0);

												const val = Math.floor(tempData.getTime() / 1000.0);

												setSubject((subject) => ({
													...subject,
													[field.columnName]: val,
												}));
											}}
										/>
									</InputGroup>
								</FlexItem>
							</Flex>
						);
						break;
				}

				if (input === null) {
					return null;
				}

				return (
					<FormGroup
						label={label}
						isRequired={showRequired}
						fieldId={fieldId}
						key={fieldId}
						helperText={field.uiSchema.helpText}
						helperTextInvalid={
							canBeInvalid ? validations.get(fieldId)?.message : undefined
						}
						helperTextInvalidIcon={canBeInvalid ? <ExclamationCircleIcon /> : undefined}
						validated={canBeInvalid ? (isValid ? 'default' : 'error') : 'default'}
						labelIcon={
							field.uiSchema.popover && (
								<Popover
									headerContent={
										<div
											dangerouslySetInnerHTML={{
												__html: field.uiSchema.popover.header,
											}}
										/>
									}
									bodyContent={
										<div
											dangerouslySetInnerHTML={{
												__html: field.uiSchema.popover.body,
											}}
										/>
									}
								>
									<button
										type="button"
										aria-label={'More info for ' + label}
										onClick={(e) => e.preventDefault()}
										aria-describedby={fieldId}
										className="pf-c-form__group-label-help"
									>
										<HelpIcon noVerticalAlign />
									</button>
								</Popover>
							)
						}
					>
						{input}
					</FormGroup>
				);
			})}

			<ActionGroup className="pull-right">
				<Button
					variant="primary"
					isDisabled={!isFormValid}
					onClick={() => {
						const isValid = validateForm(true);
						setHasFormBeenSubmitted(true);
						if (isValid) {
							props.onSubmit(subject);
						}
					}}
				>
					Submit
				</Button>
			</ActionGroup>
		</Form>
	);
}
