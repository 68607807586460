import React from 'react';
import { DatePicker } from '@patternfly/react-core';

interface Props {
	id: string;
	name: string;
	value: string | undefined;
	onChange: (value: string) => void;
}

export const ZIDatePicker = (props: Props) => {
	const { id, name, value, onChange } = props;
	const dateFormat = (date: Date) =>
		date
			.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
			.replace(/\//g, '/');
	const dateParse = (date: string) => {
		const split = date.split('/');
		if (split.length !== 3) {
			return new Date();
		}
		const month = split[0];
		const day = split[1];
		const year = split[2];
		return new Date(
			`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`
		);
	};

	return (
		<DatePicker
			id={id}
			name={name}
			value={value}
			onChange={(_event, value) => onChange(value)}
			placeholder="MM/DD/YYYY"
			dateFormat={dateFormat}
			dateParse={dateParse}
			appendTo={() => document.body}
		/>
	);
};
