import axiosConfig from '../axiosConfig';
import { TUser } from '../security/User';
import { TFolder } from '../types/folder';

export type TCard = {
	id: number;
	folder?: number | TFolder;
	name: string;
	type: string;
	chartType?: string;
	creator?: number | TUser;
	updated_at?: string;
	managed?: boolean;
	isFolder: boolean;
	isTable?: boolean;
	uuid?: string;
};

export class Card implements TCard {
	id: number;
	folder?: number | TFolder;
	name: string;
	type: string;
	chartType?: string;
	creator?: number | TUser;
	updated_at?: string;
	managed?: boolean;
	isFolder: boolean;
	isTable?: boolean;
	uuid?: string;

	constructor(card: TCard) {
		this.id = card.id;
		this.folder = card.folder;
		this.name = card.name;
		this.type = card.type;
		this.chartType = card.chartType;
		this.creator = card.creator;
		this.updated_at = card.updated_at;
		this.managed = card.managed;
		this.isFolder = card.isFolder;
		this.isTable = card.isTable;
		this.uuid = card.uuid;
	}

	public static GetAll(
		type: string,
		folderId?: number,
		expand: (keyof TCard)[] = []
	): Promise<TCard[]> {
		const apiPath = folderId ? `app/entities/${type}/${folderId}` : `app/entities/${type}`;

		return axiosConfig.instance.get<TCard[]>(apiPath, { params: { expand } }).then((res) => {
			return res.data ?? [];
		});
	}
}
