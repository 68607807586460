import { TKeyMeasureFact } from '../api/analytics/KeyMeasureFact';
import { TUnitType } from '../api/analytics/UnitType';
import {
	ChartAxisOptions,
	ChartColorAxis,
	ChartPointClickEvent,
	ChartSeries,
	ChartSeriesChartTypes,
	ZiChartOptions,
} from '../types/charts/chart-options';
import {
	OdometerSeriesType,
	SingleSeriesType,
} from '../types/charts/chart-single-series-data-options';
import { MultiSeriesType } from '../types/charts/multi-series-data-options';
import { DataOptions } from '../types/charts/series/_root';
import { numberFormatter } from '../helpers/number-formatter';
import { DraggableMenuItemData } from '../types/databuilder/databuilder';
import { IIndexable } from '../types/general';
import Highcharts from 'highcharts';
import { ChartTypesEnum } from '../enums/chart-types.enum';

// Type guard to check if an item has a 'value' property
function hasValueProperty(item: any): item is { value: number } {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return item && typeof item === 'object' && 'value' in item;
}

export class ChartBuilder {
	private readonly options: ZiChartOptions;

	constructor() {
		this.options = {
			credits: false,
		};
	}

	withTitle = (title: string): ChartBuilder => {
		this.options.title = { text: title };
		return this;
	};

	withValueTitle = (title: string): ChartBuilder => {
		this.options.yAxis = [
			{ ...this.options.yAxis, title: { text: title } },
			{ ...this.options.yAxis, title: { text: title } },
		];
		return this;
	};

	withCategoryTitle = (title: string): ChartBuilder => {
		this.options.xAxis = { ...this.options.xAxis, title: { text: title } };
		return this;
	};

	withValueCategories = (
		xAxiscategories: string[],
		yAxiscategories?: string[],
		isHeatmapChart?: boolean
	): ChartBuilder => {
		this.options.xAxis = { ...this.options.xAxis, categories: xAxiscategories, min: 0 };

		if (yAxiscategories && yAxiscategories.length > 0) {
			if (isHeatmapChart) {
				this.options.yAxis = { ...this.options.yAxis, categories: yAxiscategories, min: 0 };
			} else {
				this.options.yAxis = [
					{ ...this.options.yAxis, categories: yAxiscategories, min: 0 },
					{ ...this.options.yAxis, categories: yAxiscategories, min: 0 },
				];
			}
		}

		return this;
	};

	//dont use this function it overrides the chart colors defined in the actual series items
	withColorAxis = (colorAxis: ChartColorAxis): ChartBuilder => {
		this.options.colorAxis = colorAxis;
		return this;
	};

	withDataLabels = (enabled = false, showChartLegend = false, chartType = ''): ChartBuilder => {
		this.options.series = this.options.series?.map((cs) => {
			return {
				...cs,
				dataLabels: {
					enabled: chartType === 'wordcloud' ? false : enabled,
				},
			};
		});
		this.options.legend = { enabled: showChartLegend };
		return this;
	};

	withScrollbar = (showScrollBar = false): ChartBuilder => {
		this.options.xAxis = {
			...this.options.xAxis,
			scrollbar: { enabled: showScrollBar, barBackgroundColor: '#00ff00' },
		};
		return this;
	};

	withValueAxisLabelsShown = (
		showYAxis = true,
		showXAxis = true,
		series: ChartSeries[],
		unitType?: TUnitType,
		selected2ndUnitType?: TUnitType,
		isSingleSeries = true,
		chartType = '',
		showSecondYaxis = false,
		yAxisFirstHeader = '',
		yAxisSecondHeader = '',
		xAxisFirstHeader = '',
		showPercentages?: boolean,
		numberOfDecimals?: number,
		secondFactnumberOfDecimals?: number,
		droppedFacts?: DraggableMenuItemData[]
	): ChartBuilder => {
		if (chartType !== 'odometer') {
			const ticks =
				!showPercentages || chartType === 'heatmap' ? undefined : [0, 20, 40, 60, 80, 100];

			this.options.yAxis = [
				{
					...this.options.yAxis,
					tickPositions: ticks,
					labels: {
						enabled: showYAxis,
					},
					title: {
						text: showPercentages ? 'Percentage (Total)' : yAxisFirstHeader,
					},
				},
			];

			const bothUnitTypesArePercentage =
				unitType?.code == 'PCT' && selected2ndUnitType?.code == 'PCT';

			//eslint-disable-next-line @typescript-eslint/no-inferrable-types
			let isSeriesItemsExponentiallyBigger = false;
			//eslint-disable-next-line @typescript-eslint/no-inferrable-types
			let maxValue1 = 0;
			//eslint-disable-next-line @typescript-eslint/no-inferrable-types
			let maxValue2 = 0;
			//eslint-disable-next-line @typescript-eslint/no-inferrable-types
			let minValue1 = 0;
			//eslint-disable-next-line @typescript-eslint/no-inferrable-types
			let minValue2 = 0;
			//eslint-disable-next-line @typescript-eslint/no-inferrable-types
			let maxValueBothYaxis = 0;

			if (
				series &&
				series[0]?.data &&
				series[0]?.data?.length > 0 &&
				series[1]?.data &&
				series[1]?.data?.length > 0
			) {
				const seriesData1 = series[0].data as Array<{ value: number } | null>;
				maxValue1 = Math.max(
					...seriesData1.filter(hasValueProperty).map((item) => item['value'])
				);
				minValue1 = Math.min(
					...seriesData1.filter(hasValueProperty).map((item) => item['value'])
				);

				const seriesData2 = series[1].data as Array<{ value: number } | null>;
				maxValue2 = Math.max(
					...seriesData2.filter(hasValueProperty).map((item) => item['value'])
				);
				minValue2 = Math.min(
					...seriesData2.filter(hasValueProperty).map((item) => item['value'])
				);

				// Check conditions
				const condition1 = maxValue1 >= maxValue2 * 100;
				const condition2 = maxValue2 >= maxValue1 * 100;

				// Set the variable to true if either condition is met
				isSeriesItemsExponentiallyBigger = condition1 || condition2;

				//set inital  max value for both facts
				maxValueBothYaxis = maxValue1;

				//check if 2nd facts max is greater than first facts max
				if (maxValue2 > maxValue1) {
					maxValueBothYaxis = maxValue2;
				}
			}

			const sameYaxis = yAxisFirstHeader == yAxisSecondHeader;
			if (showSecondYaxis && sameYaxis) {
				this.options.yAxis = [
					{
						...this.options.yAxis,
						min: minValue1,
						max:
							(!isSeriesItemsExponentiallyBigger ? maxValueBothYaxis : maxValue1) *
							1.2,
						tickPositions: ticks,
						startOnTick: true,
						endOnTick: true,
						labels: {
							enabled: showYAxis,
						},
						title: {
							text: showPercentages ? 'Percentage (Total)' : yAxisFirstHeader,
						},
					},
					{
						...this.options.yAxis,
						min: minValue2,
						max:
							(!isSeriesItemsExponentiallyBigger ? maxValueBothYaxis : maxValue2) *
							1.2,
						tickPositions: ticks,
						startOnTick: true,
						endOnTick: true,
						labels: {
							enabled: isSeriesItemsExponentiallyBigger,
						},
						title: {
							text: undefined,
						},
						opposite: isSeriesItemsExponentiallyBigger,
						...(bothUnitTypesArePercentage && { linkedTo: 0 }),
					},
				];
			} else if (showSecondYaxis) {
				this.options.yAxis = [
					{
						...this.options.yAxis,
						min: 0,
						max: maxValueBothYaxis,
						tickPositions: ticks,
						startOnTick: true,
						endOnTick: true,
						labels: {
							enabled: showYAxis,
						},
						title: {
							text: showPercentages ? 'Percentage (Total)' : yAxisFirstHeader,
						},
					},
					{
						...this.options.yAxis,
						min: 0,
						max: maxValueBothYaxis,
						tickPositions: ticks,
						startOnTick: true,
						endOnTick: true,
						labels: {
							enabled: showYAxis,
						},
						title: {
							text: showPercentages ? 'Percentage (Total)' : yAxisSecondHeader,
						},
						opposite: true,
					},
				];
			}
		}

		this.options.xAxis = {
			...this.options.xAxis,
			labels: {
				enabled: showXAxis,
			},
			title: {
				text: xAxisFirstHeader,
			},
		};

		const stackedChartTypes = [
			'stackedbar',
			'stackedcolumn',
			'clusteredcolumn',
			'clusteredline',
			'bar',
			'column',
		];

		//sankey and depedency wheel must have their tooltips specified as an override from the .withSeries function
		//therefore dont set tool tips here for those chart types
		if (chartType == 'sankey' || chartType == 'dependencywheel') {
			return this;
		}

		const isStacking = stackedChartTypes.includes(chartType);
		const isMultiFact =
			series.length > 1 &&
			chartType !== ChartTypesEnum.Columnrange &&
			chartType !== ChartTypesEnum.Donut &&
			chartType !== ChartTypesEnum.Pie;

		if (
			chartType === 'clusteredbar' ||
			chartType === 'clusteredcolumn' ||
			chartType === 'clusteredline'
		) {
			(this.options.yAxis as ChartAxisOptions[])?.map(
				(yAxis) => (yAxis.categories = undefined)
			);
			this.options.tooltip = {
				valuePrefix: unitType?.prefix,
				formatter: function () {
					return `<b>${this.point.series.name ?? ''}</b>: ${
						!showPercentages ? unitType?.prefix ?? '' : ''
					}${numberFormatter(this.point.y, numberOfDecimals)}${
						!showPercentages ? unitType?.suffix ?? '' : '%'
					}`;
				},
			};
		} else if (!isSingleSeries) {
			if (unitType && unitType.prefix && unitType.suffix) {
				if (!isStacking && chartType !== 'clusteredbar') {
					this.options.tooltip = {
						formatter: function () {
							return `${
								!showPercentages ? unitType.prefix ?? '' : ''
							}${numberFormatter(this.point.y, numberOfDecimals)}${
								!showPercentages ? unitType.suffix ?? '' : '%'
							}`;
						},
					};
				} else {
					this.options.tooltip = {
						formatter: function () {
							return `${this.point.category ?? ''}<br>${
								!showPercentages ? unitType.prefix ?? '' : ''
							}${numberFormatter(this.point.y, numberOfDecimals)}${
								!showPercentages ? unitType.suffix ?? '' : '%'
							}`;
						},
					};
				}
			} else if (unitType && unitType.prefix) {
				if (!isStacking) {
					this.options.tooltip = {
						formatter() {
							return `${
								!showPercentages ? unitType.prefix ?? '' : ''
							}${numberFormatter(this.point.y, numberOfDecimals)}${
								showPercentages ? '%' : ''
							}`;
						},
					};
				} else {
					this.options.tooltip = {
						formatter() {
							return `${this.point.category ?? ''}<br>${
								!showPercentages ? unitType.prefix ?? '' : ''
							}${numberFormatter(this.point.y, numberOfDecimals)}${
								showPercentages ? '%' : ''
							}`;
						},
					};
				}
			} else if (unitType && unitType.suffix) {
				if (!isStacking) {
					this.options.tooltip = {
						formatter() {
							return `${
								chartType === 'heatmap'
									? numberFormatter(this.point.value ?? 0, numberOfDecimals)
									: numberFormatter(this.point.y, numberOfDecimals)
							}${!showPercentages ? unitType.suffix ?? '' : '%'}`;
						},
					};
				} else {
					this.options.tooltip = {
						formatter() {
							return `${this.point.category ?? ''}<br>${numberFormatter(
								this.point.y,
								numberOfDecimals
							)}${!showPercentages ? unitType.suffix ?? '' : '%'}`;
						},
					};
				}
			} else {
				if (!isStacking) {
					this.options.tooltip = {
						formatter() {
							return `${
								chartType === 'heatmap'
									? numberFormatter(this.point.value ?? 0, numberOfDecimals)
									: numberFormatter(this.point.y, numberOfDecimals)
							}${showPercentages ? '%' : ''}`;
						},
					};
				} else {
					this.options.tooltip = {
						formatter() {
							return `${this.point.category ?? ''}<br>${numberFormatter(
								this.point.y,
								numberOfDecimals
							)}${showPercentages ? '%' : ''}`;
						},
					};
				}
			}

			if (unitType && unitType.prefix && unitType?.code != 'PCT') {
				this.options.tooltip = {
					valuePrefix: unitType.prefix,
					valueSuffix: unitType.suffix,
					formatter: function () {
						return `<b>${
							chartType === 'heatmap'
								? this.series.chart.yAxis[0].categories[this.point.y ?? 0]
								: ''
						}</b><br>
							 <b>${this.series.chart.xAxis[0].categories[this.point.x ?? 0]}</b>:
							${!showPercentages ? unitType.prefix ?? '' : ''}
							${
								chartType === 'heatmap'
									? numberFormatter(this.point.value ?? 0, numberOfDecimals)
									: numberFormatter(this.point.y, numberOfDecimals)
							}
							${!showPercentages ? unitType.suffix ?? '' : '%'}`;
					},
				};
			}
		} else if (unitType) {
			if (chartType !== 'odometer') {
				(this.options.yAxis as ChartAxisOptions[])?.map(
					(yaxis) => (yaxis.categories = undefined)
				);

				if (isMultiFact == false) {
					this.options.tooltip = {
						formatter: function () {
							const prefix = !showPercentages ? unitType?.prefix ?? '' : '';
							const suffix = !showPercentages ? unitType?.suffix ?? '' : '%';

							const columnrangePoint = `<b>${prefix}
								${numberFormatter(this.point?.low, numberOfDecimals)}${suffix} - ${prefix}${numberFormatter(
								this.point?.high,
								numberOfDecimals
							)}${suffix}</b>`;

							const point = `${prefix}${
								chartType === 'heatmap'
									? numberFormatter(this.point.value ?? 0, numberOfDecimals)
									: numberFormatter(this.point.y, numberOfDecimals)
							} ${suffix}`;

							const tooltip =
								(chartType !== 'pie' &&
									chartType !== 'donut' &&
									chartType !== 'funnel' &&
									chartType !== 'pyramid') ||
								showPercentages
									? chartType === ChartTypesEnum.Columnrange
										? `${this.point?.category}: ${columnrangePoint}`
										: `<b>${this.point?.name}</b> </br> ${point}`
									: `<b>${this.point?.name}</b> </br> ${
											this.point?.percentage?.toFixed(numberOfDecimals) ?? '0'
									  }% (n=${point})`;

							return `${tooltip}`;
						},
					};
				}
			}

			if (isMultiFact) {
				//correctly set chart label plotValues & the chart tooltip plotValues to include second series UnitType
				this.options.series = series.map((s, idx) => {
					return {
						...s,
						data: series[idx].data,
						tooltip: {
							headerFormat: '',
							pointFormatter: function () {
								const point = `${
									!showPercentages
										? idx == 1
											? selected2ndUnitType?.prefix ?? ''
											: unitType?.prefix ?? ''
										: ''
								}${numberFormatter(
									this.y ?? 0,
									idx == 1 ? secondFactnumberOfDecimals : numberOfDecimals
								)} ${
									!showPercentages
										? idx == 1
											? selected2ndUnitType?.suffix ?? ''
											: unitType?.suffix ?? ''
										: '%'
								}`;

								return !showPercentages
									? `<b>${this.name}</b> </br> ${point}`
									: `<b>${this.name}</b> </br> ${
											this.percentage
												? this.percentage?.toFixed(
														idx == 1
															? secondFactnumberOfDecimals
															: numberOfDecimals
												  )
												: ''
									  }`;
							},
						},
						dataLabels: {
							enabled: true,
							formatter: function () {
								if (idx == 0) {
									return `${
										!showPercentages ? unitType?.prefix ?? '' : ''
									}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
										!showPercentages ? unitType?.suffix ?? '' : '%'
									}`;
								}
								if (idx == 1) {
									return `${
										!showPercentages ? selected2ndUnitType?.prefix ?? '' : ''
									}${numberFormatter(
										this.point.y ?? 0,
										secondFactnumberOfDecimals
									)} ${
										!showPercentages ? selected2ndUnitType?.suffix ?? '' : '%'
									}`;
								}
							},
						},
					} as ChartSeries;
				});
			}
		}

		return this;
	};

	withTransparentBackground = () => {
		if (this.options.chart) {
			this.options.chart.backgroundColor = 'transparent';
		}
	};

	withSize = (height: number | string, width: number | string) => {
		if (this.options.chart) {
			this.options.chart.height = height;
			this.options.chart.width = width;
		}
	};

	withSeries = <T extends ChartSeries>(
		type: ChartSeriesChartTypes,
		series: T[],
		maxColor: string,
		enableStacking: boolean,
		unitType?: TUnitType,
		selected2ndUnitType?: TUnitType,
		ApplyDrilldownDrillIn?: ((name: string, isKMF: boolean) => void) | undefined,
		lookupData?: (string | number)[][],
		keyMeasureFact?: TKeyMeasureFact | null | undefined,
		secondYAxisIsLine?: boolean,
		showPercentages?: boolean,
		numberOfDecimals?: number,
		droppedFacts?: DraggableMenuItemData[]
	): ChartBuilder => {
		const typesSpecialClickEvent = ['dependencywheel', 'heatmap', 'sankey'];
		if (type) {
			this.options.chart = { ...this.options.chart, type: returnHighChartsChartType(type) };
		}

		this.options.boost = { enabled: true, useGPUTranslations: true };
		this.options.plotOptions = {
			...this.options.plotOptions,
			series: {
				...this.options.plotOptions?.series,
				stacking: null,
				grouping: true,
				cursor: 'pointer',
				point: {
					events: {
						click: function (event: ChartPointClickEvent) {
							if (typesSpecialClickEvent.includes(type ?? '')) {
								const xCategory = this.series?.xAxis.categories[this.x ?? 0];
								event.point.name = xCategory ?? '';
							}
							handleOnChartVisualClicked(event, ApplyDrilldownDrillIn);
						},
					},
				},
			},
			bar: { stacking: null, grouping: true },
			column: { stacking: null, grouping: true },
		};

		if (type !== 'columnrange') {
			this.options.series = series.map((s, idx) => {
				const chartType = series[idx].type?.toString() ?? 'bar';
				return {
					...s,
					data: series[idx].data,
					type: returnHighChartsChartType(chartType),
				};
			});
		}

		switch (type) {
			case 'funnel':
			case 'pyramid':
			case 'donut':
			case 'pie':
				this.options.chart = {
					...this.options.chart,
					type: returnHighChartsChartType(type),
					events: {
						render: function () {
							const chart = this as Highcharts.Chart & {
									options: ZiChartOptions;
									customLabel?: Highcharts.SVGElement;
								},
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								series = chart && chart.series ? (chart.series[0] as any) : null;

							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							if (chart.customLabel) {
								chart.customLabel.destroy();
								chart.customLabel = undefined;
							}

							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
							if (series && series.data && type === 'donut') {
								const customLabel: Highcharts.SVGElement = chart.renderer
									.label(
										`Total<br/><strong>${
											!showPercentages ? unitType?.prefix ?? '' : ''
										}${numberFormatter(
											// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
											series.data[0]?.total,
											numberOfDecimals
										)} ${
											!showPercentages ? unitType?.suffix ?? '' : '%'
										}</strong>`,
										0,
										0
									)
									.css({
										color: '#000',
										textAnchor: 'middle',
									})
									.add();

								if (customLabel) {
									// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
									if (series.center) {
										// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
										const [centerX, centerY, diameter] = series.center as [
											number,
											number,
											number
										];
										const x = centerX + chart.plotLeft;
										const y =
											centerY +
											chart.plotTop -
											(customLabel.attr('height')! as any) / 2;
										customLabel.attr({ x, y });
										customLabel.css({
											fontSize: `${diameter / 12}px`,
										});
									}

									chart.customLabel = customLabel;
								}
							}
						},
					},
				};

				this.options.plotOptions = {
					...this.options.plotOptions,
					series: {
						...this.options.plotOptions?.series,
						dataLabels: {
							enabled: true,
							formatter: function () {
								const point = `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;

								return showPercentages
									? `<b>${this.point?.name}</b> </br> ${point}`
									: `<b>${this.point?.name}</b> </br> <b>${
											this.point?.percentage?.toFixed(numberOfDecimals) ?? '0'
									  }% (n=${point})</b>`;
							},
						},
					},
				};

				this.options.series = series.map((s) => {
					return {
						...s,
						innerSize: type === 'pie' ? '0%' : '75%',
						data: series[0].data,
					} as SingleSeriesType | MultiSeriesType;
				});

				break;
			case 'wordcloud': {
				this.options.series = series.map((s) => {
					const sum =
						s.data.length > 0
							? s.data
									.map((d) => (d as DataOptions).value ?? 0)
									.reduce((a, b) => a + b)
							: 0;

					const hasLongName = series[0].data.some((d) => {
						const length = (d as DataOptions).name?.length ?? 0;
						return length > 35;
					});

					return {
						...s,
						type: 'wordcloud',
						data: series[0].data.map((d) => {
							const dataOptions = d as DataOptions;
							const percent = ((dataOptions.value ?? 0) * 100) / (sum != 0 ? sum : 1);
							const weight = calculateWordCloudWeight(
								percent,
								series[0].data.length,
								hasLongName
							);

							return {
								name: dataOptions.name,
								y: dataOptions.y,
								value: dataOptions.value,
								weight: weight,
							};
						}),
					} as ChartSeries;
				});
				break;
			}
			case 'odometer': {
				this.options.pane = {
					center: ['50%', '85%'],
					size: '140%',
					startAngle: -90,
					endAngle: 90,
					background: {
						backgroundColor: '#EEE',
						innerRadius: '60%',
						outerRadius: '100%',
						shape: 'arc',
					},
				};

				this.options.series = series.map((s) => {
					const odometerType = s as OdometerSeriesType;

					return {
						...s,
						name: odometerType.name,
						type: 'solidgauge',
						data: [odometerType.data[0]],
					} as OdometerSeriesType;
				});

				const firstSeries = this.options.series[0] as OdometerSeriesType;
				const data = (firstSeries?.data as (number | null)[]) ?? [];
				let max = 0;

				if (data.length > 0 && data[0]) {
					if (typeof data[0] === 'number') {
						max = data[0] + data[0] * 0.1;
					} else {
						const dataIndex = data[0] as IIndexable;
						max = Number(dataIndex.value) + Number(dataIndex.value) * 0.1;
					}
				}

				this.options.plotOptions = {
					solidgauge: {
						dataLabels: {
							y: -20,
							enabled: true,
							borderWidth: 0,
							useHTML: true,
							formatter: function () {
								return `<div style="text-align:center">
									<span style="font-size:15px">${!showPercentages ? unitType?.prefix ?? '' : ''}${numberFormatter(
									this.point.y ?? 0,
									numberOfDecimals
								)}${!showPercentages ? unitType?.suffix ?? '' : '%'}</span></div>`;
							},
						},
					},
				};

				this.options.tooltip = {
					enabled: false,
				};

				this.options.yAxis = [
					{
						min:
							keyMeasureFact && keyMeasureFact.minimum_value
								? keyMeasureFact.minimum_value
								: 0,
						max:
							keyMeasureFact && keyMeasureFact.maximum_value
								? keyMeasureFact.maximum_value
								: max,
						lineWidth: 0,
						tickWidth: 0,
						minorTickInterval: null,
						tickAmount: 0,
						title: {
							enabled: false,
						},
						labels: {
							y: 10,
						},
						stops: [[1, maxColor ?? '#55BF3B']],
					},
				];
				break;
			}
			case 'packedbubble': {
				const bubbleCount = series && series[0] ? series[0].data.length : 0;

				this.options.plotOptions = {
					...this.options.plotOptions,
					packedbubble: {
						...this.options.plotOptions?.series,
						minSize: '30%',
						maxSize: bubbleCount <= 2 ? '105%' : '140%',
						layoutAlgorithm: {
							splitSeries: false,
							gravitationalConstant: 0.01,
						},
						dataLabels: {
							enabled: true,
							formatter: function () {
								const point = `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;

								return `${this.point?.name} </br> ${point}`;
							},
						},
					},
				};

				this.options.series = series.map((s) => {
					return { ...s, type, data: series[0].data } as ChartSeries;
				});
				break;
			}
			case 'columnrange':
				if (this.options.chart) {
					this.options.chart.inverted = true;
				}

				this.options.plotOptions = {
					...this.options.plotOptions,
					columnrange: {
						...this.options.plotOptions?.series,
						borderRadius: '50%',
						dataLabels: {
							enabled: true,
							formatter: function () {
								const point = `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.y ?? 0, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;

								return `${point}`;
							},
						},
					},
				};

				if (series.length == 1) {
					this.options.series = series.map((s) => {
						return { ...s, type, data: series[0].data } as ChartSeries;
					});
				}
				break;
			case 'clusteredbar':
				this.options.series = series.map((s) => {
					return {
						...s,
						type: 'bar',
					} as SingleSeriesType | MultiSeriesType;
				});
				this.options.plotOptions = {
					bar: {
						stacking: null,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
						point: {
							events: {
								click: function (event: ChartPointClickEvent) {
									handleOnChartVisualClicked(event, ApplyDrilldownDrillIn);
								},
							},
						},
					},
				};
				break;
			case 'stackedbar':
				this.options.series = series.map((s) => {
					return {
						...s,
						type: 'bar',
					} as SingleSeriesType | MultiSeriesType;
				});

				if (enableStacking) {
					this.options.plotOptions = {
						bar: {
							stacking: 'normal', // This is crucial for stacked column charts
							point: {
								events: {
									click: function (event: ChartPointClickEvent) {
										handleOnChartVisualClicked(event, ApplyDrilldownDrillIn);
									},
								},
							},
							dataLabels: {
								enabled: true,
								formatter: function () {
									return `${
										!showPercentages ? unitType?.prefix ?? '' : ''
									}${numberFormatter(this.point.y, numberOfDecimals)} ${
										!showPercentages ? unitType?.suffix ?? '' : '%'
									}`;
								},
							},
						},
					};
					this.options.yAxis = [
						{
							stackLabels: {
								enabled: true,
								formatter: function () {
									return `${unitType?.prefix ?? ''}${numberFormatter(
										this.total,
										numberOfDecimals
									)} ${unitType?.suffix ?? ''}`;
								},
							},
						},
					];
				}

				break;
			case 'clusteredcolumn':
				this.options.series = series.map((s) => {
					return { ...s, type: 'column' } as ChartSeries;
				});
				this.options.plotOptions = {
					column: {
						stacking: null,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
						point: {
							events: {
								click: function (event: ChartPointClickEvent) {
									handleOnChartVisualClicked(event, ApplyDrilldownDrillIn);
								},
							},
						},
					},
				};
				break;
			case 'stackedcolumn':
				this.options.series = series.map((s) => {
					return { ...s, type: 'column' } as ChartSeries;
				});

				if (enableStacking) {
					this.options.plotOptions = {
						column: {
							stacking: 'normal', // This is crucial for stacked column charts
							point: {
								events: {
									click: function (event: ChartPointClickEvent) {
										handleOnChartVisualClicked(event, ApplyDrilldownDrillIn);
									},
								},
							},
							dataLabels: {
								enabled: true,
								formatter: function () {
									return `${
										!showPercentages ? unitType?.prefix ?? '' : ''
									}${numberFormatter(this.point.y, numberOfDecimals)} ${
										!showPercentages ? unitType?.suffix ?? '' : '%'
									}`;
								},
							},
						},
					};
					this.options.yAxis = [
						{
							stackLabels: {
								enabled: true,
								formatter: function () {
									return `${
										!showPercentages ? unitType?.prefix ?? '' : ''
									}${numberFormatter(this.total, numberOfDecimals)} ${
										!showPercentages ? unitType?.suffix ?? '' : '%'
									}`;
								},
							},
						},
					];
				}
				break;
			case 'clusteredline':
				this.options.series = series.map((s) => {
					return { ...s, type: 'line' } as ChartSeries;
				});
				this.options.plotOptions = {
					area: {
						stacking: 'normal',
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
						point: {
							events: {
								click: function (event: ChartPointClickEvent) {
									handleOnChartVisualClicked(event, ApplyDrilldownDrillIn);
								},
							},
						},
					},
				};
				break;
			case 'line':
				this.options.plotOptions = {
					...this.options.plotOptions,
					series: {
						...this.options.plotOptions?.series,
						color: maxColor,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
					},
				};

				this.options.series = series.map((s, idx) => {
					return {
						...s,
						type: idx == 0 ? 'line' : s.type,
					} as ChartSeries;
				});
				break;
			case 'scatter':
				this.options.plotOptions = {
					...this.options.plotOptions,
					scatter: {
						...this.options.plotOptions?.series,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
					},
				};

				if (series.length > 1) {
					this.options.plotOptions = {
						...this.options.plotOptions,
						series: {
							...this.options.plotOptions?.series,
							dataLabels: {
								enabled: true,
								formatter: function () {
									return `${
										!showPercentages ? unitType?.prefix ?? '' : ''
									}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
										!showPercentages ? unitType?.suffix ?? '' : '%'
									}`;
								},
							},
						},
					};
				}

				this.options.series = series.map((s, idx) => {
					return {
						...s,
						type: idx == 1 ? (secondYAxisIsLine ? 'line' : 'column') : s.type,
					} as ChartSeries;
				});
				break;
			case 'heatmap':
				this.options.plotOptions = {
					series: {
						...this.options.plotOptions?.series,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.value ?? 0, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
					},
				};

				this.options.series = series.map((s) => {
					return {
						...s,
						type: 'heatmap',
						data: series[0].data ? series[0]?.data : [],
					} as ChartSeries;
				});
				break;
			case 'sankey':
			case 'dependencywheel':
				// eslint-disable-next-line no-case-declarations
				const combinedData = series.map((item) => {
					//fix for bug when you switch from clusteredbar or from clusteredcolumn to sankey it would throw a highcharts error
					if (item.data[0] == null || item.data[1] == null || item.data[2] == null) {
						return [];
					}
					return [
						item.data[0], // FacilityName
						item.data[1], // Region
						parseFloat(item.data[2]?.toString() ?? '0'), // EmployeeBasePay
					];
				});
				(this.options.tooltip = {
					formatter: function () {
						const fromNodeName =
							this.point && this.point.fromNode
								? `${this.point.fromNode.name} \u2192 `
								: this.point?.name;
						const toNodeName = this.point?.toNode?.name ?? '';
						const weight = this.point?.weight ?? this.point?.sum ?? 0;

						return `${fromNodeName ?? ''}${toNodeName}: ${
							!showPercentages ? unitType?.prefix ?? '' : ''
						}${numberFormatter(weight, numberOfDecimals)}${
							!showPercentages ? unitType?.suffix ?? '' : '%'
						}`;
					},
				}),
					(this.options.series = series.map((s) => {
						return {
							...s,
							type,
							data: combinedData,
							curveFactor: 0.6,
							states: {
								hover: {
									linkOpacity: 1,
								},
								inactive: {
									linkOpacity: 0.005,
								},
							},
						} as ChartSeries;
					}));
				break;
			default:
				this.options.plotOptions = {
					...this.options.plotOptions,
					series: {
						...this.options.plotOptions?.series,
						dataLabels: {
							enabled: true,
							formatter: function () {
								return `${
									!showPercentages ? unitType?.prefix ?? '' : ''
								}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
									!showPercentages ? unitType?.suffix ?? '' : '%'
								}`;
							},
						},
					},
				};

				if (series.length == 1) {
					this.options.series = series.map((s, idx) => {
						return { ...s, data: series[0].data } as ChartSeries;
					});
				} else {
					this.options.series = series.map((s, idx) => {
						return {
							...s,
							data: series[idx].data,
							dataLabels: {
								enabled: true,
								formatter: function () {
									if (idx == 0) {
										return `${
											!showPercentages ? unitType?.prefix ?? '' : ''
										}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
											!showPercentages ? unitType?.suffix ?? '' : '%'
										}`;
									}
									if (idx == 1) {
										return `${
											!showPercentages
												? selected2ndUnitType?.prefix ?? ''
												: ''
										}${numberFormatter(this.point.y ?? 0, numberOfDecimals)} ${
											!showPercentages
												? selected2ndUnitType?.suffix ?? ''
												: '%'
										}`;
									}
								},
							},
						} as ChartSeries;
					});
				}

				break;
		}

		return this;
	};

	build = (): ZiChartOptions => {
		return this.options;
	};
}

export const returnHighChartsChartType = (inputChartType: string | undefined) => {
	switch (inputChartType) {
		case 'clusteredcolumn':
		case 'stackedcolumn':
			return 'column';
		case 'clusteredbar':
		case 'stackedbar':
			return 'bar';
		case 'clusteredline':
			return 'line';
		case 'donut':
			return 'pie';
		case 'odometer':
			return 'solidgauge';
	}

	return inputChartType as ChartSeriesChartTypes;
};

const calculateWordCloudWeight = (
	percent: number,
	recordLength: number,
	hasLongName: boolean
): number => {
	let weight = 0;

	if (hasLongName) {
		switch (true) {
			case percent < 20:
				weight = 0.2;
				break;
			case percent > 20 && percent < 40:
				weight = 0.4;
				break;
			case percent > 40 && percent < 60:
				weight = 0.6;
				break;
			case percent > 60 && percent < 80:
				weight = 0.8;
				break;
			default:
				weight = 1;
				break;
		}
	} else {
		if (recordLength < 10) {
			switch (true) {
				case percent < 10:
					weight = 0.1;
					break;
				case percent > 10 && percent < 20:
					weight = 0.2;
					break;
				case percent > 20 && percent < 30:
					weight = 0.3;
					break;
				case percent > 30 && percent < 40:
					weight = 0.4;
					break;
				case percent > 40 && percent < 50:
					weight = 0.5;
					break;
				case percent > 50 && percent < 60:
					weight = 0.6;
					break;
				case percent > 60 && percent < 70:
					weight = 0.7;
					break;
				case percent > 70 && percent < 80:
					weight = 0.8;
					break;
				case percent > 80 && percent < 90:
					weight = 0.9;
					break;
				default:
					weight = 1;
					break;
			}
		} else if (recordLength >= 10 && recordLength <= 15) {
			switch (true) {
				case percent < 5:
					weight = 0.05;
					break;
				case percent > 5 && percent < 10:
					weight = 0.1;
					break;
				case percent > 10 && percent < 15:
					weight = 0.15;
					break;
				case percent > 15 && percent < 20:
					weight = 0.2;
					break;
				case percent > 20 && percent < 25:
					weight = 0.25;
					break;
				case percent > 25 && percent < 30:
					weight = 0.3;
					break;
				case percent > 30 && percent < 35:
					weight = 0.35;
					break;
				case percent > 35 && percent < 40:
					weight = 0.4;
					break;
				case percent > 40 && percent < 45:
					weight = 0.45;
					break;
				case percent > 45 && percent < 50:
					weight = 0.5;
					break;
				case percent > 50 && percent < 55:
					weight = 0.55;
					break;
				case percent > 55 && percent < 60:
					weight = 0.6;
					break;
				case percent > 60 && percent < 65:
					weight = 0.65;
					break;
				case percent > 65 && percent < 70:
					weight = 0.7;
					break;
				case percent > 70 && percent < 75:
					weight = 0.75;
					break;
				case percent > 75 && percent < 80:
					weight = 0.8;
					break;
				case percent > 80 && percent < 85:
					weight = 0.85;
					break;
				case percent > 85 && percent < 90:
					weight = 0.9;
					break;
				case percent > 90 && percent < 95:
					weight = 0.95;
					break;
				default:
					weight = 1;
					break;
			}
		} else {
			switch (true) {
				case percent < 2.5:
					weight = 0.025;
					break;
				case percent > 2.5 && percent < 5:
					weight = 0.05;
					break;
				case percent > 5 && percent < 7.5:
					weight = 0.075;
					break;
				case percent > 7.5 && percent < 10:
					weight = 0.1;
					break;
				case percent > 10 && percent < 12.5:
					weight = 0.125;
					break;
				case percent > 12.5 && percent < 15:
					weight = 0.15;
					break;
				case percent > 15 && percent < 17.5:
					weight = 0.175;
					break;
				case percent > 17.5 && percent < 20:
					weight = 0.2;
					break;
				case percent > 20 && percent < 22.5:
					weight = 0.225;
					break;
				case percent > 22.5 && percent < 25:
					weight = 0.25;
					break;
				case percent > 25 && percent < 27.5:
					weight = 0.275;
					break;
				case percent > 27.5 && percent < 30:
					weight = 0.3;
					break;
				case percent > 30 && percent < 32.5:
					weight = 0.325;
					break;
				case percent > 32.5 && percent < 35:
					weight = 0.35;
					break;
				case percent > 35 && percent < 37.5:
					weight = 0.375;
					break;
				case percent > 37.5 && percent < 40:
					weight = 0.4;
					break;
				case percent > 40 && percent < 42.5:
					weight = 0.425;
					break;
				case percent > 42.5 && percent < 45:
					weight = 0.45;
					break;
				case percent > 45 && percent < 47.5:
					weight = 0.475;
					break;
				case percent > 47.5 && percent < 50:
					weight = 0.5;
					break;
				case percent > 50 && percent < 52.5:
					weight = 0.525;
					break;
				case percent > 52.5 && percent < 55:
					weight = 0.55;
					break;
				case percent > 55 && percent < 57.5:
					weight = 0.575;
					break;
				case percent > 57.5 && percent < 60:
					weight = 0.6;
					break;
				case percent > 60 && percent < 62.5:
					weight = 0.625;
					break;
				case percent > 62.5 && percent < 65:
					weight = 0.65;
					break;
				case percent > 65 && percent < 67.5:
					weight = 0.675;
					break;
				case percent > 67.5 && percent < 70:
					weight = 0.7;
					break;
				case percent > 70 && percent < 72.5:
					weight = 0.725;
					break;
				case percent > 72.5 && percent < 75:
					weight = 0.75;
					break;
				case percent > 75 && percent < 77.5:
					weight = 0.775;
					break;
				case percent > 77.5 && percent < 80:
					weight = 0.8;
					break;
				case percent > 80 && percent < 82.5:
					weight = 0.825;
					break;
				case percent > 82.5 && percent < 85:
					weight = 0.85;
					break;
				case percent > 85 && percent < 87.5:
					weight = 0.875;
					break;
				case percent > 87.5 && percent < 90:
					weight = 0.9;
					break;
				case percent > 90 && percent < 92.5:
					weight = 0.925;
					break;
				case percent > 92.5 && percent < 95:
					weight = 0.95;
					break;
				case percent > 95 && percent < 97.5:
					weight = 0.975;
					break;
				default:
					weight = 1;
					break;
			}
		}
	}

	return weight;
};

const handleOnChartVisualClicked = (
	event: ChartPointClickEvent,
	ApplyDrilldownDrillIn?: ((name: string, isKMF: boolean) => void) | undefined
) => {
	let name = '';
	if (event.point.fromNode) {
		name = event.point.fromNode.name;
	} else if (event.point.name) {
		name = event.point.name;
	} else if (event.point.category) {
		name = event.point.category.toString();
	}

	ApplyDrilldownDrillIn && ApplyDrilldownDrillIn(name, false);
};
