import { Button, FormGroup, Modal, TreeViewDataItem } from '@patternfly/react-core';
import React, { useState } from 'react';
import SearchableTreeViewSelect from '../../../components/form/Select/SearchableTreeViewSelect';
import { BuildTreeViewItem } from '../../../helpers/tree-view.helper';
import { useMount } from 'react-use';
import { TDimension } from '../../../api/analytics/Dimension';
import { TDimensionAttribute } from '../../../api/analytics/DimensionAttribute';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { useApplication } from '../../../components/user/ApplicationProvider';
import _ from 'lodash';

type Props = {
	addDashboardFilter: (selectedDimId: number, entityType: string) => void;
	setModalOpen: (value: boolean) => void;
	isOpen?: boolean;
	selectedIds?: number[];
};

const AddFilterModal = (props: Props) => {
	const { addDashboardFilter, setModalOpen, isOpen, selectedIds } = props;
	const [createDisabled, setCreateDisabled] = useState<boolean>(true);
	const [allSeries, setAllSeries] = useState<TDimension[]>([]);
	const [selectedDimId, setSelectedDimId] = useState<number>();
	const [selectedType, setSelectedType] = useState<string>();
	const { dimensions } = useApplication();

	const emptyTree = [
		BuildTreeViewItem({ id: -1, name: 'No Dimensions or Attributes were found' }, []),
	];

	useMount(() => {
		const dimsList: TDimension[] = [];
		dimensions.map((dim: TDimension) => {
			const clonedDim = _.cloneDeep(dim);
			const newDimAttribute: TDimensionAttribute = {
				code: clonedDim.code,
				creator: clonedDim.creator,
				created_at: clonedDim.created_at,
				name: clonedDim.name,
				id: clonedDim.id,
				show_on_summary: clonedDim.show_on_summary,
				type: OptionsBuilderItemTypes.Dimension,
				dimension: clonedDim,
				disabled: false,
				display_attribute: true,
				effective_dated: false,
				sequence: 0,
			};

			clonedDim.dimensionAttributes.forEach((dimAttr) => {
				dimAttr.type = OptionsBuilderItemTypes.DimensionAttribute;
			});

			clonedDim.dimensionAttributes.push(newDimAttribute);
			dimsList.push(clonedDim);
		});
		setAllSeries(dimsList);
	});

	const onSelect = (_event: React.MouseEvent<Element, MouseEvent>, item: TreeViewDataItem) => {
		if (item.id === '-1') return;
		if (item.id?.includes('__')) {
			const [ids, type] = item.id.split('-');
			const [dimensionId, attributeId] = ids.split('__');

			const dimension = allSeries.find((_) => _.id.toString() === dimensionId);

			if (dimension) {
				if (type === OptionsBuilderItemTypes.Dimension) {
					setSelectedDimId(dimension.id);
				} else if (type === OptionsBuilderItemTypes.DimensionAttribute) {
					const attr = dimension.dimensionAttributes.find(
						(_) => _.id.toString() === attributeId
					);
					setSelectedDimId(attr?.id);
				}

				setSelectedType(type);
				setCreateDisabled(false);
			}
		}
	};

	const beforeClose = () => {
		setModalOpen(false);
	};

	const beforeCreate = () => {
		if (selectedDimId && selectedType) {
			addDashboardFilter(selectedDimId, selectedType);
			setModalOpen(false);
		}
	};

	return (
		<>
			<Modal
				data-testid="add-filter-modal"
				isOpen={isOpen}
				onClose={beforeClose}
				variant="small"
				title="Add Filter"
				actions={[
					<Button
						data-testid="create-button"
						key="create"
						variant="primary"
						onClick={beforeCreate}
						isDisabled={createDisabled}
					>
						Add
					</Button>,
					<Button
						data-testid="cancel-button"
						key="cancel"
						variant="link"
						onClick={beforeClose}
					>
						Cancel
					</Button>,
				]}
			>
				<FormGroup label="Series">
					<div className="form-group-col">
						<SearchableTreeViewSelect
							className="selectSeries no-innerscroll"
							data={
								allSeries.length > 0
									? allSeries
											.filter(
												(d) =>
													!selectedIds ||
													!selectedIds.find((s) => s === d.id)
											)
											.map((s) =>
												BuildTreeViewItem(
													s,
													s.dimensionAttributes.filter(
														(d) =>
															!selectedIds ||
															!selectedIds.find((s) => s === d.id)
													)
												)
											)
									: emptyTree
							}
							placeHolderText={'Select a Series or Series Attribute'}
							onSelect={onSelect}
							treeItemsExpanded={true}
						/>
					</div>
				</FormGroup>
			</Modal>
		</>
	);
};

export default AddFilterModal;
