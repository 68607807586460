import axiosConfig from '../axiosConfig';

export type TZoneSetting = {
	id: number;
	zone_setting_enum_id: number;
	value: string;
	name: string;
	description: string;
	enabled: boolean;
};

export type TNewZoneSetting = Omit<TZoneSetting, 'id'>;

export enum ZoneSettingEnum {
	Colors = 1,
	Saml2_Enabled = 2,
	Saml2_IDP_Federation_Metadata = 3,
	Saml2_Uid_Attribute = 4,
	Saml2_Logout_Destination = 5,
	discoverQuickFilters = 6,
	showMaintenancePage = 7,
	replyEmailAddress = 8,
	iframeIntegration = 9,
	applicationWindowTitle = 10,
	supportLink = 11,
	isbuilderzone = 12,
}

export class ZoneSetting implements TZoneSetting {
	id: number;
	zone_setting_enum_id: number;
	value: string;
	name: string;
	description: string;
	enabled: boolean;

	constructor(zoneSetting: TZoneSetting) {
		this.id = zoneSetting.id;
		this.zone_setting_enum_id = zoneSetting.zone_setting_enum_id;
		this.value = zoneSetting.value;
		this.name = zoneSetting.name;
		this.description = zoneSetting.description;
		this.enabled = zoneSetting.enabled;
	}

	public static DefaultNewZoneSetting(): TNewZoneSetting {
		return {
			name: '',
			description: '',
			enabled: false,
			zone_setting_enum_id: 0,
			value: '',
		};
	}

	public static GetAll(): Promise<TZoneSetting[]> {
		return axiosConfig.instance.get<TZoneSetting[]>('zone-settings').then((res) => {
			return res.data?.length > 0 ? res.data : [];
		});
	}

	public static Get(zone_setting: ZoneSettingEnum): Promise<TZoneSetting> {
		return axiosConfig.instance
			.get<TZoneSetting>(`zone-setting/${zone_setting}`)
			.then((res) => {
				return res.data;
			});
	}

	public static Update(zoneSetting: TZoneSetting): Promise<TZoneSetting> {
		return axiosConfig.instance.patch('zone-setting', zoneSetting).then((res) => {
			return new ZoneSetting(res.data as TZoneSetting);
		});
	}
}
