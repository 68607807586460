import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../layout/Layout';
import { useMount } from 'react-use';
import ZiTable, { Action, Column } from '../components/table/ZiTable';
import FilterTableLayout from '../layout/FilterTableLayout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Modal,
	ModalBoxFooter,
	ModalVariant,
} from '@patternfly/react-core';
import PermissionButton from '../components/button/PermissionButton';
import SchnurForm, { Field, ISelectOption, UIType } from '../components/form/SchnurForm/SchnurForm';
import { Subscription, TSubscription } from '../api/subscriptions/Subscription';
import Loader from '../components/util/Loader';
import { Permission } from '../enums/permission.enum';

export default function SubscriptionListing(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableData, setTableData] = useState<TSubscription[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [activeSubscription, setActiveSubscription] = useState<TSubscription>(
		Subscription.Default() as TSubscription
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { userId } = useParams();
	const navigate = useNavigate();

	const selectedColumns: Column<TSubscription>[] = [
		{
			title: 'Subscription Name',
			columnName: 'name',
		},
		{
			title: 'Status',
			columnName: 'status',
		},
		{
			title: 'Version',
			columnName: 'version',
		},
	];

	const actions: Action<TSubscription>[] = [
		{
			name: (
				<>
					Edit Subscription <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			permission: Permission.EditSubscription,
			callback: (item) => {
				setIsModalOpen(true);
				setActiveSubscription(item);
			},
		},
		{
			name: (
				<>
					Edit Model & Content <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			permission: Permission.EditSubscription,
			callback: (item) => {
				navigate(`/setup/subscription/${item.id}/edit`);
			},
		},
		{
			name: 'Delete',
			callback: (item) => {
				setActiveSubscription(item);
				setIsDeleteModalOpen(true);
			},
		},
		{
			name: (
				<>
					Inventory View <FontAwesomeIcon icon={faFileExport} />
				</>
			),
			callback: (item) => {
				return;
			},
		},
	];

	const StatusOptions = [
		{ value: 'Active', key: 'active' },
		{ value: 'Revoked', key: 'revoked' },
	];

	const formProperties: Field<TSubscription>[] = [
		{
			title: 'Subscription Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
			},
			required: true,
		},
		{
			title: 'Description',
			columnName: 'description',
			uiSchema: {
				type: UIType.TEXTAREA,
			},
			required: true,
		},
		{
			title: 'Status',
			columnName: 'status',
			uiSchema: {
				type: UIType.SELECT,
				options: StatusOptions,
				onSelect: (selected: ISelectOption) => {
					return selected.key;
				},
				//initialSelection: activeDimension.type !== '' ? activeDimension.type : null,
			},
			required: true,
		},
	];

	useMount(() => {
		setIsLoading(true);
		const userIdParsed = userId ? +userId : undefined;
		getAllSubscriptions();
	});

	const getAllSubscriptions = () => {
		void Subscription.GetAll()
			.then((response) => {
				setTableData(response);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Subscriptions"
					pageDescription="Manage Subscriptions."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	const addButton = (
		<PermissionButton
			permission={undefined}
			data-testid={'subscription-create'}
			variant={'primary'}
			onClick={() => {
				setIsModalOpen(true);
				setActiveSubscription(Subscription.Default() as TSubscription);
			}}
		>
			New Subscription
		</PermissionButton>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleDeleteModalClose = () => {
		setIsDeleteModalOpen(false);
	};

	const handleDelete = () => {
		setTableLoading(true);
		Subscription.Delete(activeSubscription.id)
			.then(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				setTableData((prev) => prev.filter((user) => user.id !== activeSubscription.id));
				addToast('Subscription deleted successfully.', AlertVariant.success);
			})
			.catch(() => {
				handleDeleteModalClose();
				setTableLoading(false);
				addToast(
					'An error occurred while trying to delete Subscription. Please try again later.',
					AlertVariant.danger
				);
			})
			.finally(() => {
				setActiveSubscription(Subscription.Default() as TSubscription);
			});
	};

	const handleSuccess = (_value: TSubscription) => {
		const userIdParsed = userId ? +userId : 0;

		getAllSubscriptions();
		setIsModalOpen(false);
	};

	const subsTable = (
		<ZiTable<TSubscription>
			ariaLabel={'Subscriptions'}
			columns={selectedColumns}
			data={tableData}
			caption={`Subscriptions`}
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="Subscriptions"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TSubscription>
					title={'Subscriptions'}
					fields={formProperties}
					initialSubject={activeSubscription}
					isLoading={isFormLoading}
					onSubmit={(sub) => {
						setIsFormLoading(true);

						sub.version = 1;

						if (sub.id) {
							Subscription.Update(sub)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the Subscription. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							Subscription.New(sub)
								.then((newSub) => {
									handleSuccess(newSub);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the Subscription. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>

			<Modal
				title="Subscription Delete Confirmation"
				variant="small"
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				className="delete-modal"
			>
				<hr />
				<br />
				<div className="text-center">
					<h3>Are you sure you want to delete {activeSubscription.name}?</h3>
				</div>
				<br />
				<hr />
				<ModalBoxFooter className="pull-right add-question-footer">
					<Button
						variant="secondary"
						onClick={handleDeleteModalClose}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleDelete}
					>
						Delete
					</Button>
				</ModalBoxFooter>
			</Modal>

			<React.Fragment>
				{isLoading ? (
					<Card>
						<CardBody>
							<Loader />
						</CardBody>
					</Card>
				) : (
					<FilterTableLayout
						table={subsTable}
						layoutActions={[addButton]}
					/>
				)}
			</React.Fragment>
		</React.Fragment>
	);
}
