import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef } from 'react';
import {
	formatCsvColumn,
	formatCsvDataToColumns,
	MultipartResponse,
} from '../../helpers/multipart-response.helper';
import { DataframeDataRetrievalResponse, TDataframeOrder, TDateRange } from '../../api/types';
import {
	ISortBy,
	TableComposable,
	Tbody,
	Td,
	Th,
	Thead,
	ThProps,
	Tr,
} from '@patternfly/react-table';
import { TNewDataframeOrder } from '../../api/dataframes/Dataframes';
import Loader from '../util/Loader';
import { Bullseye, EmptyState, EmptyStateVariant, Title } from '@patternfly/react-core';
import { TUnitType } from '../../api/analytics/UnitType';
import { IIndexable } from '../../types/general';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { DateRange } from '../../api/date-period-selector/DateRange';
import { useApplication } from '../../../src/components/user/ApplicationProvider';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import './Preview.scss';
import { numberFormatter } from '../../helpers/number-formatter';
import { containerDimensions } from '../../helpers/container-dimensions.helper';
import _ from 'lodash';
import { DatePeriodFormatter } from '../date-period-selector/DatePeriodFormatter';
import { faPlusSquare, faMinusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportConditionalFormattingRuleEnum } from '../../enums/report-condition-formatting-rule';
import { Link } from 'react-router-dom';
import { ConditionalRule } from '../../types/conditional-rules/conditional-rules';
import { ChartTypesEnum } from '../../enums/chart-types.enum';
import * as ReactDOMServer from 'react-dom/server';

export type IPreviewProps = {
	data?: MultipartResponse<DataframeDataRetrievalResponse>;
	facts?: DraggableMenuItemData[];
	rows?: DraggableMenuItemData[];
	drillRows?: DraggableMenuItemData[];
	drillFilters?: DraggableMenuItemData[];
	hasDateDrill?: boolean;
	columns?: DraggableMenuItemData[];
	unitTypes?: TUnitType[];
	order: (TDataframeOrder | TNewDataframeOrder)[];
	setOrder?: Dispatch<SetStateAction<(TDataframeOrder | TNewDataframeOrder)[]>>;
	isLoading: boolean;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setSelectedUnitType?: Dispatch<SetStateAction<TUnitType | undefined>>;
	ApplyDrilldownDrillIn?: (name: string, isKMF: boolean, column?: string) => void;
	fromWidget?: boolean;
	tableWidgetLink?: string;
	title?: string;
	conditionalRules?: ConditionalRule[];
	chartType?: string;
	chartLoaded?: boolean;
};

const Preview = (props: IPreviewProps): ReactElement => {
	const [columns, setColumns] = React.useState<string[][]>([]);
	const [formattedData, setFormattedData] = React.useState<IIndexable[]>([]);
	const [unformattedFactValues, setUnformattedFactValues] = React.useState<IIndexable[]>([]);
	const [sortBy, setSortBy] = React.useState<ISortBy[]>([]);
	const { currentDatePeriods } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);

	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	const periodId =
		hasPreviouslySelectedPeriod && previouslySelectedStartDate
			? previouslySelectedStartDate.period
			: defaultDatePeriod?.period ?? 0;
	const containerRef = useRef<HTMLDivElement>(null);
	const containerDims = containerDimensions(containerRef);
	const headerRef = useRef<HTMLDivElement>(null);
	const headerDims = containerDimensions(headerRef);

	useEffect(() => {
		const handler = setTimeout(() => {
			if (props.data) {
				if (props.data.json) {
					if (props.data.csvData) {
						props.setIsLoading(true);
						let formattedData: IIndexable[] = formatCsvDataToColumns(
							props.data.json.columns,
							props.data.csvData,
							periodId
						);
						const valuesBeforeFormatting: IIndexable[] = [];

						if (formattedData) {
							const seenValues: string[] = [];
							const hasDrillableItems = props.rows?.some(
								(row) => row.data?.drillable
							);
							formattedData = formattedData.map(
								(item: IIndexable, index: number): IIndexable => {
									const keys: string[] = Object.keys(item);
									const isSummaryRow = keys.some(
										(key) =>
											item[key] && (item[key] as string).includes('Summary')
									);
									const isGrandTotalRow = keys.some(
										(key) =>
											item[key] &&
											(item[key] as string).includes('Grand Total')
									);

									keys.map((key) => {
										const isPercentageOfTotalCol =
											key.includes('PercentofTotal');

										const fact =
											props.chartType === ChartTypesEnum.Columnrange &&
											props.facts
												? props.facts[0]
												: props.facts?.find((fact) => {
														const keyToUse = isPercentageOfTotalCol
															? key
																	.replace('PercentofTotal', '')
																	.toLowerCase()
																	.replace(/\s/g, '')
															: key.toLowerCase().replace(/\s/g, '');
														const isAlias =
															keyToUse ===
															fact.data?.alias
																?.toLowerCase()
																.replace(/\s/g, '');
														const isTitle =
															keyToUse ===
															fact.data?.title
																.toLowerCase()
																.replace(/\s/g, '');
														return isAlias || isTitle;
												  });

										if (fact) {
											const unitType: TUnitType | undefined = props.unitTypes
												? props.unitTypes?.find(
														(type: TUnitType) =>
															type.id == fact?.data?.unitType
												  )
												: undefined;
											if (item[key] && item[key] != '') {
												if (!isNaN(item[key] as number)) {
													valuesBeforeFormatting.push({
														factId: fact.data?.id ?? 0,
														index: index,
														value: item[key] as number,
													});
													if (isPercentageOfTotalCol) {
														item[key] = `${numberFormatter(
															item[key] as number,
															fact?.data?.numDecimals
														)}%`;
													} else if (unitType) {
														if (
															unitType.name.toLowerCase() ===
															'percentage'
														) {
															item[key] = numberFormatter(
																parseFloat(item[key] as string) *
																	100,
																fact.data?.numDecimals
															);
														} else {
															item[key] = numberFormatter(
																parseFloat(item[key] as string),
																fact.data?.numDecimals
															);
														}

														item[key] = unitType.prefix
															? unitType.prefix +
															  (item[key] as string)
															: unitType.suffix
															? (item[key] as string) +
															  unitType.suffix
															: (item[key] as string);
													}
												}
											} else {
												if (fact.data?.defaultWhenNull) {
													item[key] = fact.data?.defaultWhenNull;
												}
											}
										} else {
											if (!isSummaryRow && !isGrandTotalRow) {
												const isBreakGroup = props.rows?.find(
													(row) =>
														row.data?.title.replace(/\s/g, '') === key
												)?.data?.totaled;
												const drillEnabled = props.rows?.find(
													(row) =>
														row.data?.title.replace(/\s/g, '') === key
												)?.data?.drillable;

												if (
													isBreakGroup ||
													(hasDrillableItems && drillEnabled)
												) {
													if (seenValues.includes(item[key] as string)) {
														item[key] = '';
													} else {
														seenValues.push(item[key] as string);
													}
												}
											} else {
												seenValues.length = 0;
											}
										}

										if (
											(isSummaryRow || isGrandTotalRow) &&
											item[key] &&
											item[key] != ''
										) {
											item[key] = <b>{item[key]}</b>;
										}
									});

									return item;
								}
							);

							// If there are pivots then format the date range pivot
							// All other pivots are not numeric so we only need to cater for the date range item
							const colsToUse = props.data.json.columns.map(
								(colList, index, allColumns) => {
									if (index < allColumns.length - 1) {
										return colList.map((col) =>
											isNaN(+col)
												? col
												: DatePeriodFormatter(col, periodId, true)
										);
									}
									return colList;
								}
							);

							if (props.order.length && props.order.length === colsToUse.length) {
								const newSortBy = props.order.map((order, index) => {
									return {
										index: getSortIndex(
											props.facts ?? [],
											props.rows ?? [],
											props.columns ?? [],
											order,
											index,
											colsToUse
										),
										direction: order.direction.toLowerCase() as
											| 'asc'
											| 'desc'
											| undefined,
										defaultDirection: 'asc',
									};
								});

								// Check if the order objects are not equal so that the data retrieval is not continuously triggered
								if (!_.isEqual(sortBy, newSortBy)) {
									setSortBy(newSortBy as ISortBy[]);
								}
							} else {
								setSortBy(
									colsToUse.map(() => {
										return {
											index: 0,
											direction: 'asc',
											defaultDirection: 'asc',
										};
									})
								);
							}

							setUnformattedFactValues(valuesBeforeFormatting);
							setFormattedData(formattedData);
							setColumns(colsToUse);
							props.setIsLoading(false);
						}
					} else {
						setFormattedData([]);
					}
				}
			}
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [props.data, props.setSelectedUnitType, props.chartType, props.conditionalRules]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setOrderObject();
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [sortBy]);

	const getConditionalFormattingStyles = (factId: number, index: number) => {
		const factValue = unformattedFactValues.find(
			(val) => val.factId === factId && val.index === index
		);
		const rules = props.conditionalRules?.filter((rule) => rule.key_measure_fact_id === factId);
		let styles: IIndexable | null = null;
		let htmlToUse: string | null = null;

		if (factValue) {
			rules?.forEach((rule) => {
				switch (rule.rule) {
					case ReportConditionalFormattingRuleEnum.BETWEEN:
						if (rule.value2) {
							if (+factValue.value > rule.value && +factValue.value < rule.value2) {
								styles = convertStyleStringToObject(rule.editorHTML);
								htmlToUse = rule.editorHTML;
							}
						}
						break;
					case ReportConditionalFormattingRuleEnum.EQUAL_TO:
						if (+factValue.value === rule.value) {
							styles = convertStyleStringToObject(rule.editorHTML);
							htmlToUse = rule.editorHTML;
						}
						break;
					case ReportConditionalFormattingRuleEnum.GREATER_THAN:
						if (+factValue.value > rule.value) {
							styles = convertStyleStringToObject(rule.editorHTML);
							htmlToUse = rule.editorHTML;
						}
						break;
					case ReportConditionalFormattingRuleEnum.GREATER_THAN_OR_EQUAL_TO:
						if (+factValue.value >= rule.value) {
							styles = convertStyleStringToObject(rule.editorHTML);
							htmlToUse = rule.editorHTML;
						}
						break;
					case ReportConditionalFormattingRuleEnum.LESS_THAN:
						if (+factValue.value < rule.value) {
							styles = convertStyleStringToObject(rule.editorHTML);
							htmlToUse = rule.editorHTML;
						}
						break;
					case ReportConditionalFormattingRuleEnum.LESS_THAN_OR_EQUAL_TO:
						if (+factValue.value <= rule.value) {
							styles = convertStyleStringToObject(rule.editorHTML);
							htmlToUse = rule.editorHTML;
						}
						break;
					case ReportConditionalFormattingRuleEnum.NOT_EQUAL_TO:
						if (+factValue.value != rule.value) {
							styles = convertStyleStringToObject(rule.editorHTML);
							htmlToUse = rule.editorHTML;
						}
						break;
				}
			});
		}

		return {
			styles,
			htmlToUse,
		};
	};

	const convertStyleStringToObject = (html: string): IIndexable | null => {
		const regex = /style="([^"]*)"/;
		const match = html.match(regex);

		if (match) {
			const styleValue = match[1];
			return styleValue.split(';').reduce((acc, style) => {
				if (style.trim()) {
					const [key, value] = style.split(':');
					const camelCaseKey = key
						.trim()
						.replace(/-([a-z])/g, (_, char) => (char as string).toUpperCase());
					acc[camelCaseKey] = value.trim();
				}
				return acc;
			}, {} as Record<string, string>);
		}

		return null;
	};

	const getSortIndex = (
		facts: DraggableMenuItemData[],
		rows: DraggableMenuItemData[],
		columns: DraggableMenuItemData[],
		order: TDataframeOrder | TNewDataframeOrder,
		level: number,
		colArray: string[][]
	) => {
		if (
			columns.find(
				(col) => col.data?.id === order.entity_id && col.entityType === order.entity_type
			)
		) {
			return 0;
		}

		const colsByLevel = colArray[level];

		let item = rows.find(
			(row) => row.data?.id === order.entity_id && row.entityType === order.entity_type
		);

		if (!item) {
			item = facts.find(
				(fact) => fact.data?.id === order.entity_id && fact.entityType === order.entity_type
			);

			// If pivots exist and the sorted column is a fact then make use of the pivotSortIndex property to ensure correct sorted column
			if (item && colArray.length > 1) {
				return order.pivotSortIndex;
			}
		}

		if (item) {
			return colsByLevel.indexOf(item.data?.title ?? '');
		}

		return 0;
	};

	const getSortParams = (columnIndex: number, level: number): ThProps['sort'] => {
		return {
			sortBy: sortBy[level],
			onSort: (
				event: React.MouseEvent,
				index: number,
				direction: 'asc' | 'desc' | undefined
			) => {
				const newSortBy = [...sortBy];
				const existingSort = newSortBy[level];
				existingSort.index = index;
				existingSort.direction = direction;
				setSortBy(newSortBy);
			},
			columnIndex,
		};
	};

	const setOrderObject = () => {
		if (props.setOrder) {
			const order: TNewDataframeOrder[] = [];

			sortBy.forEach((sort, index) => {
				let pivotSort = '';
				let item = props.facts?.find((fact) => {
					return (
						columns.length > 0 && fact.data?.title === columns[index][sort.index ?? 0]
					);
				});

				// If pivots exist and sorted column is a fact then build up this piped string for the BE to utilize
				if (item && columns.length > 1) {
					for (let x = columns.length - 2; x >= 0; x--) {
						for (let i = sort.index ?? 0; i > 0; i--) {
							const currentCol = columns[x][i];
							if (currentCol !== '') {
								pivotSort = pivotSort ? `${currentCol}|${pivotSort}` : currentCol;
								break;
							}
						}
					}
				}

				if (!item) {
					item = props.rows?.find((row) => {
						return (
							columns.length > 0 &&
							row.data?.title === columns[index][sort.index ?? 0]
						);
					});
				}

				if (!item) {
					item = props.columns?.find((col) => {
						return (
							columns.length > 0 &&
							col.data?.title === columns[index][sort.index ?? 0]
						);
					});
				}

				if (!item) {
					item = props.rows?.find((row) => {
						return row.entityType == OptionsBuilderItemTypes.DateSeries;
					});
				}

				if (item) {
					order.push({
						direction: sort.direction?.toUpperCase() ?? '',
						entity_id: item.data?.id ? +item.data.id : 0,
						entity_type: item.entityType,
						useSequence: item.data?.useSequence ?? false,
						pivotSort,
						pivotSortIndex: pivotSort != '' ? sort.index : undefined,
					});
				}
			});

			if (
				order.length &&
				!_.isEqual(
					order,
					props.order.map((o) => {
						return {
							direction: o.direction,
							entity_id: o.entity_id,
							entity_type: o.entity_type,
							useSequence: o.useSequence,
							pivotSort: o.pivotSort,
							pivotSortIndex: o.pivotSort != '' ? o.pivotSortIndex : undefined,
						};
					})
				)
			) {
				props.setOrder(order);
			}
		}
	};

	const styleValue =
		props.chartLoaded !== undefined
			? props.chartLoaded == true
				? { display: 'block' }
				: { display: 'none' }
			: { display: 'block' };

	const noData = (
		<Tr style={styleValue}>
			<Td colSpan={100}>
				<Bullseye>
					<EmptyState variant={EmptyStateVariant.small}>
						<Title
							headingLevel="h2"
							size="lg"
						>
							No results found
						</Title>
					</EmptyState>
				</Bullseye>
			</Td>
		</Tr>
	);

	const getColumns = (currentRowColumns: string[], level: number, showSort = true) => {
		const isPivotItems = currentRowColumns.some((row) =>
			props.columns?.some((col) => col.data?.title === row)
		);
		const columns: {
			col: string;
			colSpan: number;
			sortParams: ThProps['sort'];
			isPercentageCol: boolean;
		}[] = [];
		currentRowColumns.map((col, index) => {
			const sortParams = getSortParams(index, level);
			if (!_.isEmpty(col)) {
				const isPercentageCol = col.replace(/\s+/g, '').includes('PercentofTotal');
				const thisColumn = {
					col: col,
					colSpan: 1,
					sortParams: sortParams,
					isPercentageCol,
				};

				// If has pivots
				if (index !== 0 && !showSort) {
					thisColumn.sortParams = undefined;
				}
				columns.push(thisColumn);
			} else {
				columns[columns.length - 1].colSpan = columns[columns.length - 1].colSpan + 1;
			}
		});

		return (
			<Tr>
				{columns.map((col, index) => {
					const isLastRow = props.rows && index >= props.rows?.length - 1;
					const isPivotHeader = isPivotItems && index === 0;

					let isNumeric = false;

					if (props.data && props.data.json && props.data.csvData) {
						const formattedData: IIndexable[] = formatCsvDataToColumns(
							props.data?.json?.columns,
							props.data?.csvData,
							periodId
						);

						isNumeric = !!formattedData.find((data) => {
							// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
							const value = data[col.col.replaceAll(/\s/g, '')];
							return value
								? !isNaN(
										// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
										value.replace(/[^a-zA-Z0-9]/g, '')
								  )
								: false;
						});
					}

					return (
						<Th
							className={`${col.col.replaceAll(/\s/g, '')} ${
								isPivotHeader ? 'pivot-header' : ''
							}`}
							colSpan={col.colSpan}
							sort={col.isPercentageCol ? undefined : col.sortParams}
							textCenter={isNumeric}
							hasLeftBorder={isLastRow && col.colSpan > 1}
							hasRightBorder={isLastRow && col.colSpan > 1}
						>
							{col.col}
						</Th>
					);
				})}
			</Tr>
		);
	};

	const getDrillIcon = (col: string) => {
		if (props.drillRows && props.drillFilters) {
			const drillRow = props.drillRows.find(
				(row) => row.data?.title.replace(/\s/g, '') === col
			);
			if (drillRow) {
				const exists = props.drillFilters.find(
					(filter) => filter.data?.title.replace(/\s/g, '') === col
				);

				if (drillRow.entityType === OptionsBuilderItemTypes.DateSeries) {
					return (
						<>
							<FontAwesomeIcon
								icon={props.hasDateDrill ? faMinusSquare : faPlusSquare}
								size="1x"
							/>
							&nbsp;
						</>
					);
				} else {
					return (
						<>
							<FontAwesomeIcon
								icon={exists ? faMinusSquare : faPlusSquare}
								size="1x"
							/>
							&nbsp;
						</>
					);
				}
			}
		}
	};

	const getCells = (row: IIndexable, index: number) => {
		const colsToUse: string[] =
			props.rows?.map((row) => row.data?.title.replaceAll(/\s/g, '') ?? '') ?? [];

		if (props.columns && props.columns.length) {
			columns.forEach((cols) => {
				const isPivotColumns = cols.some((row) =>
					props.columns?.some((col) => col.data?.title === row)
				);

				if (isPivotColumns) {
					cols.forEach((pivotCol) => {
						const isPivot = props.columns?.some((col) => col.data?.title === pivotCol);
						if (!isPivot && !_.isEmpty(pivotCol)) {
							props.facts?.forEach((fact) => {
								if (fact.data) {
									colsToUse.push(fact.data?.title.replaceAll(/\s/g, ''));
								}
							});
						}
					});
				}
			});
		} else {
			props.facts?.forEach((fact) => {
				if (fact.data) {
					colsToUse.push(fact.data?.title.replaceAll(/\s/g, ''));
				}
			});
		}

		if (colsToUse.length) {
			return colsToUse.map((col, i) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const tableCellValue = row[Object.keys(row)[i]];
				let formatting: IIndexable | null = null;

				const updatedTableCellValue =
					typeof tableCellValue === 'string' &&
					tableCellValue != null &&
					tableCellValue?.replace(/[^a-zA-Z0-9\s]/g, '');

				const isNumeric =
					tableCellValue != null &&
					!isNaN(updatedTableCellValue ? +updatedTableCellValue : 0);

				if (props.conditionalRules && props.conditionalRules.length) {
					const fact = props.facts?.find(
						(factToFind) => factToFind.data?.title.replace(/\s/g, '') === col
					);

					if (fact) {
						formatting = getConditionalFormattingStyles(fact.data?.id ?? 0, index);
					}
				}

				return (
					<Td
						dataLabel={col}
						style={(formatting?.styles as IIndexable | null) ?? undefined}
						textCenter={isNumeric}
						className={`${props.ApplyDrilldownDrillIn ? 'cursor' : ''}`}
						onClick={() => {
							applyTableDrilldown(
								row[formatCsvColumn(col)] as string,
								props.facts &&
									props.facts.some(
										(fact) => fact.data?.title.replace(/\s/g, '') == col
									),
								col
							);
						}}
					>
						{!_.isEmpty(row[Object.keys(row)[i]]) && (
							<>
								{getDrillIcon(col)}
								{(formatting?.htmlToUse as string | null) ? (
									<span
										dangerouslySetInnerHTML={{
											// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
											__html: formatting?.htmlToUse.replace(
												'{0}',
												ReactDOMServer.renderToString(
													// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
													row[Object.keys(row)[i]]
												)
											),
										}}
									/>
								) : (
									row[Object.keys(row)[i]]
								)}
							</>
						)}
					</Td>
				);
			});
		} else {
			return <></>;
		}
	};

	const applyTableDrilldown = (filterValue: string, isKMF = false, col?: string) => {
		props.ApplyDrilldownDrillIn && props.ApplyDrilldownDrillIn(filterValue, isKMF, col);
	};

	return (
		<div
			className={`preview-table-container ${props.fromWidget ? 'widget-height' : ''}`}
			ref={containerRef}
		>
			{props.title && (
				<h1
					className="preview-title"
					ref={headerRef}
				>
					<Link
						className="table-hyperlink"
						to={props.tableWidgetLink ? props.tableWidgetLink : ''}
					>
						{props.title}
					</Link>
				</h1>
			)}
			<div
				className="scroll-container"
				style={{
					maxWidth: containerDims?.width,
					...(props.fromWidget && {
						maxHeight: containerDims
							? containerDims?.height - (headerDims?.height ?? 0)
							: undefined,
					}),
				}}
			>
				{props.isLoading ? (
					<Loader />
				) : (
					<TableComposable>
						<Thead className="sticky-header">
							{columns.map((col, index) =>
								getColumns(col, index, index === columns.length - 1)
							)}
						</Thead>
						<Tbody>
							{formattedData.length > 0
								? formattedData.map((row, rowIndex) => {
										return <Tr key={rowIndex}>{getCells(row, rowIndex)}</Tr>;
								  })
								: noData}
						</Tbody>
					</TableComposable>
				)}
			</div>
		</div>
	);
};

export default Preview;
