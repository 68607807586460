import React, { useEffect, useState } from 'react';
import { AlertVariant } from '@patternfly/react-core';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import Loader from '../util/Loader';
import {
	Dataframe,
	TNewDataframeFilter,
	TNewDataframeOrder,
} from '../../api/dataframes/Dataframes';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	TDataframe,
	TDataframeOrder,
	TDateRange,
	TReport,
	TReportConditionalRules,
} from '../../api/types';
import { MultipartResponse } from '../../helpers/multipart-response.helper';
import { useApplication } from '../user/ApplicationProvider';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DashboardFilter } from '../../api/dashbboards/DashboardFilter';
import { AxiosError } from 'axios';
import { useMount } from 'react-use';
import Preview, { IPreviewProps } from './Preview';
import { Report } from '../../api/reports/Reports';
import { Dimension } from '../../api/analytics/Dimension';
import {
	buildDataframeRequest,
	populateDroppedColumnsByDataframe,
	populateDroppedFactsByDataframe,
	populateDroppedFilterByDimension,
	populateDroppedFilterByDimensionAttribute,
	populateDroppedRowsByDataframe,
} from '../../hooks/DataBuilderHooks';
import { DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import { FilterOperatorEnum } from '../../enums/operators.enum';
import { DimensionAttribute } from '../../api/analytics/DimensionAttribute';
import { DatePeriodReformatter } from '../date-period-selector/DatePeriodFormatter';
import { useParams } from 'react-router-dom';

type Props = {
	reportId: string;
	selectedDate?: TNewDateRange;
	allowClickNavigate?: boolean;
	filters?: DashboardFilter[];
	delayedDisplayTime?: number;
	isEdit?: boolean;
};

const TableView = (props: Props) => {
	const { reportId, selectedDate, filters, delayedDisplayTime, allowClickNavigate, isEdit } =
		props;
	const { addToast } = useToast();
	const { measures, dimensions, unitTypes } = useApplication();
	const { dashboardId } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [report, setReport] = useState<TReport | null>(null);
	const [previewData, setPreviewData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [previewProps, setPreviewProps] = useState<IPreviewProps>();
	const [dataframe, setDataframe] = useState<TDataframe>();
	const [drillRows, setDrillRows] = useState<DraggableMenuItemData[]>([]);
	const [drillFilters, setDrillFilters] = useState<DraggableMenuItemData[]>([]);
	const [datePeriodSelectorOverride, setDatePeriodSelectorOverride] = useState<TDateRange>();
	useState<string>();
	const [order, setOrder] = useState<(TDataframeOrder | TNewDataframeOrder)[]>([]);
	const [hiddenClass, setHiddenClass] = useState<string>('hidden');
	const [conditionalFormattingRules, setConditionalFormattingRules] = useState<
		TReportConditionalRules[]
	>([]);

	useMount(() => {
		getWidgetData();
	});

	useEffect(() => {
		if (report && dataframe && previewData) {
			setIsLoading(true);
			setDatePeriodSelectorOverride(undefined);
			setDrillRows([]);
			setDrillFilters([]);
		}
	}, [selectedDate, filters]);

	useEffect(() => {
		if (report) {
			getDataframe();
		}
	}, [report]);

	useEffect(() => {
		if (dataframe) {
			setIsLoading(true);
			setHiddenClass('hidden');
			// set drill rows if needed
			const rows = populateDroppedRowsByDataframe(dataframe, dimensions);
			const drillableRows = rows.filter((row) => row.data?.drillable);
			if (drillableRows.length) {
				if (!drillRows.length && !drillFilters.length) {
					setDrillRows(drillableRows.length ? [drillableRows[0]] : []);
				}
			}
		}

		const handler = setTimeout(() => {
			getRetrievalData();
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [dataframe, order, drillRows, drillFilters]);

	useEffect(() => {
		if (dataframe) {
			const rows = populateDroppedRowsByDataframe(dataframe, dimensions);

			setPreviewProps({
				data: previewData,
				facts: populateDroppedFactsByDataframe(dataframe, measures),
				rows: [...rows.filter((row) => !row.data?.drillable), ...drillRows],
				columns: populateDroppedColumnsByDataframe(dataframe, dimensions),
				unitTypes,
				isLoading,
				setIsLoading,
				order: order,
				setOrder,
				ApplyDrilldownDrillIn: applyDrilldown,
				drillFilters,
				drillRows,
				hasDateDrill: !!datePeriodSelectorOverride,
				fromWidget: true,
				tableWidgetLink: `/dashboards/${dashboardId ?? 0}/table/view/${reportId}`,
				title: report?.name,
				conditionalRules: conditionalFormattingRules,
			});

			// Time out used to prevent the flash of the chart component before all data has initialized
			if (delayedDisplayTime) {
				setTimeout(() => {
					setHiddenClass('');
				}, delayedDisplayTime);
			} else {
				setHiddenClass('');
			}
		}
	}, [previewData]);

	const getWidgetData = () => {
		Report.Get(+reportId, ['conditionalRules'])
			.then((report) => {
				setReport(report);
				setConditionalFormattingRules(report.conditionalRules);
			})
			.catch(() => {
				addToast('Error fetching widget data.', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const getRetrievalData = () => {
		if (dataframe) {
			const facts = populateDroppedFactsByDataframe(dataframe, measures);
			const rows = populateDroppedRowsByDataframe(dataframe, dimensions);
			const columns = populateDroppedColumnsByDataframe(dataframe, dimensions);

			const overrideRequest = buildDataframeRequest(
				facts,
				[...rows.filter((row) => !row.data?.drillable), ...drillRows],
				columns,
				drillFilters,
				'override',
				order,
				false,
				true,
				dataframe
			);

			overrideRequest.filters = [
				...mapFilters(filters ?? [], dataframe),
				...overrideRequest.filters,
			];

			const request: DataframeDataRetrievalRequest = {
				dataframeId: dataframe.id,
				begin_date: datePeriodSelectorOverride?.begin_date ?? selectedDate?.begin_date ?? 0,
				end_date: datePeriodSelectorOverride?.end_date ?? selectedDate?.end_date ?? 0,
				periodId: datePeriodSelectorOverride?.period ?? selectedDate?.period ?? 0,
				override: overrideRequest,
			};

			Dataframe.Retrieve(request)
				.then((response) => {
					setPreviewData(response);
					setIsLoading(false);
				})
				.catch((e: AxiosError<{ message: string }>): void => {
					addToast('Error fetching data.', AlertVariant.danger);
					setIsLoading(false);
				});
		}
	};

	const getDataframe = () => {
		Dataframe.Get(report?.dataframe ?? 0, [
			'datasets',
			'filters',
			'rowEntry',
			'columnEntry',
			'order',
		])
			.then((dataframeResponse) => {
				setDataframe(dataframeResponse);
				setOrder(dataframeResponse.order ? dataframeResponse.order : []);
			})
			.catch(() => {
				setIsLoading(false);
				addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
			});
	};

	const mapFilters = (
		filters: DashboardFilter[],
		dataframe: TDataframe
	): TNewDataframeFilter[] => {
		const filtersToReturn: TNewDataframeFilter[] = [];

		filters.forEach((filter) => {
			filtersToReturn.push({
				entity_id: filter.entity_id,
				entity_type: filter.entity_type,
				excluded: filter.excluded,
				isExistingValue: filter.isExistingValue,
				operator: filter.operator,
				value: filter.value,
			});
		});

		return [...filtersToReturn, ...dataframe.filters];
	};

	const applyDrilldown = (name: string, isKMF: boolean, col?: string) => {
		if (
			!isKMF &&
			drillRows.find((row) => row.data?.title.replace(/\s/g, '') === col) &&
			dataframe
		) {
			const rows = populateDroppedRowsByDataframe(dataframe, dimensions);
			const exists = drillFilters.find(
				(filter) => filter.data?.title.replace(/\s/g, '') === col
			);

			if (exists) {
				const indexDrillRow = drillRows.findIndex(
					(item) => item.data?.title.replace(/\s/g, '') === col
				);

				const indexDrillFilter = drillFilters.findIndex(
					(item) => item.data?.title.replace(/\s/g, '') === col
				);

				setDrillRows(drillRows.slice(0, indexDrillRow + 1));
				setDrillFilters(drillFilters.slice(0, indexDrillFilter));
			} else {
				const drillItems = rows.filter((row) => row.data?.drillable);
				const currentIndex = drillItems.findIndex(
					(item) => item.data?.title.replace(/\s/g, '') === col
				);
				const drillItem = drillItems[currentIndex];

				if (drillItem.entityType === OptionsBuilderItemTypes.Dimension) {
					void Dimension.RetrievalWithType(drillItem.data?.id ?? 0).then((response) => {
						let newFilterItem: DraggableMenuItemData | null = null;
						const matchingFilterValue = response.items.find(
							(x) => x.value.toLowerCase() === name.toLowerCase()
						);

						newFilterItem = populateDroppedFilterByDimension(
							drillItem.data?.id ?? 0,
							dimensions
						);

						if (newFilterItem && newFilterItem.data) {
							newFilterItem.data.title = drillItem.data?.title ?? '';
							newFilterItem.data.drillable = false;
							newFilterItem.data.operator = FilterOperatorEnum.EQUALS;
							newFilterItem.data.value = matchingFilterValue?.id.toString();
							newFilterItem.data.isExistingValue = true;
							setDrillFilters([...drillFilters, newFilterItem]);

							if (drillItems[currentIndex + 1]) {
								setDrillRows([...drillRows, drillItems[currentIndex + 1]]);
							}
						}
					});
				} else if (drillItem.entityType === OptionsBuilderItemTypes.DimensionAttribute) {
					void DimensionAttribute.Retrieval(drillItem.data?.id ?? 0).then((response) => {
						let newFilterItem: DraggableMenuItemData | null = null;
						const matchingFilterValue = response.find(
							(x) => x.value.toLowerCase() === name.toLowerCase()
						);

						newFilterItem = populateDroppedFilterByDimensionAttribute(
							drillItem.data?.id ?? 0,
							dimensions
						);
						if (newFilterItem && newFilterItem.data) {
							newFilterItem.data.title = drillItem.data?.title ?? '';
							newFilterItem.data.drillable = false;
							newFilterItem.data.operator = FilterOperatorEnum.EQUALS;
							newFilterItem.data.value = matchingFilterValue?.id.toString();
							newFilterItem.data.isExistingValue = true;
							setDrillFilters([...drillFilters, newFilterItem]);

							if (drillItems[currentIndex + 1]) {
								setDrillRows([...drillRows, drillItems[currentIndex + 1]]);
							}
						}
					});
				} else if (drillItem.entityType === OptionsBuilderItemTypes.DateSeries) {
					if (selectedDate) {
						if (datePeriodSelectorOverride) {
							setDatePeriodSelectorOverride(undefined);
						} else {
							setDatePeriodSelectorOverride(
								DatePeriodReformatter(name, selectedDate.period)
							);
						}
					}
				}
			}
		}
	};

	const componentStyles = {
		height: '100%',
		width: '100%',
	};

	let component = <Loader />;

	if (!isLoading) {
		component = (
			<div
				style={componentStyles}
				className={hiddenClass}
			>
				{previewProps && <Preview {...previewProps} />}
			</div>
		);
	}

	return component;
};

export default TableView;
