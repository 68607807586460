import axiosConfig from '../axiosConfig';
import { TKeyMeasure } from './KeyMeasure';
import { TDimensionAttribute } from './DimensionAttribute';
import { GenericKeyValueResponse } from '../types';

export enum DimensionTypeEnum {
	INTEGER = 'Integer',
	FLOAT = 'Float',
	STRING = 'String',
	DATE = 'Date',
	DATETIME = 'Datetime',
	TIME = 'Time',
	TEXT = 'Text',
}

export const DimensionTypeOptions = [
	{ value: 'Integer', key: 'Integer' },
	{ value: 'Float', key: 'Float' },
	{ value: 'String', key: 'String' },
	{ value: 'Date/Time', key: 'Datetime' },
	{ value: 'Date', key: 'Date' },
	{ value: 'Time', key: 'Time' },
	{ value: 'Text', key: 'Text' },
];

export type TKeyMeasureDimension = {
	dimension: TDimension | number;
	keyMeasure: TKeyMeasure | number;
	sequence: TKeyMeasure | number;
	id: number;
};

export type TDimension = {
	allow_filtering: boolean; // Default true
	code: string;
	creator: unknown;
	created_at: number;
	description: string | null;
	dimensionAttributes: TDimensionAttribute[];
	display_name: string | null;
	groups: unknown[];
	id: number;
	is_unique: boolean; // Default false
	keyMeasures: TKeyMeasure[];
	name: string;
	show_on_summary: boolean; // Default false
	type: string;
	updated_at: number;
	updater: unknown;
	userDimensions: unknown[];
	isDimension?: boolean;
	uuid?: string;
};

export type TNewDimension = Omit<
	TDimension,
	'id' | 'creator' | 'updater' | 'updated_at' | 'created_at'
>;

export class Dimension implements TDimension {
	allow_filtering: boolean;
	code: string;
	creator: unknown;
	created_at: number;
	description: string | null;
	dimensionAttributes: TDimensionAttribute[];
	display_name: string | null;
	groups: unknown[];
	id: number;
	is_unique: boolean;
	keyMeasures: TKeyMeasure[];
	name: string;
	show_on_summary: boolean;
	type: string;
	updated_at: number;
	updater: unknown;
	userDimensions: unknown[];
	uuid?: string;

	constructor(dimension: TDimension) {
		this.allow_filtering = dimension.allow_filtering;
		this.code = dimension.code;
		this.creator = dimension.creator;
		this.created_at = dimension.created_at;
		this.description = dimension.description;
		this.dimensionAttributes = dimension.dimensionAttributes;
		this.display_name = dimension.display_name;
		this.groups = dimension.groups;
		this.id = dimension.id;
		this.is_unique = dimension.is_unique;
		this.keyMeasures = dimension.keyMeasures;
		this.name = dimension.name;
		this.show_on_summary = dimension.show_on_summary;
		this.type = dimension.type;
		this.updated_at = dimension.updated_at;
		this.updater = dimension.updater;
		this.userDimensions = dimension.userDimensions;
		this.uuid = dimension.uuid;
	}

	public static DefaultNewDimension(): TNewDimension {
		return {
			dimensionAttributes: [],
			groups: [],
			keyMeasures: [],
			userDimensions: [],
			allow_filtering: true,
			code: '',
			description: null,
			display_name: null,
			is_unique: false,
			name: '',
			show_on_summary: false,
			type: '',
		};
	}

	public static NewDimension(dimension: TNewDimension): Promise<TDimension> {
		return axiosConfig.instance.post('app/dimension', dimension).then((res) => {
			return new Dimension(res.data as TDimension);
		});
	}

	public static DeleteDimension(id: number): Promise<boolean> {
		return axiosConfig.instance.delete(`app/dimension/${id}`).then(() => {
			return true;
		});
	}

	public static Update(dimension: TDimension): Promise<TDimension> {
		return axiosConfig.instance.patch('app/dimension', dimension).then((res) => {
			return new Dimension(res.data as TDimension);
		});
	}

	public static GetAll(expand: (keyof TDimension)[] = []): Promise<TDimension[]> {
		return axiosConfig.instance
			.get('app/dimensions', { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TDimension[]).map((dimension: TDimension) => {
					return new Dimension(dimension);
				});
			});
	}

	public static Get(id: number, expand: (keyof TDimension)[] = []): Promise<TDimension> {
		return axiosConfig.instance
			.get(`app/dimension/${id}`, { params: { expand: expand } })
			.then((res) => {
				return new Dimension(res.data as TDimension);
			});
	}

	public static Retrieval(id: number): Promise<GenericKeyValueResponse<string>[]> {
		return axiosConfig.instance
			.get<GenericKeyValueResponse<string>[]>(`app/data-retrieval/dimension/${id}`)
			.then((res) => {
				return res.data || [];
			});
	}
	public static RetrievalWithType(
		id: number
	): Promise<{ dimension_type: string; items: GenericKeyValueResponse<string>[] }> {
		return axiosConfig.instance
			.get<{ dimension_type: string; items: GenericKeyValueResponse<string>[] }>(
				`app/data-retrieval/dimension-with-type/${id}`
			)
			.then((res) => {
				return res.data || { dimension_type: 'TEXT', items: [] };
			});
	}
}
