import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Modal,
	ModalBoxFooter,
	ModalVariant,
	TextArea,
	Text,
	Alert,
	AlertVariant,
} from '@patternfly/react-core';
import { useState, useEffect } from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { Question, TQuestion } from '../../api/questions/Questions';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { IconDefinition, faPlus } from '@fortawesome/pro-regular-svg-icons';
import DiscoverAddQuestionCategoryModal from './DiscoverAddQuestionCategoryModal';
import { QuestionCategory, TQuestionCategory } from '../../api/question-category/QuestionCategory';
import { iconMap } from '../global-icon-map.helper';
import MultiSelectTagDropdown from './MultiSelectTagDropdown';
import { QuestionTag, TQuestionTag } from '../../api/tags/QuestionTags';
import { useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';

type Props = {
	isOpen: boolean;
	onClose: (hasAddedQuestion: boolean) => void;
	chartId: number;
	fetchedQuestion?: TQuestion;
	refetchQuestions: () => void;
};

function DiscoverAddQuestionModal(props: Props) {
	const [modalTitle, setModalTitle] = useState(`Discover - Add Question to Chart`);
	const [buttonText, _setButtonText] = useState('Submit');
	const [questionText, setQuestionText] = useState('');
	const [questionDescrip, setQuestionDescrip] = useState('');
	const [validationMessage, setValidationMessage] = useState<React.ReactElement | null>();
	const [isExpanded, setIsExpanded] = useState(false);
	const [selectedCategoryText, setSelectedCategoryText] = useState('- Select a Category -');
	const [selectedCategoryId, setSelectedCategoryId] = useState(0);
	const [isAddQuestionCatModalOpen, setIsAddQuestionCatModalOpen] = useState(false);
	const { addToast } = useToast();
	const [fetchedQuestionCategories, setFetchedQuestionCategories] =
		useState<TQuestionCategory[]>();
	const [selectedCategoryIcon, setSelectedCategoryIcon] = useState<IconDefinition>();
	const [selectedTags, setSelectedTags] = useState<number[]>([]);
	const isGranted = useIsGranted();
	const hasAddQuestionCatPerm = isGranted(Permission.CreateQuestionCategory);

	const handleToggle = () => {
		setIsExpanded(!isExpanded);
	};

	const handleTextAreaChange = (
		value: string,
		_event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setQuestionText(value);
	};

	const handleDescriptionAreaChange = (
		value: string,
		_event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setQuestionDescrip(value);
	};

	useEffect(() => {
		getAllQuestionCategories();
	}, []);

	// Update actionText when the modal is opened
	useEffect(() => {
		if (props.isOpen && !props.fetchedQuestion) {
			setModalTitle('Discover - Add Question to Chart');
			setValidationMessage(null);
			setQuestionText('');
			setQuestionDescrip('');
			setQuestionDescrip('');
			setSelectedCategoryText('- Select a Category -');
			setSelectedCategoryIcon(undefined);
			setSelectedTags([]);
			setSelectedTags([]);
		}

		if (props.isOpen && props.fetchedQuestion) {
			setModalTitle('Discover - Edit Chart Question');
			setQuestionText(props.fetchedQuestion.name);
			setQuestionDescrip(props.fetchedQuestion.description);
			setQuestionDescrip(props.fetchedQuestion.description);
			setSelectedCategoryId(props.fetchedQuestion.category!);

			void QuestionCategory.Get(
				props.fetchedQuestion.category!,
				props.fetchedQuestion.chart
			).then((response) => {
				setSelectedCategoryText(response.title);
				setSelectedCategoryIcon(iconMap[response.icon]);
			});

			if (props.fetchedQuestion.tags?.length == 0) {
				setSelectedTags([]);
			} else if (props.fetchedQuestion.tags) {
				setSelectedTags(props.fetchedQuestion.tags.map((x) => x.id!));
			}
		}
	}, [props.isOpen]);

	const closeQuestionCatModal = (hasAddedQuestionCat = false) => {
		setIsAddQuestionCatModalOpen(false);

		if (hasAddedQuestionCat) {
			getAllQuestionCategories();
		}
	};

	const getAllQuestionCategories = () => {
		void QuestionCategory.GetAll().then((response) => {
			setFetchedQuestionCategories(response);

			const newGeneratedDropdownItems = dropdownItems(response);
			finalDropdownItems = hasAddQuestionCatPerm
				? [addNewItem, ...newGeneratedDropdownItems]
				: newGeneratedDropdownItems;
		});
	};

	const submitAddQuestion = () => {
		if (questionText === '') {
			setValidationMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title={`Question Text is required`}
				/>
			);

			return;
		} else if (questionDescrip === '') {
			setValidationMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title={`Question Description is required`}
				/>
			);
		} else if (questionDescrip.length > 255) {
			setValidationMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title={`Question Description may not be longer than 255 characters`}
				/>
			);
		} else {
			if (props.fetchedQuestion) {
				SaveUpdateQuestion();
			} else {
				SaveAddQuestion();
			}

			props.onClose(true);
			props.onClose(true);
		}
	};

	const SaveAddQuestion = () => {
		const newQuestionRequest: TQuestion = {
			category: selectedCategoryId,
			chart: props.chartId,
			description: questionDescrip,
			name: questionText,
			sequence: 1,
		};

		Question.New(newQuestionRequest)
			.then((response) => {
				selectedTags.map((tag) => {
					const qTagRequest: TQuestionTag = {
						question: response.id!,
						tag: tag,
					};
					void QuestionTag.New(qTagRequest);
				});

				props.refetchQuestions();
				addToast('Created Discover Question Succesfully', AlertVariant.success);
			})
			.catch(() => {
				addToast('Error Creating Discover Question', AlertVariant.danger);
			});
	};

	const SaveUpdateQuestion = () => {
		const updateQuestionRequest: TQuestion = {
			id: props.fetchedQuestion?.id,
			category: selectedCategoryId,
			chart: props.chartId,
			description: questionDescrip,
			name: questionText,
			sequence: 1,
		};

		Question.Update(updateQuestionRequest)
			.then((response) => {
				//first delete old question tags
				props.fetchedQuestion?.tags?.map((existingTag) => {
					void QuestionTag.Delete(response.id!, existingTag.id!);
				});

				//add all new question tags
				selectedTags.map((tag) => {
					const qTagRequest: TQuestionTag = {
						question: response.id!,
						tag: tag,
					};
					void QuestionTag.New(qTagRequest);
				});

				props.refetchQuestions();
				addToast('Updated Discover Question Succesfully', AlertVariant.success);
			})
			.catch(() => {
				addToast('Error Updating Discover Question', AlertVariant.danger);
			});
	};

	const closeExpandedDropdown = () => {
		setIsExpanded(false);
	};

	const dropdownItems = (passedQuestionCategories: TQuestionCategory[]): JSX.Element[] => {
		return (passedQuestionCategories ?? fetchedQuestionCategories).map((category) => (
			<li
				onClick={() => {
					setSelectedCategoryText(category.title);
					setSelectedCategoryId(category.id!);
					setSelectedCategoryIcon(iconMap[category.icon]);
					closeExpandedDropdown();
				}}
			>
				<a
					className="pf-c-dropdown__menu-item"
					href="#"
				>
					<FontAwesomeIcon icon={iconMap[category.icon]} /> {category.title}
				</a>
			</li>
		));
	};

	const addNewItem = (
		<li
			onClick={(e) => {
				closeExpandedDropdown();
				setIsAddQuestionCatModalOpen(true);
			}}
		>
			<a
				className="pf-c-dropdown__menu-item blue-text"
				href="#"
			>
				<FontAwesomeIcon
					icon={faPlus}
					className="blue-text"
				/>{' '}
				Add New
			</a>
		</li>
	);
	const generatedDropdownItems = dropdownItems(fetchedQuestionCategories ?? []);
	let finalDropdownItems = hasAddQuestionCatPerm
		? [addNewItem, ...generatedDropdownItems]
		: generatedDropdownItems;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const classes = target.classList;
			const isDropdownItemClicked =
				classes.contains('pf-c-dropdown__menu-item') ||
				classes.contains('pf-c-dropdown__toggle');
			if (!isDropdownItemClicked) {
				setIsExpanded(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div>
			<Modal
				title={modalTitle}
				variant={ModalVariant.medium}
				isOpen={props.isOpen}
				onClose={() => {
					props.onClose(false);
				}}
				className="add-question-modal"
			>
				{validationMessage && (
					<div>
						<br />
						{validationMessage}
					</div>
				)}
				<span className="spacer-sm"></span>
				<Text>Category</Text>
				<div
					className={`pf-c-dropdown add-question-dropdown ${
						isExpanded ? 'pf-m-expanded' : ''
					}`}
				>
					<button
						className="pf-c-dropdown__toggle btnQuestionCategories"
						id="dropdown-collapsed-button"
						aria-expanded={isExpanded}
						onClick={handleToggle}
						type="button"
					>
						<span className="pf-c-dropdown__toggle-text">
							{selectedCategoryIcon && (
								<FontAwesomeIcon icon={selectedCategoryIcon} />
							)}{' '}
							{selectedCategoryText}
						</span>
						<span className="pf-c-dropdown__toggle-icon">
							<FontAwesomeIcon icon={faCaretDown} />
						</span>
					</button>
					<ul
						className="pf-c-dropdown__menu"
						hidden={!isExpanded}
						aria-labelledby="dropdown-collapsed-button"
					>
						{finalDropdownItems}
					</ul>
				</div>
				<br />
				<br />
				<Text>Tags</Text>
				<MultiSelectTagDropdown
					selected={selectedTags}
					setSelected={setSelectedTags}
				/>
				<br />
				<br />
				<Text>
					Text <span className="pf-m-danger">*</span>
				</Text>
				<TextArea
					id="taQuestionText"
					rows={2}
					value={questionText}
					onChange={handleTextAreaChange}
				></TextArea>
				<br />
				<br />
				<Text>
					Description <span className="pf-m-danger">*</span>
				</Text>
				<TextArea
					id="taQuestionDescrip"
					rows={5}
					value={questionDescrip}
					onChange={handleDescriptionAreaChange}
				></TextArea>
				<br />
				<div>
					<ModalBoxFooter className="pull-right add-question-footer">
						<Button
							variant="secondary"
							onClick={() => {
								props.onClose(false);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={submitAddQuestion}
						>
							{buttonText}
						</Button>
					</ModalBoxFooter>
				</div>
			</Modal>

			<DiscoverAddQuestionCategoryModal
				isOpen={isAddQuestionCatModalOpen}
				onClose={closeQuestionCatModal}
			/>
		</div>
	);
}

export default DiscoverAddQuestionModal;
