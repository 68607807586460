import axiosConfig from '../axiosConfig';
import { TSharedEntity } from '../shared-entity/SharedEntity';
import { GenericResponse } from '../types';

export type TChart = {
	id?: number;
	chart_type: string;
	second_series_chart_type?: string;
	color_override: boolean;
	color_set: string;
	dataframe: number;
	folder: number | null;
	future_periods: number;
	hide_legend: boolean;
	hide_y_axis: boolean;
	historical_periods: number;
	lowest_period: number;
	maxium: string | null;
	minumum: string | null;
	name: string;
	num_decimals: number;
	selected_data_point: number | null;
	share_common_y_axis: boolean;
	show_average: boolean;
	show_data_values: boolean;
	show_percentages: boolean;
	show_scrollbar: boolean;
	show_targets: boolean;
	show_values: boolean;
	significant_digits?: string | null;
	target_value?: string | null;
	top_results: boolean;
	owner: number;
	period: number;
	is_from_dataframe: boolean;
	sharedChart?: number[] | TSharedEntity[];
	uuid?: string;
};

export class Chart implements TChart {
	id?: number;
	name: string;
	chart_type: string;
	second_series_chart_type?: string;
	color_override: boolean;
	color_set: string;
	dataframe: number;
	folder: number | null;
	future_periods: number;
	hide_legend: boolean;
	hide_y_axis: boolean;
	historical_periods: number;
	lowest_period: number;
	maxium: string | null;
	minumum: string | null;
	num_decimals: number;
	selected_data_point: number | null;
	share_common_y_axis: boolean;
	show_average: boolean;
	show_data_values: boolean;
	show_percentages: boolean;
	show_scrollbar: boolean;
	show_targets: boolean;
	show_values: boolean;
	significant_digits?: string | null;
	target_value?: string | null;
	top_results: boolean;
	owner: number;
	period: number;
	is_from_dataframe: boolean;
	sharedChart?: number[] | TSharedEntity[];
	uuid?: string;

	constructor(chart: TChart) {
		this.id = chart.id;
		this.name = chart.name;
		this.chart_type = chart.chart_type;
		this.second_series_chart_type = chart.second_series_chart_type;
		this.color_override = chart.color_override;
		this.color_set = chart.color_set;
		this.dataframe = chart.dataframe;
		this.folder = chart.folder;
		this.future_periods = chart.future_periods;
		this.hide_legend = chart.hide_legend;
		this.hide_y_axis = chart.hide_y_axis;
		this.historical_periods = chart.historical_periods;
		this.lowest_period = chart.lowest_period;
		this.maxium = chart.maxium;
		this.minumum = chart.minumum;
		this.num_decimals = chart.num_decimals;
		this.selected_data_point = chart.selected_data_point;
		this.share_common_y_axis = chart.share_common_y_axis;
		this.show_average = chart.show_average;
		this.show_data_values = chart.show_data_values;
		this.show_percentages = chart.show_percentages;
		this.show_scrollbar = chart.show_scrollbar;
		this.show_targets = chart.show_targets;
		this.show_values = chart.show_values;
		this.significant_digits = chart.significant_digits;
		this.target_value = chart.target_value;
		this.top_results = chart.top_results;
		this.owner = chart.owner;
		this.period = chart.period;
		this.is_from_dataframe = chart.is_from_dataframe;
		this.sharedChart = chart.sharedChart;
		this.uuid = chart.uuid;
	}

	public static Default(): TChart {
		return {
			id: 0,
			name: '',
			chart_type: '',
			second_series_chart_type: '',
			color_override: false,
			color_set: '',
			dataframe: 0,
			folder: 0,
			future_periods: 0,
			hide_legend: false,
			hide_y_axis: false,
			historical_periods: 0,
			lowest_period: 0,
			maxium: '',
			minumum: '',
			num_decimals: 0,
			selected_data_point: 0,
			share_common_y_axis: false,
			show_average: false,
			show_data_values: false,
			show_percentages: false,
			show_scrollbar: false,
			show_targets: false,
			show_values: false,
			top_results: false,
			owner: 0,
			period: 0,
			is_from_dataframe: false,
		};
	}

	public static New(folder: TChart): Promise<TChart> {
		return axiosConfig.instance.post<TChart>('app/chart', folder).then((res) => {
			return res.data;
		});
	}

	public static Delete(id: number): Promise<GenericResponse<any>> {
		return axiosConfig.instance.delete<GenericResponse<any>>(`app/chart/${id}`).then((res) => {
			return res.data;
		});
	}

	public static Update(chart: TChart): Promise<TChart> {
		return axiosConfig.instance.patch<TChart>('app/chart', chart).then((res) => {
			return res.data;
		});
	}

	public static GetAll(): Promise<TChart[]> {
		return axiosConfig.instance.get<TChart[]>('app/charts').then((res) => {
			return res.data ?? [];
		});
	}

	public static GetOne(id: number): Promise<TChart> {
		return axiosConfig.instance
			.get<TChart>(`app/chart/${id}?expand[]=sharedChart`)
			.then((res) => {
				return res.data ?? [];
			});
	}
}
