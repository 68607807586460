import { Label, Tooltip } from '@patternfly/react-core';

export default function BuilderZoneTag() {
	return (
		<Tooltip
			aria="none"
			content={'This is a Builder Zone'}
			flipBehavior={['bottom', 'bottom-end']}
		>
			<Label className="bz-pill">Builder Zone</Label>
		</Tooltip>
	);
}
