import {
	Spinner,
	Alert,
	Button,
	Form,
	FormGroup,
	TextInput,
	ActionGroup,
} from '@patternfly/react-core';
import React, { ReactElement, useEffect, useState } from 'react';
import './LoginPage.scss';
import { Login, SSOLogin } from '../../../api/login/Login';
import CenteredPage from '../../../layout/CenteredPage';
import axios, { AxiosError } from 'axios';
import axiosConfig, { IZIApiError } from '../../../api/axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCSRFToken } from '../../../api/security/CSRFToken';
import { ZoneSetting, ZoneSettingEnum } from '../../../api/zone/ZoneSetting';

interface Props {
	isSSO?: boolean;
}

export default function LoginPage(props: Props): ReactElement {
	const { isSSO } = props;
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(true);
	const [loginLoading, setLoginLoading] = useState(false);
	const [message, setMessage] = useState<React.ReactElement | null>();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setLoading(true);
		getCSRFToken()
			.then((token) => {
				axiosConfig.instance.defaults.headers['CSRF-TOKEN'] = token;
				if (isSSO) {
					ZoneSetting.Get(ZoneSettingEnum.Saml2_Enabled)
						.then((response) => {
							if (response.value === '1') {
								SSOLogin()
									.then((response: string) => {
										let url = `${response}?RelayState=${window.location.origin}`;
										// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
										if (location.state && location.state.nextPath) {
											const zone = window.location.hostname.split('.')[0];
											// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
											url = `${url}${location.state.nextPath}`;
										}
										window.location.href = url;
									})
									.catch((err: unknown) => {
										setLoading(false);
									});
							} else {
								setLoading(false);
							}
						})
						.catch((err) => {
							console.error(err);
						});
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			login();
		}
	};

	const login = () => {
		if (loginLoading) {
			return;
		}

		let login_username = '';
		let login_password = '';

		if (username === '' || password === '') {
			const usernameInput = document.getElementById('username') as HTMLInputElement;
			login_username = usernameInput.value;

			const passwordInput = document.getElementById('password') as HTMLInputElement;
			login_password = passwordInput.value;
		} else {
			login_username = username;
			login_password = password;
		}

		if (login_username === '' || login_password === '') {
			setMessage(
				<Alert
					variant="danger"
					isInline
					isPlain
					title="Username and password must be set."
				/>
			);

			return;
		}

		setMessage(null);
		setLoginLoading(true);

		Login(login_username, login_password)
			.then((response) => {
				if (response.pinnedDashboardId) {
					navigate(`/analyze/dashboards/view/${response.pinnedDashboardId}`);
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				} else if (location.state && location.state.nextPath) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
					navigate(location.state.nextPath);
				} else {
					navigate('/getting-started');
				}
			})
			.catch((err: unknown) => {
				if (axios.isAxiosError(err)) {
					const axiosErr: AxiosError = err as AxiosError;

					const data = axiosErr.response?.data as IZIApiError;

					if (data === undefined) {
						setMessage(
							<Alert
								variant="danger"
								isInline
								isPlain
								title="An unknown error occurred. Please try again."
								data-testid="login-error"
							/>
						);
					} else {
						setMessage(
							<Alert
								variant="danger"
								isInline
								isPlain
								title={(axiosErr.response?.data as IZIApiError).detail}
								data-testid="login-error"
							/>
						);
					}

					setLoginLoading(false);
				}
			});
	};

	const button = loginLoading ? (
		<div className="spinner-container">
			<Spinner size={'lg'} />
		</div>
	) : (
		<Button
			onClick={login}
			variant="primary"
			data-testid="login-submit"
			type="button"
			className="btn-login"
		>
			Login
		</Button>
	);

	return (
		<CenteredPage
			description={loading ? '' : 'Please enter your login credentials:'}
			notice={message}
		>
			{loading && isSSO && (
				<div className="spinner-container">
					<Spinner size={'lg'} />
					{isSSO && <p>Connecting via SSO...</p>}
				</div>
			)}
			{(!loading || !isSSO) && (
				<Form autoComplete="on">
					<FormGroup
						label="User Account:"
						isRequired
					>
						<TextInput
							isRequired
							type="text"
							id={'username'}
							name="username"
							aria-label={'User Account'}
							onChange={(e) => setUsername(e)}
							data-testid="login-username"
							onKeyDown={handleKeyDown}
						></TextInput>
					</FormGroup>
					<FormGroup
						label="User Password:"
						isRequired
					>
						<TextInput
							isRequired
							type="password"
							id={'password'}
							name="password"
							aria-label={'User Password'}
							onChange={(e) => setPassword(e)}
							data-testid="login-password"
							onKeyDown={handleKeyDown}
						></TextInput>
					</FormGroup>
					<ActionGroup className="actiongroup-center">{button}</ActionGroup>
				</Form>
			)}
		</CenteredPage>
	);
}
