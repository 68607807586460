import axiosConfig from '../axiosConfig';
import { GenericResponse } from '../types/generic-response';

export type TSubscriptionModel = {
	id: string;
	subscription: string;
	version: number;
	entityType: string;
	entityId: string;
};

export type TNewSubscriptionModel = Omit<TSubscriptionModel, 'id'>;

export class SubscriptionModel implements TSubscriptionModel {
	id: string;
	subscription: string;
	version: number;
	entityType: string;
	entityId: string;

	constructor(sub: TSubscriptionModel) {
		this.id = sub.id;
		this.subscription = sub.subscription;
		this.version = sub.version;
		this.entityType = sub.entityType;
		this.entityId = sub.entityId;
	}

	public static Default(): TSubscriptionModel {
		return {
			id: '',
			version: 0,
			subscription: '',
			entityType: '',
			entityId: '',
		};
	}

	public static GetAll(expand: (keyof TSubscriptionModel)[] = []): Promise<TSubscriptionModel[]> {
		return axiosConfig.instance
			.get(`app/subscription-models`, { params: { expand: expand } })
			.then((res) => {
				return ((res.data || []) as TSubscriptionModel[]).map((entity) => {
					return entity;
				});
			});
	}

	public static Get(
		GuidId: string
	): Promise<TSubscriptionModel[] | GenericResponse<TSubscriptionModel[]>> {
		return axiosConfig.instance
			.get(`app/subscription-model/subscription/${GuidId}`, {})
			.then((res) => {
				return res.data as TSubscriptionModel[] | GenericResponse<TSubscriptionModel[]>;
			});
	}

	public static Delete(id: string): Promise<boolean> {
		return axiosConfig.instance.delete(`app/subscription-model/` + String(id)).then(() => {
			return true;
		});
	}

	public static New(entity: TNewSubscriptionModel): Promise<TSubscriptionModel> {
		return axiosConfig.instance.post(`app/subscription-model`, entity).then((res) => {
			return res.data as TSubscriptionModel;
		});
	}

	public static Update(entity: TSubscriptionModel): Promise<TSubscriptionModel> {
		return axiosConfig.instance.patch('app/subscription-model', entity).then((res) => {
			return res.data as TSubscriptionModel;
		});
	}
}
