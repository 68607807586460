import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../layout/Layout';
import { useMount } from 'react-use';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import {
	AlertVariant,
	Button,
	Card,
	CardBody,
	Tab,
	Tabs,
	Text,
	TextContent,
	TextVariants,
} from '@patternfly/react-core';
import { Subscription, TSubscription } from '../api/subscriptions/Subscription';
import DualListSelectorGeneric from '../helpers/helper-components/DualListSelectorGeneric';
import { useApplication } from '../components/user/ApplicationProvider';
import { Dashboard, TDashboard } from '../api/dashbboards/Dashboards';
import { Chart, TChart } from '../api/charts/Chart';
import { DashboardWidget } from '../api/dashbboards/DashboardWidgets';
import { Report } from '../api/reports/Reports';
import { Card as CardApi } from '../api/cards/Cards';
import { FolderTypesEnum } from '../enums/folder-types-enum';
import {
	GenericKeyValueResponse,
	GenericResponse,
	GenericStringKeyValueResponse,
	TDataframe,
} from '../api/types';
import { TDimension } from '../api/analytics/Dimension';
import { Dataframe } from '../api/dataframes/Dataframes';
import { Group } from '../api/security/Group';
import { Role } from '../api/security/Role';
import {
	SubscriptionContent,
	TNewSubscriptionContent,
	TSubscriptionContent,
} from '../api/subscriptions/SubscriptionContent';
import {
	SubscriptionModel,
	TNewSubscriptionModel,
	TSubscriptionModel,
} from '../api/subscriptions/SubscriptionModel';
import Loader from '../components/util/Loader';
import { SubscriptionModelTypesEnum } from '../enums/subscription-model-types.enum';
import { SubscriptionContentTypesEnum } from '../enums/subscription-content-types.enum';

export default function SubscriptionEdit(): ReactElement {
	const { addToast } = useToast();
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [activeSubscription, setActiveUserDimFilter] = useState<TSubscription>(
		Subscription.Default() as TSubscription
	);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
	const [activeTabKey, setActiveTabKey] = useState(0);
	const [nestedActiveTabKey, setNestedActiveTabKey] = useState(0);
	const [nestedActiveTabKey2, setNestedActiveTabKey2] = useState(0);
	const [keymeasures, setKeymeasures] = React.useState<React.ReactNode[]>([]);
	const [chosenKeymeasures, setChosenKeymeasures] = React.useState<React.ReactNode[]>([]);
	const [selectDimensions, setSelectDimensions] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectDimensions, setChosenSelectDimensions] = React.useState<React.ReactNode[]>(
		[]
	);
	const [selectDimensionAttributes, setSelectDimensionAttributes] = React.useState<
		React.ReactNode[]
	>([]);
	const [chosenSelectDimensionAttributes, setChosenselectDimensionAttributes] = React.useState<
		React.ReactNode[]
	>([]);
	const [selectDashboards, setSelectDashboards] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectDashboards, setChosenSelectDashboards] = React.useState<React.ReactNode[]>(
		[]
	);
	const [selectTables, setSelectTables] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectTables, setChosenSelectTables] = React.useState<React.ReactNode[]>([]);
	const [selectReports, setSelectReports] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectReports, setChosenSelectReports] = React.useState<React.ReactNode[]>([]);
	const [selectDataframes, setSelectDataframes] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectDataframes, setChosenSelectDataframes] = React.useState<React.ReactNode[]>(
		[]
	);
	const [selectUserGroups, setSelectUserGroups] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectUserGroups, setChosenSelectUserGroups] = React.useState<React.ReactNode[]>(
		[]
	);
	const [selectRoles, setSelectRoles] = React.useState<React.ReactNode[]>([]);
	const [chosenSelectRoles, setChosenSelectRoles] = React.useState<React.ReactNode[]>([]);
	const [selectCharts, setSelectCharts] = React.useState<React.ReactNode[]>([]);
	const [charts, setCharts] = React.useState<TChart[]>([]);
	const [dataframes, setDataframes] = React.useState<TDataframe[]>([]);
	const [dashboards, setDashboards] = React.useState<TDashboard[]>([]);
	const [tables, setTables] = React.useState<GenericKeyValueResponse<string>[]>([]);
	const [guidTables, setGuidTables] = React.useState<GenericStringKeyValueResponse<string>[]>([]);
	const [guidDataframes, setGuidDataframes] = React.useState<
		GenericStringKeyValueResponse<string>[]
	>([]);
	const [chosenSelectCharts, setChosenSelectCharts] = React.useState<React.ReactNode[]>([]);
	const chosenPaneRef_KeyMeasures = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Dimensions = useRef<HTMLDivElement>(null);
	const chosenPaneRef_DimensionAttributes = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Dashboards = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Charts = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Tables = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Reports = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Dataframes = useRef<HTMLDivElement>(null);
	const chosenPaneRef_UserGroups = useRef<HTMLDivElement>(null);
	const chosenPaneRef_Roles = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const { measures, dimensions } = useApplication();
	const [chartIdsBasedOnDashboards, setChartIdsBasedOnDashboards] = useState<number[]>([]);
	const [tableIdsBasedOnDashboards, setTableIdsBasedOnDashboards] = useState<number[]>([]);
	const { subscriptionId } = useParams();
	const [subscriptionName, setSubscriptionName] = React.useState('');
	const [existingSubscriptionContents, setExistingSubscriptionContents] = useState<
		TSubscriptionContent[]
	>([]);
	const [existingSubscriptionModels, setExistingSubscriptionModels] = useState<
		TSubscriptionModel[]
	>([]);
	const [isLoading, setIsLoading] = useState(true);

	useMount(() => {
		if (subscriptionId) {
			void Subscription.Get(subscriptionId).then((response) => {
				setSubscriptionName(response.name);

				void SubscriptionContent.Get(response.id).then((subscriptionContentReponse) => {
					setExistingSubscriptionContents(subscriptionContentReponse);
				});

				void SubscriptionModel.Get(response.id)
					.then((subscriptionModelReponse) => {
						const response = subscriptionModelReponse as GenericResponse<
							TSubscriptionModel[]
						>;
						const hasValidResponseItems =
							(subscriptionModelReponse as TSubscriptionModel[]).length > 0;

						if (hasValidResponseItems) {
							const response = subscriptionModelReponse as TSubscriptionModel[];
							setExistingSubscriptionModels(response);
						} else if (response.message.includes('not found')) {
							setIsLoading(false);
						}
					})
					.catch(() => {
						setIsLoading(false);
					});
			});
		}

		const mappedCommonKeymeasures: React.ReactNode[] = measures.map((option, index) => (
			<div
				key={`option${index + 1}`}
				id={option.uuid}
				data-id={option.uuid}
			>
				{option.display_name ?? option.name}
			</div>
		));

		const mappedCommonDimensions: React.ReactNode[] = dimensions.map((option, index) => (
			<div
				key={`option${index + 1}`}
				id={option.uuid}
				data-id={option.uuid}
			>
				{option.display_name ?? option.name}
			</div>
		));

		const mappedCommonDimensionAttributes: React.ReactNode[] = dimensions
			.flatMap((x) => x.dimensionAttributes)
			.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));

		let dashboardIds: number[] = [];
		const chartIdsBasedOnDashboards: number[] = [];
		const tableIdsBasedOnDashboards: number[] = [];

		void Dashboard.GetAll().then((response) => {
			dashboardIds = response.map((x) => x.id!);
			setDashboards(response);

			const mappedCommonDashboards: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));

			dashboardIds.map((x) => {
				void DashboardWidget.GetAll({ dashboard: x.toString() }).then((widgetResponse) => {
					widgetResponse.map((widgetItem) => {
						console.log('widgetItem', widgetItem);
						if (widgetItem.chart && widgetItem.widget_type == 'chart') {
							chartIdsBasedOnDashboards.push(widgetItem.chart);
							setChartIdsBasedOnDashboards(chartIdsBasedOnDashboards);
						}
						if (widgetItem.report && widgetItem.widget_type == 'pivot_table') {
							tableIdsBasedOnDashboards.push(widgetItem.report);
							setTableIdsBasedOnDashboards(tableIdsBasedOnDashboards);
						}
					});
				});
			});

			setSelectDashboards(mappedCommonDashboards);
		});

		void Chart.GetAll().then((response) => {
			const mappedCommonCharts: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));
			setCharts(response);
			setSelectCharts(mappedCommonCharts);
		});

		void CardApi.GetAll(FolderTypesEnum.Tables).then((response) => {
			const mappedCommonTables: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));

			const items: GenericKeyValueResponse<string>[] = response.map((x) => ({
				id: x.id,
				value: x.name,
			}));
			const guidItems: GenericStringKeyValueResponse<string>[] = response.map((x) => ({
				key: x.uuid ?? '0',
				value: x.name,
			}));

			setTables(items);
			setGuidTables(guidItems);
			setSelectTables(mappedCommonTables);
		});

		void Report.GetAll().then((response) => {
			const mappedCommonReports: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));
			setSelectReports(mappedCommonReports);
		});

		void Dataframe.GetAll().then((response) => {
			const mappedCommonDataframes: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));

			const guidItems: GenericStringKeyValueResponse<string>[] = response.map((x) => ({
				key: x.uuid ?? '0',
				value: x.name,
			}));

			setDataframes(response);
			setGuidDataframes(guidItems);
			setSelectDataframes(mappedCommonDataframes);
		});

		void Group.GetAll().then((response) => {
			const mappedCommonUserGroups: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));
			setSelectUserGroups(mappedCommonUserGroups);
		});

		void Role.GetAll().then((response) => {
			const mappedCommonRoles: React.ReactNode[] = response.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			));
			setSelectRoles(mappedCommonRoles);
		});

		setKeymeasures(mappedCommonKeymeasures);
		setSelectDimensions(mappedCommonDimensions);
		setSelectDimensionAttributes(mappedCommonDimensionAttributes);
	});

	//load logic
	useEffect(() => {
		let hasExistingItems = false;

		if (existingSubscriptionModels && existingSubscriptionModels.length > 0) {
			const existingKmIds = existingSubscriptionModels
				.filter((x) => x.entityType == SubscriptionModelTypesEnum.KEY_MEASURE)
				.map((x) => x.entityId);
			const existingKms = measures.filter((x) => existingKmIds.includes(x.uuid!));

			const mappedSelectedKMs: React.ReactNode[] = existingKms.map((option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.display_name ?? option.name}
				</div>
			));

			setChosenKeymeasures(mappedSelectedKMs);
			hasExistingItems = true;
		}

		if (existingSubscriptionContents && existingSubscriptionContents.length > 0) {
			const existingDashboardIds = existingSubscriptionContents
				.filter((x) => x.contentType == SubscriptionContentTypesEnum.DASHBOARD)
				.map((x) => x.contentId);
			const existingDashboards = dashboards.filter((x) =>
				existingDashboardIds.includes(x.uuid!)
			);

			const mappedSelectedDashboards: React.ReactNode[] = existingDashboards.map(
				(option, index) => (
					<div
						key={`option${index + 1}`}
						id={option.uuid}
						data-id={option.uuid}
					>
						{option.name}
					</div>
				)
			);

			setChosenSelectDashboards(mappedSelectedDashboards);
			hasExistingItems = true;
		}
	}, [existingSubscriptionModels, existingSubscriptionContents]);

	useEffect(() => {
		const hasExistingDataLoaded =
			(chosenKeymeasures && chosenKeymeasures.length > 0) ||
			(chosenSelectDashboards && chosenSelectDashboards.length > 0);

		if (hasExistingDataLoaded) {
			setIsLoading(false);
		}
	}, [chosenKeymeasures, chosenSelectDashboards]);

	useEffect(() => {
		if (charts && charts.length > 0) {
			const dataframeIdsBasedOnCharts: number[] = [];
			const autoSelectedCharts = charts.filter((x) =>
				chartIdsBasedOnDashboards.includes(x.id!)
			);

			autoSelectedCharts.map((chart) => {
				dataframeIdsBasedOnCharts.push(chart.dataframe);
			});

			const autoSelectedDataframes = dataframes.filter((x) =>
				dataframeIdsBasedOnCharts.includes(x.id)
			);
			const autoSelectedGuidDataframes = guidDataframes.filter((x) =>
				autoSelectedDataframes.some((y) => y.name == x.value)
			);

			const mappedSelectedDataframes: React.ReactNode[] = autoSelectedGuidDataframes.map(
				(option, index) => (
					<div
						key={`option${index + 1}`}
						id={option.key}
						data-id={option.key}
					>
						{option.value}
					</div>
				)
			);

			setChosenSelectDataframes(mappedSelectedDataframes);
		}
	}, [chosenSelectDashboards]);

	useEffect(() => {
		const autoSelectedCharts = charts.filter((x) => chartIdsBasedOnDashboards.includes(x.id!));

		const mappedSelectedCharts: React.ReactNode[] = autoSelectedCharts.map((option, index) => (
			<div
				key={`option${index + 1}`}
				id={option.uuid}
				data-id={option.uuid}
			>
				{option.name}
			</div>
		));

		setChosenSelectCharts(mappedSelectedCharts);

		const autoSelectedTables = tables.filter((x) => tableIdsBasedOnDashboards.includes(x.id));
		const autoSelectedGuidTables = guidTables.filter((x) =>
			autoSelectedTables.some((y) => y.value == x.value)
		);

		const mappedSelectedTables: React.ReactNode[] = autoSelectedGuidTables.map(
			(option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.key}
					data-id={option.key}
				>
					{option.value}
				</div>
			)
		);

		setChosenSelectTables(mappedSelectedTables);

		if (chosenSelectDashboards.length == 0) {
			setChartIdsBasedOnDashboards([]);
			setTableIdsBasedOnDashboards([]);
		}
	}, [selectCharts, chosenSelectDashboards]);

	useEffect(() => {
		const dimensionIds: string[] = [];
		if (chosenPaneRef_Dimensions.current) {
			const dimensionElements =
				chosenPaneRef_Dimensions.current.querySelectorAll('[data-id]');
			dimensionElements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimensionIds.push(id);
				}
			});
		}

		const dimAttributes = dimensions
			.filter((x) => dimensionIds.includes(x.uuid!))
			.flatMap((x) => x.dimensionAttributes);

		const mappedCommonDimensionAttributes: React.ReactNode[] = dimAttributes.map(
			(option, index) => (
				<div
					key={`option${index + 1}`}
					id={option.uuid}
					data-id={option.uuid}
				>
					{option.name}
				</div>
			)
		);

		setChosenselectDimensionAttributes(mappedCommonDimensionAttributes);
	}, [chosenSelectDimensions]);

	useEffect(() => {
		if (chosenKeymeasures && chosenKeymeasures.length > 0) {
			const keyMeasureIds: string[] = [];
			if (chosenPaneRef_KeyMeasures.current) {
				const dimensionElements =
					chosenPaneRef_KeyMeasures.current.querySelectorAll('[data-id]');
				dimensionElements.forEach((element) => {
					const id = element.getAttribute('data-id');
					if (id) {
						keyMeasureIds.push(id);
					}
				});
			}

			if (keyMeasureIds.length > 0) {
				const dimsBasedOnKeyMeasures = measures.find((x) =>
					keyMeasureIds.includes(x.uuid!)
				)!.dimensions as TDimension[];

				const mappedDimensionsBasedonKMs: React.ReactNode[] = dimsBasedOnKeyMeasures.map(
					(option, index) => (
						<div
							key={`option${index + 1}`}
							id={option.uuid}
							data-id={option.uuid}
						>
							{option.display_name ?? option.name}
						</div>
					)
				);
				setChosenSelectDimensions(mappedDimensionsBasedonKMs);
			}
		} else {
			setChosenSelectDimensions([]);
		}
	}, [chosenKeymeasures]);

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle="Subscriptions"
					pageDescription="Manage Subscriptions."
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
				/>
			),
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded]);

	// Define a function to handle tab selection
	const handleTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveTabKey(Number(tabIndex));
	};
	const handleNestedTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setNestedActiveTabKey(Number(tabIndex));
	};

	const handleNestedTabSelect2 = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setNestedActiveTabKey2(Number(tabIndex));
	};

	const saveSubscription = () => {
		setIsLoading(true);

		const kmIds: string[] = [];
		const dimIds: string[] = [];
		const dimAtrributeIds: string[] = [];
		const dashboardIds: string[] = [];
		const chartIds: string[] = [];
		const tableIds: string[] = [];
		const reportIds: string[] = [];
		const dataframeIds: string[] = [];
		const userGroupIds: string[] = [];
		const roleIds: string[] = [];

		if (chosenPaneRef_KeyMeasures.current) {
			const elements = chosenPaneRef_KeyMeasures.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					kmIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Dimensions.current) {
			const elements = chosenPaneRef_Dimensions.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimIds.push(id);
				}
			});
		}
		if (chosenPaneRef_DimensionAttributes.current) {
			const elements =
				chosenPaneRef_DimensionAttributes.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dimAtrributeIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Dashboards.current) {
			const elements = chosenPaneRef_Dashboards.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dashboardIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Charts.current) {
			const elements = chosenPaneRef_Charts.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					chartIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Tables.current) {
			const elements = chosenPaneRef_Tables.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					tableIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Reports.current) {
			const elements = chosenPaneRef_Reports.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					reportIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Dataframes.current) {
			const elements = chosenPaneRef_Dataframes.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					dataframeIds.push(id);
				}
			});
		}
		if (chosenPaneRef_UserGroups.current) {
			const elements = chosenPaneRef_UserGroups.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					userGroupIds.push(id);
				}
			});
		}
		if (chosenPaneRef_Roles.current) {
			const elements = chosenPaneRef_Roles.current.querySelectorAll('[data-id]');
			elements.forEach((element) => {
				const id = element.getAttribute('data-id');
				if (id) {
					roleIds.push(id);
				}
			});
		}

		const KeyMeasurePromises = kmIds.map((id) => {
			const model: TNewSubscriptionModel = {
				entityId: id,
				entityType: SubscriptionModelTypesEnum.KEY_MEASURE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionModel.New(model);
		});
		const dimPromises = dimIds.map((id) => {
			const model: TNewSubscriptionModel = {
				entityId: id,
				entityType: SubscriptionModelTypesEnum.DIMENSION,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionModel.New(model);
		});
		const dimAttributePromises = dimAtrributeIds.map((id) => {
			const model: TNewSubscriptionModel = {
				entityId: id,
				entityType: SubscriptionModelTypesEnum.ATTRIBUTE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionModel.New(model);
		});

		const subscriptionModelPromises: Promise<TSubscriptionModel>[] = [
			...KeyMeasurePromises,
			...dimPromises,
			...dimAttributePromises,
		];

		const dashboardPromises = dashboardIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.DASHBOARD,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const chartPromises = chartIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.CHART,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const tablePromises = tableIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.TABLE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const reportPromises = reportIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.REPORT,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const dataframePromises = dataframeIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.DATAFRAME,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const userGroupPromises = userGroupIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.USER_GROUP,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const rolePromises = roleIds.map((id) => {
			const model: TNewSubscriptionContent = {
				contentId: id,
				contentType: SubscriptionContentTypesEnum.ROLE,
				subscription: subscriptionId ?? '0',
				version: 1,
			};
			return SubscriptionContent.New(model);
		});

		const subscriptionContentPromises: Promise<TSubscriptionContent>[] = [
			...dashboardPromises,
			...chartPromises,
			...tablePromises,
			...reportPromises,
			...dataframePromises,
			...userGroupPromises,
			...rolePromises,
		];

		const combinedList = [...subscriptionModelPromises, ...subscriptionContentPromises];

		const isExistingSubModel =
			existingSubscriptionModels && existingSubscriptionModels.length > 0;
		const isExistingSubContent =
			existingSubscriptionContents && existingSubscriptionContents.length > 0;
		const isBothExistingSubModelAndContent = isExistingSubContent && isExistingSubModel;

		let deletePromises: Promise<boolean>[] = [];

		if (isBothExistingSubModelAndContent) {
			deletePromises = [
				SubscriptionModel.Delete(subscriptionId ?? '0'),
				SubscriptionContent.Delete(subscriptionId ?? '0'),
			];
		} else if (isExistingSubContent) {
			deletePromises = [SubscriptionContent.Delete(subscriptionId ?? '0')];
		} else if (isExistingSubModel) {
			deletePromises = [SubscriptionModel.Delete(subscriptionId ?? '0')];
		}

		//delete pre-existing records
		Promise.all(deletePromises)
			.then(() => {
				Promise.all(combinedList)
					.then((responses: (TSubscriptionContent | TSubscriptionModel)[]) => {
						setIsLoading(false);
						addToast(
							'Sucessfully saved Subscription Model & Content',
							AlertVariant.success
						);
					})
					.catch(() => {
						setIsLoading(false);
						addToast(
							'An error occured while saving Subscription Model & Content',
							AlertVariant.danger
						);
					});
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	return (
		<React.Fragment>
			{' '}
			<Card>
				<TextContent className="p-sub">
					<Text component={TextVariants.h2}>Subscription: {subscriptionName}</Text>
				</TextContent>
				<br />
				<CardBody>
					{isLoading ? (
						<Loader />
					) : (
						<Tabs
							className="parent-tabs"
							activeKey={activeTabKey}
							onSelect={handleTabSelect}
						>
							<Tab
								eventKey={0}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Model<strong/>',
										}}
									></span>
								}
							>
								{/* Content for Tab 1 */}
								<Tabs
									activeKey={nestedActiveTabKey}
									onSelect={handleNestedTabSelect}
									className="child-tabs"
								>
									<Tab
										eventKey={0}
										title="Key Measures"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Key Measures"
												rightHeading="Chosen Key Measures"
												leftItems={keymeasures}
												setLeftItems={setKeymeasures}
												chosenOptions={chosenKeymeasures}
												setChosenOptions={setChosenKeymeasures}
												chosenPaneRef={chosenPaneRef_KeyMeasures}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={1}
										title="Dimensions"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Dimensions"
												rightHeading="Chosen Dimensions"
												leftItems={selectDimensions}
												setLeftItems={setSelectDimensions}
												chosenOptions={chosenSelectDimensions}
												setChosenOptions={setChosenSelectDimensions}
												chosenPaneRef={chosenPaneRef_Dimensions}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={2}
										title="Dimension Attributes"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Dimension Attributes"
												rightHeading="Chosen Key Dimensions Attributes"
												leftItems={selectDimensionAttributes}
												setLeftItems={setSelectDimensionAttributes}
												chosenOptions={chosenSelectDimensionAttributes}
												setChosenOptions={
													setChosenselectDimensionAttributes
												}
												chosenPaneRef={chosenPaneRef_DimensionAttributes}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
								</Tabs>
							</Tab>
							<Tab
								eventKey={1}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Content<strong/>',
										}}
									></span>
								}
							>
								{/* Content for Tab 2 */}
								<Tabs
									activeKey={nestedActiveTabKey2}
									onSelect={handleNestedTabSelect2}
									className="child-tabs"
								>
									<Tab
										eventKey={0}
										title="Dashboards"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Dashboards"
												rightHeading="Chosen Dashboards"
												leftItems={selectDashboards}
												setLeftItems={setSelectDashboards}
												chosenOptions={chosenSelectDashboards}
												setChosenOptions={setChosenSelectDashboards}
												chosenPaneRef={chosenPaneRef_Dashboards}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={1}
										title="Charts"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Charts"
												rightHeading="Chosen Charts"
												leftItems={selectCharts}
												setLeftItems={setSelectCharts}
												chosenOptions={chosenSelectCharts}
												setChosenOptions={setChosenSelectCharts}
												chosenPaneRef={chosenPaneRef_Charts}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={2}
										title="Tables"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Tables"
												rightHeading="Chosen Tables"
												leftItems={selectTables}
												setLeftItems={setSelectTables}
												chosenOptions={chosenSelectTables}
												setChosenOptions={setChosenSelectTables}
												chosenPaneRef={chosenPaneRef_Tables}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={3}
										title="Reports"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Reports"
												rightHeading="Chosen Reports"
												leftItems={selectReports}
												setLeftItems={setSelectReports}
												chosenOptions={chosenSelectReports}
												setChosenOptions={setChosenSelectReports}
												chosenPaneRef={chosenPaneRef_Reports}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={4}
										title="Dataframes"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Dataframes"
												rightHeading="Chosen Dataframes"
												leftItems={selectDataframes}
												setLeftItems={setSelectDataframes}
												chosenOptions={chosenSelectDataframes}
												setChosenOptions={setChosenSelectDataframes}
												chosenPaneRef={chosenPaneRef_Dataframes}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={5}
										title="User Groups"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable User Groups"
												rightHeading="Chosen User Groups"
												leftItems={selectUserGroups}
												setLeftItems={setSelectUserGroups}
												chosenOptions={chosenSelectUserGroups}
												setChosenOptions={setChosenSelectUserGroups}
												chosenPaneRef={chosenPaneRef_UserGroups}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
									<Tab
										eventKey={6}
										title="Roles"
									>
										<div className="dv-dual-list-no-modal">
											<br />
											<DualListSelectorGeneric
												leftHeading="Avaliable Roles"
												rightHeading="Chosen Roles"
												leftItems={selectRoles}
												setLeftItems={setSelectRoles}
												chosenOptions={chosenSelectRoles}
												setChosenOptions={setChosenSelectRoles}
												chosenPaneRef={chosenPaneRef_Roles}
												isNonModalDualList={true}
											/>
										</div>
									</Tab>
								</Tabs>
							</Tab>
						</Tabs>
					)}
					<br />
					<div className="pull-right">
						<Button
							variant="primary"
							onClick={() => {
								navigate('/setup/subscription/builder');
							}}
						>
							Back
						</Button>
						<Button
							variant="primary"
							onClick={() => saveSubscription()}
						>
							Save
						</Button>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
}
