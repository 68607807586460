import axiosConfig from '../axiosConfig';

export interface LoginResponse {
	message: string;
	pinnedDashboardId?: string;
}
export async function Login(username: string, password: string): Promise<LoginResponse> {
	return axiosConfig.instance.post('/app/login', { username, password }).then((response) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		return { message: 'Successfully logged in.', pinnedDashboardId: response.data };
	});
}

export async function SSOLogin(): Promise<any> {
	return axiosConfig.instance.post('/app/saml/signonservice').then((response) => {
		return response.data as string;
	});
}

export async function SSOLogout(): Promise<any> {
	return axiosConfig.instance.post('/app/saml/signoutservice').then((response) => {
		return response.data as string;
	});
}
