import React from 'react';
import {
	TipAndTrickType,
	TipsAndTricksSummary,
} from '../../helpers/sub-nav-contexts/sub-nav-context';
import { Flex, Grid, GridItem, Text } from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import './SubNavTipsAndTricks.scss';
import { faCirclePlay } from '@fortawesome/pro-light-svg-icons';

type Props = {
	tipsAndTricks: TipsAndTricksSummary;
};

const SubNavTipsAndTricks = (props: Props) => {
	const {
		tipsAndTricks: { title, allTutorialsLink, tipsAndTricksList },
	} = props;
	const navigate = useNavigate();

	return (
		<Grid>
			<Grid span={12}>
				<GridItem span={12}>
					<Text>{title}</Text>
				</GridItem>
			</Grid>

			<Grid
				span={12}
				key={'grid-2'}
			>
				{tipsAndTricksList?.map((tipsAndTricksItem, index) => {
					let icon = faFilePdf;
					let typeText = 'PDF Guide';
					if (tipsAndTricksItem.type === TipAndTrickType.VIDEO) {
						icon = faCirclePlay;
						typeText = 'Video';
					}
					return (
						<GridItem
							span={6}
							key={`tips-and-tricks-grid-item-${index}`}
						>
							<Grid
								key={`sub-grid-${index}`}
								onClick={() => navigate(tipsAndTricksItem.url)}
								className="action-card-container"
							>
								<GridItem
									span={12}
									className="action-card"
									key={`tips-and-tricks-grid-grid-item-${index}`}
								>
									<Grid>
										<GridItem
											span={10}
											offset={1}
											key={`tips-and-tricks-grid-grid-item-grid-${index}`}
										>
											<Flex alignItems={{ default: 'alignItemsCenter' }}>
												<Flex
													direction={{ default: 'column' }}
													className="action-card-title"
													flex={{ default: 'flex_4' }}
												>
													<Text className="tutorial-type">
														{typeText}
													</Text>
													<Text className="tutorial-title">
														{tipsAndTricksItem.title}
													</Text>
												</Flex>
												<Flex
													alignItems={{ default: 'alignItemsCenter' }}
													className="action-card-icon"
												>
													<FontAwesomeIcon
														size="lg"
														icon={icon}
													/>
												</Flex>
											</Flex>
										</GridItem>
									</Grid>
								</GridItem>
							</Grid>
						</GridItem>
					);
				})}
			</Grid>
		</Grid>
	);
};

export default SubNavTipsAndTricks;
